import { ReadOnlyResource } from '@/api/resource';

/**
 * The Rate raw data sent by the API.
 * @author Axel Nana <axel.nana@workerly.io>
 */
export interface RateResponseData {
  pk: string;
  rate: number;
  country: string;
  state: string;
  code_state: string;
  timezone: string;
}

/**
 * The rate resource manager.
 * @author Axel Nana <axel.nana@workerly.io>
 */
export class RateResource extends ReadOnlyResource<RateResponseData> {
  constructor() {
    super('/rate');
  }
}
