import { DataConstraintsPart, FieldConstraints } from '@/api/types';
import { DataModel } from '@/data/DataModel';

/**
 * A data model which have constraints on the creation, modification or deletion
 * capabilities.
 *
 * @author Axel Nana <axel.nana@workerly.io>
 */
export abstract class ConstrainedDataModel<T = Record<string, any>> extends DataModel<T & DataConstraintsPart> {
  /**
   * Checks if the model can be updated.
   */
  public get updatable(): boolean {
    return this._rawData.mining?.updatable || true;
  }

  /**
   * Checks if the model can be deleted.
   */
  public get deletable(): boolean {
    return this._rawData.mining?.deletable || true;
  }

  /**
   * Returns the list of constraints on each fields of this model.
   */
  public get fieldConstraints(): FieldConstraints[] {
    return this._rawData.mining?.fields || [];
  }
}
