import React from 'react';
import { Card, Skeleton, Avatar, Image } from 'antd';
import classNames from 'classnames';

import { Company } from '@/data';
import { useIntl } from 'umi';
import { Badge } from './Badge';

const { Meta } = Card;

/**
 * The available properties for a CompanyCard component.
 * @author Axel Nana <axel.nana@workerly.io>
 */
export interface CompanyCardProps {
  /**
   * The company to display.
   * @type {Company}
   */
  company: Company;

  /**
   * Defines if the card is loading or not.
   * @type {boolean}
   */
  loading?: boolean;

  /**
   * Defines if the card is hoverable or not.
   * @type {boolean}
   */
  hoverable?: boolean;

  /**
   * Defines if the card is selectable or not.
   * @type {boolean}
   */
  selectable?: boolean;

  /**
   * Defines if the card can display more details in a separate modal.
   * @type {boolean}
   */
  haveDetails?: boolean;

  /**
   * Callback called when the component is selected or deselected.
   * @param {boolean} selected The selected state of the card.
   */
  onSelectionChange?(selected: boolean): void;

  /**
   * Callback called when the component want to show details.
   * @returns {boolean} `true` to show the details modal, `false` otherwise.
   */
  onShowDetails?(): boolean;

  /**
   * Actions displayed at the bottom of the company card.
   * @type {React.ReactNode[]}
   */
  actions?: React.ReactNode[];
}

/**
 * Renders details for a single company.
 * @author Axel Nana <axel.nana@workerly.io>
 * @param {CompanyCardProps} props The properties sent to the component.
 */
export function CompanyCard({ actions, company, hoverable, loading }: CompanyCardProps) {
  const intl = useIntl();

  const [selected, setSelected] = React.useState(false);

  return (
    <Card
      className={classNames('wl-component-card wl-component-company-card', {
        'wl-component-company-card-selected': selected,
      })}
      hoverable={hoverable}
      cover={<Image alt={company.name} src={company.coverImage} preview={false} />}
      actions={actions}
    >
      <Skeleton loading={loading} avatar={{ size: 62, shape: 'circle' }} title={false} active paragraph={{ rows: 2 }}>
        <div className="wl-component-company-card-badge-row">
          {company.isOwner && (
            <Badge
              content={intl.formatMessage({
                id: 'components.company_card.badge.owner',
              })}
            />
          )}
        </div>
        <Meta
          avatar={<Avatar src={company.logo} size={62} />}
          title={company.name}
          description={company.addressLine1}
        />
      </Skeleton>
    </Card>
  );
}
