import React from 'react';
import { Modal as DefaultModal, Collapse, Space, Typography, Row, Col, Drawer, Popconfirm, message } from 'antd';
import { Device } from '@/data/Device';
import { Button, PreventDeletion } from '@/components';
import { Modal, ModalProps } from '@/components';
import { useProTableRequest, useTranslation } from '@/utils/hooks';
import { ProColumns } from '@ant-design/pro-table';
import { WLyPageContainer } from '@/components/Layout';
import { DataTable } from '@/components';
import { DeviceResource, DeviceResponseData } from '@/api/device';
import { EyeOutlined, DeleteOutlined, EditOutlined, UserAddOutlined } from '@ant-design/icons';
import { TextDescription } from '@/components';
import { useMount, useBoolean, useCreation } from '@umijs/hooks';
import { useModel, useRequest } from 'umi';
import { isEmpty } from 'lodash';

const { Panel } = Collapse;
const { Text, Paragraph } = Typography;

const dataTableColumns: ProColumns<Device>[] = [
  {
    title: 'Name',
    dataIndex: 'name',
    copyable: true,
    ellipsis: true,
    tooltip: 'The name of the device.',
  },
  {
    title: 'Device ID',
    dataIndex: 'uniqueId',
    copyable: true,
    ellipsis: true,
    tooltip: 'The device unique ID.',
  },
  {
    title: 'Location',
    dataIndex: 'locationName',
    ellipsis: true,
    tooltip: 'Where the device it is located.',
    renderText: (text) => `x ${text}`,
  },
];

/**
 * @author Adonis SIMO <simo.adonis@workerly.io>
 */
export default () => {
  const _ = useTranslation();
  const { activeWorkspace } = useModel('workspaces');

  const [apiToken, setToken] = React.useState('');
  const [viewDelete, setViewDelete] = React.useState(false);

  const resource = useCreation(() => new DeviceResource(), []);
  const { run: _runFetchTokenRequest, loading: _fetchTokenRequestLoading } = useRequest(resource.fetchToken, {
    manual: true,
  });
  const { run: _runObtainTokenRequest, loading: _obtainTokenRequestLoading } = useRequest(resource.obtainToken, {
    manual: true,
  });

  useMount(async () => {
    const response = await _runFetchTokenRequest();
    setToken(response.data?.apikey || '');
  });

  /**
   * Disables the existing token and fetch another one.
   * @author Adonis SIMO <simo.adonis@workerly.io>
   */
  const obtainNewToken = React.useCallback(async () => {
    DefaultModal.confirm({
      title: 'Confirm',
      centered: true,
      content:
        'Obtaining a new token will disable the existing one, No more registered devices will be able to send punches. Continue ?',
      onOk: async () => {
        const { data, status } = await _runObtainTokenRequest();
        if (status) {
          setToken(data?.apikey || '');
          message.success({
            content: 'The new API token was successfully generated.',
          });
        } else {
          // TODO: Display an error ?
        }
      },
    });
  }, [_runObtainTokenRequest]);

  /**
   * First time API token generation.
   * @author Axel Nana <axel.nana@workerly.io>
   */
  const generateToken = React.useCallback(async () => {
    DefaultModal.confirm({
      title: 'Confirm',
      centered: true,
      content: 'This will generate an API token to use on your punch devices. Continue ?',
      onOk: async () => {
        const { data, status } = await _runObtainTokenRequest();
        if (status) {
          setToken(data?.apikey || '');
          message.success({
            content: 'The API token was successfully generated.',
          });
        } else {
          // TODO: Display an error ?
        }
      },
    });
  }, [_runObtainTokenRequest]);

  /**
   * The effective token button click callback.
   * @author Axel Nana <axel.nana@workerly.io>
   */
  const tokenButtonCallback = React.useMemo(() => (isEmpty(apiToken) ? generateToken : obtainNewToken), [
    apiToken,
    generateToken,
    obtainNewToken,
  ]);

  /**
   * The text displayed in the token button.
   * @author Axel Nana <axel.nana@workerly.io>
   */
  const tokenButtonText = React.useMemo(() => (isEmpty(apiToken) ? 'Generate' : 'Refresh'), [apiToken, _]);

  return (
    <WLyPageContainer content="Manage Devices and API Key" subTitle="Clock Devices">
      <PreventDeletion
        title="Do you want to obtain a new token ?"
        content="By doing so, you will disable the existing token and all registered devices will be disabled. All devices have to be re-activated with new token before they can record Attendance Punches."
        requiredText="delete the token"
        visible={viewDelete}
      />
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Collapse bordered={false} defaultActiveKey={[]}>
            <Panel
              style={{ backgroundColor: 'white' }}
              header={<Text strong>API Key &amp; Technical information</Text>}
              key="1"
            >
              <Row gutter={[16, 8]}>
                <Col span={24}>
                  <Paragraph>
                    The devices shows the differents devices matched with our application . Click to generate token for
                    generate a new token for new device and click to Add New Device to create new device with name and
                    token.
                  </Paragraph>
                </Col>
                <Col span={12}>
                  <TextDescription
                    title={
                      <Space>
                        <Text strong>API Key</Text>
                        <Button
                          size="small"
                          type={isEmpty(apiToken) ? 'success' : 'warning'}
                          loading={_fetchTokenRequestLoading || _obtainTokenRequestLoading}
                          onClick={tokenButtonCallback}
                        >
                          {tokenButtonText}
                        </Button>
                      </Space>
                    }
                  >
                    <Text copyable code>
                      {apiToken}
                    </Text>
                  </TextDescription>
                </Col>
                <Col span={12}>
                  <TextDescription title="Company Tenant">
                    <Text copyable code>
                      {activeWorkspace?.tenant}
                    </Text>
                  </TextDescription>
                </Col>
              </Row>
            </Panel>
          </Collapse>
        </Col>
        <Col span={24}>
          <DataTable<Device, DeviceResource>
            model={Device}
            resource={DeviceResource}
            columns={dataTableColumns}
            drawerProps={{
              title: 'Consult Device',
              width: 420,
            }}
          />
        </Col>
      </Row>
    </WLyPageContainer>
  );
};
