import React, { useCallback } from 'react';
import { Col, Row } from 'antd';
import { useBoolean, usePersistFn } from '@umijs/hooks';
import { ProColumns } from '@ant-design/pro-table';

import { WorkWeek } from '@/data';
import { WorkWeekResource } from '@/api/workweek';
import { Button, DataTable, TextDescription, Layout } from '@/components';
import { WorkWeekForm } from '@/pages/company/settings/components/WorkWeekForm';

const { WLyPageContainer } = Layout;

const dataTableColumns: ProColumns<WorkWeek>[] = [
  {
    title: 'Name',
    dataIndex: 'name',
    copyable: true,
    ellipsis: true,
    //   tip: "tle's  Name",
  },
  {
    title: 'Timeout To Compute Attendance',
    dataIndex: 'attendanceComputeDelay',
    //   copyable: true,
    ellipsis: true,
    renderText: (weekWork: any) => `${weekWork} Day(s)`,
    //   tip: 'What Schedule Does This Payroll Follow',
  },
  {
    title: 'Regular Work Hours',
    dataIndex: 'weekWork',
    ellipsis: true,
    renderText: (weekWork: any) => `${weekWork} Hours`,
  },
  // TODO: correct this object on the object
  {
    title: 'Work Week Start Day',
    dataIndex: 'workWeekStart',
    copyable: true,
    ellipsis: true,
    tip: 'What day should be considered as the first day of the week',
    renderText: (workWeekStart: any) => {
      // console.log(item, 'payrollSchedule');
      switch (workWeekStart) {
        case 0:
          return 'Monday';
        case 1:
          return 'Tuesday';
        case 2:
          return 'Wednesday';
        case 3:
          return 'Thursday';
        case 4:
          return 'Friday';
        case 5:
          return 'Saturday';
        case 6:
          return 'Sunday';
        default:
          return '';
      }
    },
  },
];

/**
 * @author Adonis SIMO <simo.adonis@workerly.io>
 */
export default () => {
  const [entity, setEntity] = React.useState<WorkWeek>(new WorkWeek());
  const { state: viewForm, toggle: setViewForm } = useBoolean(false);

  const showCreateCompany = useCallback(() => {
    setEntity(new WorkWeek());
    setViewForm();
  }, [setViewForm]);

  const drawerContentRenderer = usePersistFn((entity: WorkWeek) => (
    <Row gutter={[16, 16]}>
      <Col span={12}>
        <TextDescription title="Name">{entity.name}</TextDescription>
      </Col>
      <Col span={12}>
        <TextDescription title="Number Of Hours">
          <span>{entity.weekWork} Hours</span>
        </TextDescription>
      </Col>
      <Col span={12}>
        <TextDescription title="Computation Delay">
          <span>{entity.attendanceComputeDelay} Day (s)</span>
        </TextDescription>
      </Col>
      <Col span={12}>
        <TextDescription title="Work Week Start Day">{entity.workWeekStartDay()}</TextDescription>
      </Col>
    </Row>
  ));

  return (
    <WLyPageContainer extra={[<Button onClick={showCreateCompany}>New Regular Work Week</Button>]}>
      <WorkWeekForm workWeek={entity} visible={viewForm} onCancel={showCreateCompany} />
      <DataTable<WorkWeek, WorkWeekResource>
        model={WorkWeek}
        resource={WorkWeekResource}
        columns={dataTableColumns}
        renderDrawerContent={drawerContentRenderer}
        drawerProps={{
          title: 'Regular Work Week Hours',
          width: 420,
        }}
      />
    </WLyPageContainer>
  );
};
