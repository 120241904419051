import React from 'react';

import { WLyPageContainer } from '@/components/Layout';

/**
 * @author Adonis SIMO <simo.adonis@workerly.io>
 */
export default () => {
  return <WLyPageContainer />;
};
