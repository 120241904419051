/**
 * An interface describing the architecture of an application route.
 * @author Axel Nana <axel.nana@workerly.io>
 */
interface AppRoute {
  /**
   * The route name.
   * @type {string}
   */
  name: string;

  /**
   * The name to display in breadcrumb.
   * If empty, defaults to name.
   * @type {string}
   */
  breadcrumbName?: string;

  /**
   * The route path.
   * @type {string}
   */
  path: string;

  /**
   * The route component to display.
   * @type {string | undefined}
   */
  component?: string;

  /**
   * The locale code name to use for the menu.
   * @type {string | undefined}
   */
  locale?: string;

  /**
   * The locale code name to use for the page title.
   * @type {string | undefined}
   */
  title?: string;

  /**
   * The sub routes items.
   * @type {Array<AppRoute>}
   */
  routes?: Array<AppRoute>;

  /**
   * Specify the icon to use for this menu entry.
   * @type {string}
   */
  icon?: string;
}

/**
 * An interface describing the list of registered application routes.
 * @author Axel Nana <axel.nana@workerly.io>
 */
interface RegisteredAppRoutes {
  /**
   * The login page.
   * @type {AppRoute}
   */
  accountLogin: AppRoute;

  /**
   * The logout page.
   * @type {AppRoute}
   */
  accountLogout: AppRoute;

  /**
   * The signup page.
   * @type {AppRoute}
   */
  accountSignup: AppRoute;

  /**
   * The account verification page.
   * @type {AppRoute}
   */
  accountVerify: AppRoute;

  /**
   * The account password reset page.
   * @type {AppRoute}
   */
  accountPasswordReset: AppRoute;

  /**
   * The account workspaces manager page.
   * @type {AppRoute}
   */
  accountWorkspaces: AppRoute;

  /**
   * The Workerly account settings page.
   * @type {AppRoute}
   */
  accountSettings: AppRoute;

  /**
   * The application dashboard.
   * @type {AppRoute}
   */
  dashboard: AppRoute;

  /**
   * The employees management page.
   * @type {AppRoute}
   */
  employees: AppRoute;

  /**
   * The attendance management page.
   * @type {AppRoute}
   */
  attendances: AppRoute;

  /**
   * The global payroll management page.
   * @type {AppRoute}
   */
  payrolls: AppRoute;

  /**
   * The work shift management page.
   * @type {AppRoute}
   */
  shifts: AppRoute;

  /**
   * The timeoff approvals page.
   * @type {AppRoute}
   */
  timeOffApprovals: AppRoute;

  /**
   * The global timesheet management page.
   * @type {AppRoute}
   */
  timesheets: AppRoute;

  /**
   * The company settings page.
   * @type {AppRoute}
   */
  companySettings: AppRoute;
}

/**
 * Stores the list of routes in the app.
 * @author Axel Nana <axel.nana@workerly.io>
 */
export const AppRoutes: RegisteredAppRoutes = {
  accountLogin: {
    name: 'accountLogin',
    path: '/login',
    component: '@/pages/auth/login',
  },

  accountLogout: {
    name: 'accountLogout',
    path: '/logout',
    component: '@/pages/auth/logout',
  },

  accountSignup: {
    name: 'accountSignup',
    locale: 'auth.signup',
    title: 'title.signup',
    path: '/signup',
    component: '@/pages/auth/signup',
  },

  accountVerify: {
    name: 'accountVerify',
    path: '/verify',
    component: '@/pages/auth/login',
  },

  accountPasswordReset: {
    name: 'accountPasswordReset',
    path: '/password-reset',
    component: '@/pages/auth/password-reset',
  },

  accountWorkspaces: {
    name: 'accountWorkspaces',
    path: '/account/workspaces',
    component: '@/pages/account/workspaces',
  },

  accountSettings: {
    name: 'accountSettings',
    path: '/account/settings',
    component: '@/pages/account/settings',
  },

  dashboard: {
    icon: 'dashboard',
    name: 'dashboard',
    locale: 'menu.dashboard',
    title: 'title.dashboard',
    path: '/dashboard',
    component: '@/pages/dashboard',
  },

  employees: {
    icon: 'employees',
    name: 'employees',
    locale: 'menu.employees',
    title: 'title.employees',
    path: '/employees',
    component: '@/pages/employees',
  },

  attendances: {
    icon: 'attendances',
    name: 'attendances',
    locale: 'menu.attendances',
    breadcrumbName: 'menu.attendances',
    title: 'title.attendances',
    path: '/attendances',
    component: '@/pages/attendances',
  },

  payrolls: {
    icon: 'payment',
    name: 'payrolls',
    locale: 'menu.payrolls',
    breadcrumbName: 'menu.payrolls',
    title: 'title.payrolls',
    path: '/global-payrolls',
    component: '@/pages/payrolls',
  },

  shifts: {
    icon: 'shifts',
    name: 'shifts',
    locale: 'menu.shifts',
    breadcrumbName: 'menu.shifts',
    title: 'title.shifts',
    path: '/work-shifts',
    component: '@/pages/shifts',
  },

  timeOffApprovals: {
    icon: 'time-off',
    name: 'timeOffApproval',
    locale: 'menu.timeOffApproval',
    breadcrumbName: 'menu.timeOffApproval',
    title: 'title.timeOffApproval',
    path: '/time-off-approvals',
    component: '@/pages/time-off-approval',
  },

  timesheets: {
    icon: 'timesheet',
    name: 'timesheets',
    locale: 'menu.timesheets',
    breadcrumbName: 'menu.timesheets',
    title: 'title.timesheets',
    path: '/global-timesheets',
    component: '@/pages/timesheets',
  },

  companySettings: {
    icon: 'settings',
    name: 'companySettings',
    locale: 'menu.companySettings',
    breadcrumbName: 'menu.companySettings',
    title: 'title.companySettings',
    path: '/company/settings',
    routes: [
      {
        icon: 'company',
        name: 'companySettingsProfile',
        locale: 'menu.company.settings.profile',
        breadcrumbName: 'menu.company.settings.profile',
        title: 'title.company.settings.profile',
        path: '/company/settings/profile',
        component: '@/pages/company/settings/profile',
      },
      {
        icon: 'location',
        name: 'companySettingsLocations',
        locale: 'menu.company.settings.locations',
        breadcrumbName: 'menu.company.settings.locations',
        title: 'title.company.settings.locations',
        path: '/company/settings/locations',
        component: '@/pages/company/settings/locations',
      },
      {
        icon: 'department',
        name: 'companySettingsDepartments',
        locale: 'menu.company.settings.departments',
        breadcrumbName: 'menu.company.settings.departments',
        title: 'title.company.settings.departments',
        path: '/company/settings/departments',
        component: '@/pages/company/settings/departments',
      },
      {
        icon: 'job-title',
        name: 'companySettingsPositions',
        locale: 'menu.company.settings.positions',
        breadcrumbName: 'menu.company.settings.positions',
        title: 'title.company.settings.positions',
        path: '/company/settings/positions',
        component: '@/pages/company/settings/positions',
      },
      {
        icon: 'payment',
        name: 'companySettingsPayrolls',
        locale: 'menu.company.settings.payrolls',
        breadcrumbName: 'menu.company.settings.payrolls',
        title: 'title.company.settings.payrolls',
        path: '/company/settings/payrolls',
        component: '@/pages/company/settings/payroll',
      },
      {
        icon: 'payment',
        name: 'companySettingsWorkWeeks',
        locale: 'menu.company.settings.workweeks',
        breadcrumbName: 'menu.company.settings.workweeks',
        title: 'title.company.settings.workweeks',
        path: '/company/settings/workweeks',
        component: '@/pages/company/settings/workweek',
      },
      {
        icon: 'overtime',
        name: 'companySettingsOvertime',
        locale: 'menu.company.settings.overtimes',
        breadcrumbName: 'menu.company.settings.overtimes',
        title: 'title.company.settings.overtimes',
        path: '/company/settings/overtimes-policies',
        component: '@/pages/company/settings/overtimes',
      },
      {
        icon: 'holiday',
        name: 'companySettingsHolidays',
        locale: 'menu.company.settings.holidays',
        breadcrumbName: 'menu.company.settings.holidays',
        title: 'title.company.settings.holidays',
        path: '/company/settings/holidays',
        component: '@/pages/company/settings/holidays',
      },
      {
        icon: 'time-off-policy',
        name: 'companySettingsPTOPolicy',
        locale: 'menu.company.settings.timeoffpolicys',
        breadcrumbName: 'menu.company.settings.timeoffpolicys',
        title: 'title.company.settings.timeoffpolicys',
        path: '/company/settings/timeoffs',
        component: '@/pages/company/settings/time-off',
      },
      {
        icon: 'device',
        name: 'companySettingsDevices',
        locale: 'menu.company.settings.devices',
        breadcrumbName: 'menu.company.settings.devices',
        title: 'title.company.settings.devices',
        path: '/company/settings/devices',
        component: '@/pages/company/settings/devices',
      },
      {
        icon: 'access-level',
        name: 'companySettingsPermissions',
        locale: 'menu.company.settings.permissions',
        breadcrumbName: 'menu.company.settings.permissions',
        title: 'title.company.settings.permissions',
        path: '/company/settings/access',
        component: '@/pages/company/settings/permissions',
      },
      {
        icon: 'hr-advisory',
        name: 'companySettingsHRAdvisory',
        locale: 'menu.company.settings.hradvisory',
        breadcrumbName: 'menu.company.settings.hradvisory',
        title: 'title.company.settings.hradvisory',
        path: '/company/settings/hr-advisory',
        component: '@/pages/company/settings/hradvisory',
      },
    ],
  },
};
