import React, { useCallback } from 'react';
import { Button, Modal, ModalProps } from '@/components';
import { Department } from '@/data';
import { Form, Input, Select, Alert } from 'antd';
import { useCreation, useBoolean, useRequest } from '@umijs/hooks';
import { useResources } from '@/api';
import { useTranslation } from '@/utils/hooks';
import { DepartmentRequestData, DepartmentResource } from '@/api/department';
import { isUndefined } from 'lodash';

const { Option } = Select;

/**
 * Required properties for a CreateDepartmentModal component.
 * @author Adonis SIMO <simo.adonis@workerly.io>
 */
export interface CreateDepartmentModalProps extends ModalProps {
  department?: Department;
  formValidationErrors?: { name?: string[]; description?: string[]; code?: string[]; parent?: string[] } | null;
  onSubmit?(data: DepartmentRequestData): void;
}

/**
 * Create and Update department component.
 * @param {CreateDepartmentModalProps} props
 * @author Adonis SIMO <simo.adonis@workerly.io>
 */
export function CreateDepartmentModal(props: CreateDepartmentModalProps) {
  const { department, onSubmit, onCancel } = props;
  const [form] = Form.useForm();
  const { state: showSuccess, toggle: setShowSuccess } = useBoolean(false);
  const { state: showError, toggle: setShowError } = useBoolean(false);
  const departments = useResources('department');

  const departmentResource = useCreation(() => new DepartmentResource(), []);
  const { run: _createDepartment, loading } = useRequest(departmentResource.create, {
    manual: true,
    onSuccess: (result, params) => {
      if (result.data.status) {
        const { data } = result.data;
        setShowSuccess();
        setTimeout(() => {
          setShowSuccess();
          _onCancel();
        }, 1500);
      } else {
        setShowError();
        const { data } = result.data;
      }
    },
    onError: (error, params) => {
      alert('An error occur when performing the request, please contact the support');
    },
  });

  const { run: _updateDepartment, loading: loadingEdit } = useRequest(departmentResource.update, {
    manual: true,
    onSuccess: (result, params) => {
      if (result.data.status) {
        // const { data } = result.data;
        setShowSuccess();
        setTimeout(() => {
          setShowSuccess();
          _onCancel();
        }, 1500);
      } else {
        setShowError();
        // const { data } = result.data;
      }
    },
    onError: (error, params) => {
      alert('An error occur when performing the request, please contact the support');
    },
  });

  const _ = useTranslation();

  const _onCancel = React.useCallback(
    (e?: React.MouseEvent<HTMLElement>) => {
      form.resetFields();
      onCancel?.apply(null, [e]);
    },
    [onCancel, form],
  );

  const onFinish = useCallback(
    (data) => {
      isUndefined(department) ? _createDepartment(data) : _updateDepartment(department.pk, data);
    },
    [department, onSubmit],
  );

  return (
    <Modal
      {...props}
      ok={false}
      onCancel={_onCancel}
      cancel={false}
      title={!isUndefined(department) ? 'Edit a department' : 'Add A New Department'}
    >
      <Form form={form} onFinish={onFinish} layout="vertical" requiredMark>
        {showSuccess && <Alert message="Department Create Succesfully" type="success" />}
        {showError && <Alert message="Error while creating the department" type={'error'} />}
        <Form.Item
          name="name"
          label={_('settings.departments.form.name')}
          initialValue={department?.name}
          required
          // messageVariables={formValidationErrors?.name}
          // validateStatus={formValidationErrors?.name}
          rules={[
            {
              required: true,
              // message: _('settings.departments.form.name.required'),
            },
          ]}
        >
          <Input />
        </Form.Item>
        {/*{formValidationErrors.name && <small>{formValidationErrors?.name[0]}</small>}*/}

        <Form.Item
          name="code"
          label={_('settings.departments.form.code')}
          required
          initialValue={department?.code}
          rules={[
            {
              required: true,
              // message: _('settings.departments.form.name.required'),
            },
          ]}
          //   validateStatus={validationStatus.company_name}
          //   rules={[
          //     {
          //       required: true,
          //       message: _('workspaces.form.create_company.message.no_company_name'),
          //     },
          //   ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="description"
          label={_('settings.departments.form.description')}
          initialValue={department?.description}
          required
          rules={[
            {
              required: true,
              // message: _('settings.departments.form.name.required'),
            },
          ]}
          //   validateStatus={validationStatus.company_name}
          //   rules={[
          //     {
          //       required: true,
          //       message: _('workspaces.form.create_company.message.no_company_name'),
          //     },
          //   ]}
        >
          <Input.TextArea />
        </Form.Item>

        {/*<Form.Item*/}
        {/*  name="head"*/}
        {/*  label={_('settings.departments.form.head')}*/}
        {/*  //   validateStatus={validationStatus.company_name}*/}
        {/*  //   rules={[*/}
        {/*  //     {*/}
        {/*  //       required: true,*/}
        {/*  //       message: _('workspaces.form.create_company.message.no_company_name'),*/}
        {/*  //     },*/}
        {/*  //   ]}*/}
        {/*>*/}
        {/*  <Select placeholder="Select A Head Of This Department" allowClear={true}>*/}
        {/*    {departments.data?.map((dep) => (*/}
        {/*      <Option key={dep.pk} value={dep.pk}>*/}
        {/*        {' '}*/}
        {/*        {dep.name}*/}
        {/*      </Option>*/}
        {/*    ))}*/}
        {/*  </Select>*/}
        {/*</Form.Item>*/}

        <Form.Item
          name="parent"
          label={_('settings.departments.form.parent')}
          initialValue={department?.parent?.pk}

          //   validateStatus={validationStatus.company_name}
        >
          <Select placeholder="Select A Parent Department" allowClear={true}>
            {departments.data?.map((department) => (
              <Option key={department.pk} value={department.pk}>
                {department.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button disabled={loading} type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
