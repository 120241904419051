import actions from '@/locales/en-US/actions';
import auth from '@/locales/en-US/auth';
import common from '@/locales/en-US/common';
import components from '@/locales/en-US/components';
import menu from '@/locales/en-US/menu';
import settings from '@/locales/en-US/settings';
import status from '@/locales/en-US/status';
import workspaces from '@/locales/en-US/workspaces';

export default {
  ...actions,
  ...auth,
  ...common,
  ...components,
  ...menu,
  ...settings,
  ...status,
  ...workspaces,
  ...settings,
};
