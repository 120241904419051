import React from 'react';
import { Skeleton, Switch, Card, Avatar } from 'antd';
import { MailOutlined, MenuOutlined } from '@ant-design/icons';
import { useBoolean } from '@umijs/hooks';
import classNames from 'classnames';
import { EmployeeListDM } from '@/data';

const { Meta } = Card;

/**
 * The properties required for the employee card component.
 * @author Axel Nana <axel.nana@workerly.io>
 */
export interface EmployeeCardProps {
  /**
   * The displayed employee.
   * @todo Use the correct type after creating data models (Employee).
   */
  employee: EmployeeListDM;

  /**
   * Displays a loading animation on the card.
   * @type {Boolean}
   */
  loading?: boolean;

  /**
   * Creates an hover animation on the card when the mouse is over.
   * @type {Boolean}
   */
  hoverable?: boolean;
}

/**
 * Display data about a single employee.
 * @author Axel Nana <axel.nana@workerly.io>
 * @param {EmployeeCardProps} props Employee card properties
 */
export function EmployeeCard({ loading, employee, hoverable }: EmployeeCardProps) {
  const { state: expanded, toggle: _toggleExpanded } = useBoolean(false);

  return (
    <Card
      className={classNames('wl-component-card wl-component-employee-card', {
        'wl-component-employee-card-expanded': expanded,
      })}
      hoverable={hoverable}
    >
      <Skeleton loading={loading} avatar={{ size: 62, shape: 'circle' }} title={false} active paragraph={{ rows: 2 }}>
        <MenuOutlined className="wl-component-employee-card-menu" onClick={() => _toggleExpanded()} />
        <span className="wl-component-employee-card-id">{employee.uniqueId}</span>
        <Meta
          avatar={
            <Avatar
              src={`${employee.pp ? employee.pp : 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png'}`}
              size={62}
            />
          }
          title={`${employee.name}`}
          description={`${employee.position.name}`}
        />
        <Meta
          className="wl-component-employee-card-details"
          title={
            <span>
              <b>{`${employee.location.name}`}</b>
            </span>
          }
          description={
            <span>
              <MailOutlined /> {employee.email}
            </span>
          }
        />
      </Skeleton>
    </Card>
  );
}
