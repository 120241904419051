import { Popover } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { Link } from 'umi';

import { useTranslation } from '@/utils/hooks';
import { AppRoutes } from '@/utils/routes';

/**
 * Renders the application user menu in the application header
 * @author Axel Nana <axel.nana@workerly.io>
 */
export function WLyRightPanel() {
  const _ = useTranslation();

  const content = (
    <div>
      <Link to={AppRoutes.companySettings.path}>
        <span>
          <SettingOutlined />
          {_(`menu.${AppRoutes.companySettings.name}`)}
        </span>
      </Link>
    </div>
  );

  return (
    <div className="rightContainer">
      <Popover placement="bottomRight" content={content}>
        {`Hello, Sid`}
      </Popover>
    </div>
  );
}
