import React, { useCallback } from 'react';
import { WLyPageContainer } from '@/components/Layout';
import { DataTable } from '@/components';
import { useBoolean } from '@umijs/hooks';
import { useProTableRequest } from '@/utils/hooks';
import { ParentTimeoffRessource, ParentPTOPolicyResponseData } from '@/api/time-off';
import {} from '@/data/';

/**
 *
 * @author Adonis SIMO <simo.adonis@workerly.io>
 */
export default () => {
  // TODO: rework this entire module
  // const [activeObject, setActiveObject] = React.useState<Payroll>(new Payroll());
  // const { state: visible, toggle: setVisible } = useBoolean(false);
  // const showDetails = useCallback(
  //   (p) => {
  //     setActiveObject(p);
  //     setVisible();
  //   },
  //   [activeObject],
  // );
  // const setVisibleCb = useCallback(() => setVisible(), [setVisible]);

  // const payrollResource = new PayrollResource();
  // const request = useProTableRequest<Payroll, PayrollResponseData>({
  //   model: Payroll,
  //   resource: payrollResource,
  //   // cacheKey: 'wl-list-department',
  // });

  // const columns: ProColumns<Payroll>[] = [
  //   {
  //     title: 'Name',
  //     dataIndex: 'name',
  //     copyable: true,
  //     ellipsis: true,
  //     tip: "Payroll's  Name",
  //   },
  //   {
  //     title: 'Schedule',
  //     dataIndex: 'payrollSchedule',
  //     copyable: true,
  //     ellipsis: true,
  //     tip: 'What Schedule Does This Payroll Follow',
  //     renderText: (payrollSchedule: any) => {
  //       // console.log(item, 'payrollSchedule');
  //       switch (payrollSchedule) {
  //         case 0:
  //           return 'Monthly';
  //         case 1:
  //           return 'Semi-Montly';
  //         case 2:
  //           return 'Bi-Weekly';
  //         case 3:
  //           return 'Weekly';
  //         case 4:
  //           return 'Daily';
  //         default:
  //           return payrollSchedule;
  //       }
  //     },
  //   },
  //   {
  //     title: 'Next Deadline',
  //     dataIndex: 'nextDeadline',
  //     copyable: false,
  //     ellipsis: true,
  //     renderText: (nextDeadline: any) =>
  //       nextDeadline ? nextDeadline.toLocaleString(DateTime.DATETIME_MED) : new Date().toString(),
  //     //   tip: ,
  //   },
  //   {
  //     title: 'Previous Deadline',
  //     dataIndex: 'previousDeadline',
  //     copyable: false,
  //     ellipsis: true,
  //     //   tip: "The Next Deadline",
  //     renderText: (previousDeadline: any) =>
  //       previousDeadline ? previousDeadline.toLocaleString(DateTime.DATETIME_MED) : new Date().toString(),
  //   },
  //   {
  //     title: 'Payroll Periods',
  //     dataIndex: 'yearPayroll',
  //     copyable: false,
  //     ellipsis: true,
  //     tip: 'Number of Fimeframe In One Year Corresponding To This Payroll',
  //     renderText: (item) => item?.length,
  //   },
  //   {
  //     title: 'Actions',
  //     key: 'option',
  //     valueType: 'option',
  //     width: '10%',
  //     render: (item: any, entity) =>
  //       item.default === false
  //         ? [
  //             <Button onClick={() => showDetails(entity)} size="small" type="secondary" key="view">
  //               <EyeOutlined />
  //             </Button>,
  //             <Button size="small" type="success" key="assign">
  //               <UserAddOutlined />
  //             </Button>,
  //             <Button size="small" type="primary" key="edit">
  //               <EditOutlined />
  //             </Button>,
  //             <Button size="small" type="danger" key="delete">
  //               <DeleteOutlined />
  //             </Button>,
  //           ]
  //         : [
  //             <Button onClick={() => showDetails(entity)} size="small" type="secondary" key="view">
  //               <EyeOutlined />
  //             </Button>,
  //           ],
  //   },
  // ];

  return (
    <WLyPageContainer>
      {/* <GenericView
        request={request}
        model={Device}
        columns={columns}
        rowKey="pk"
        headerTitle="Punch Devices Across the company"
        btnMainAction="Add New..."
      /> */}
    </WLyPageContainer>
  );
};
