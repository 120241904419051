import React, { useState } from 'react';
import { Role } from '@/data/Role';
import { Col, Row, Popconfirm, Divider, Collapse, Table, Typography, List } from 'antd';
import { Button, TextDescription } from '@/components';
import { LoadingOutlined, GatewayOutlined, OrderedListOutlined } from '@ant-design/icons';
import { useMount } from '@umijs/hooks';
import { Department, Employee, Location } from '@/data';
import _ from 'lodash';

const { Panel } = Collapse;
const { Text } = Typography;

const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

/**
 * Props for RoleDetails
 * @author Adonis SIMO <Simo.adonis@workerly.io?
 */
interface RoleDetailProps {
  /**
   * The role which will be displayed in details
   */
  role: Role;
}

export default function RoleDetail({ role }: React.PropsWithChildren<RoleDetailProps>) {
  // if "null" we display a loader otherwhise the content of the array
  const [managedEmployees, setManagedEmployees] = useState<Employee[] | null>(null);
  const [managedLocations, setManagedLocation] = useState<Location[] | null>(null);
  const [managedDepartments, setManagedDepartments] = useState<Department[] | null>(null);
  const genExtra = () => (
    <GatewayOutlined
      onClick={(event) => {
        // If you don't want click extra trigger collapse, you can prevent this:
        event.stopPropagation();
      }}
    />
  );

  /**
   * get the managed objects handled by this role
   */
  useMount(() => {
    role.getRelated('managed_employees').then((r) => setManagedEmployees(r));
    role.getRelated('managed_locations').then((r) => setManagedLocation(r));
    role.getRelated('managed_departments').then((r) => setManagedDepartments(r));
  });
  var l = [];
  for (let key in role.actions) {
    l.push(
      <Panel style={{ backgroundColor: 'white' }} header={key} key={key} extra={genExtra()}>
        <List>
          {role.actions[key].map((v: string, i: number) => (
            <List.Item key={i}>{v}</List.Item>
          ))}
        </List>
      </Panel>,
    );
  }

  /**
   * Return the component to display in case of displaying a list of managed objects by
   * a role
   * @param managedObject Either a list of employees, departments , location or null
   * @author Adonis SIMO <simo.adonis@workerly.io>
   */
  function getManagedList(managedObject: Department[] | Location[] | Employee[] | null) {
    if (managedObject === null) {
      return (
        <>
          <Text>Loading </Text>
          <LoadingOutlined />
        </>
      );
    } else {
      if (managedObject.length === 0)
        return (
          <>
            <OrderedListOutlined />
            &nbsp;
            <Text>No Managed Entity Found</Text>
          </>
        );
      return (
        <List>
          {managedObject.map((v: Department | Location | Employee, i: number) => (
            <List.Item key={i}>{v.name}</List.Item>
          ))}
        </List>
      );
    }
  }

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <TextDescription title="Name">{role.name}</TextDescription>
      </Col>
      <Col span={24}>
        <TextDescription title="Permissions"></TextDescription>
        <Collapse bordered={false} accordion>
          {l}
        </Collapse>
      </Col>
      <Col span={24}>
        <Divider>Managed Entities</Divider>
      </Col>
      <Col span={24}>
        <Collapse bordered={false} defaultActiveKey={['1']}>
          <Panel style={{ backgroundColor: 'white' }} header="Departments" key="1">
            {getManagedList(managedDepartments)}
          </Panel>
          <Panel style={{ backgroundColor: 'white' }} header="Employees" key="2">
            {getManagedList(managedEmployees)}
          </Panel>
          <Panel style={{ backgroundColor: 'white' }} header="Locations" key="3">
            {getManagedList(managedLocations)}
          </Panel>
        </Collapse>
      </Col>
    </Row>
  );
}
