import { useRequest } from 'umi';
import { RequestOptionsInit } from 'umi-request';

import { LocationResource } from '@/api/location';
import { IndustryResource } from '@/api/industry';
import { RateResource } from '@/api/rate';
import { PaginationQueryParameters } from '@/api/types';
import { DepartmentResource } from '@/api/department';
import { DeviceResource } from '@/api/device';
import { EmployeeListResource } from '@/api/employees';
import { RoleResource } from '@/api/role';
import {
  DataModel,
  Department,
  Device,
  Employee,
  EmployeeListDM,
  Industry,
  Location,
  Payroll,
  Position,
  Rate,
  Role,
} from '@/data';
import { PositionResource } from './position';
import { PayrollResource } from '@/api/payroll';

/**
 * Store required info to use in the `useResources` hook and
 * the `useResource` hook for each usable resource.
 * @author Adonis SIMO <simo.adonis@workerly.io>
 */
export const modelsMap = {
  department: {
    resource: DepartmentResource,
    multiCacheKey: 'wly-request-departments',
    oneCacheKey: 'wly-request-department',
    model: Department,
    defaultTenant: false,
  },
  device: {
    resource: DeviceResource,
    multiCacheKey: 'wly-request-devices',
    oneCacheKey: 'wly-request-device',
    model: Device,
    defaultTenant: false,
  },
  employeesDetails: {
    resource: EmployeeListResource,
    multiCacheKey: 'wly-request-details-employees',
    oneCacheKey: 'wly-request-details-employee',
    model: Employee,
    defaultTenant: false,
  },
  employeesCreation: {
    resource: EmployeeListResource,
    multiCacheKey: 'wly-request-create-employees',
    oneCacheKey: 'wly-request-create-employee',
    model: Employee,
    defaultTenant: false,
  },
  employee: {
    resource: EmployeeListResource,
    multiCacheKey: 'wly-request-list-employees',
    oneCacheKey: 'wly-request-list-employee',
    model: EmployeeListDM,
    defaultTenant: false,
  },
  industry: {
    resource: IndustryResource,
    multiCacheKey: 'wly-request-industries',
    oneCacheKey: 'wly-request-industry',
    model: Industry,
    defaultTenant: true,
  },
  location: {
    resource: LocationResource,
    multiCacheKey: 'wly-request-locations',
    oneCacheKey: 'wly-request-location',
    model: Location,
    defaultTenant: false,
  },
  payroll: {
    resource: PayrollResource,
    multiCacheKey: 'wly-request-payrolls',
    oneCacheKey: 'wly-request-payroll',
    model: Payroll,
    defaultTenant: false,
  },
  position: {
    resource: PositionResource,
    multiCacheKey: 'wly-request-positions',
    oneCacheKey: 'wly-request-position',
    model: Position,
    defaultTenant: false,
  },
  rate: {
    resource: RateResource,
    multiCacheKey: 'wly-request-rates',
    oneCacheKey: 'wly-request-rate',
    model: Rate,
    defaultTenant: false,
  },
  role: {
    resource: RoleResource,
    multiCacheKey: 'wly-request-roles',
    oneCacheKey: 'wly-request-role',
    model: Role,
    defaultTenant: false,
  },
};

/**
 * The generic response type for an `useResources` call.
 * @author Axel Nana <axel.nana@workerly.io>
 */
export interface UseResourcesResponse<T extends DataModel> {
  data?: T[];
  error?: Error;
  loading: boolean;

  cancel(...args: any[]): void;

  reset(): void;
}

/**
 * The generic response type for an `useResource` call.
 * @author Axel Nana <axel.nana@workerly.io>
 */
export interface UseResourceResponse<T extends DataModel> {
  data?: T;
  error?: Error;
  loading: boolean;

  cancel(...args: any[]): void;

  reset(): void;
}

/**
 * Retrieves a list of items wrapped in the corresponding model for a specific resource.
 * @param {string} modelName The name of one of the callable resources.
 * @param {PaginationQueryParameters | undefined} params Pagination parameters.
 * @author Adonis SIMO <simo.adonis@workerly.io>
 */
export function useResources(
  modelName: 'department',
  params?: PaginationQueryParameters,
): UseResourcesResponse<Department>;
export function useResources(modelName: 'device', params?: PaginationQueryParameters): UseResourcesResponse<Device>;
export function useResources(
  modelName: 'employee',
  params?: PaginationQueryParameters,
): UseResourcesResponse<EmployeeListDM>;
export function useResources(modelName: 'industry', params?: PaginationQueryParameters): UseResourcesResponse<Industry>;
export function useResources(modelName: 'location', params?: PaginationQueryParameters): UseResourcesResponse<Location>;
export function useResources(modelName: 'rate', params?: PaginationQueryParameters): UseResourcesResponse<Rate>;
export function useResources(modelName: 'role', params?: PaginationQueryParameters): UseResourcesResponse<Role>;
export function useResources(modelName: 'position', params?: PaginationQueryParameters): UseResourcesResponse<Position>;
export function useResources(modelName: 'payroll', params?: PaginationQueryParameters): UseResourcesResponse<Payroll>;
export function useResources(
  modelName: keyof typeof modelsMap,
  params?: PaginationQueryParameters,
): UseResourcesResponse<DataModel> | null {
  if (modelsMap[modelName] === undefined) {
    return null;
  }

  const item = modelsMap[modelName];
  const resource = new item.resource();

  const options: RequestOptionsInit = {};
  if (item.defaultTenant) {
    if (options.headers === undefined) {
      options.headers = {};
    }

    (<any>options.headers)['Tenant'] = 'workerly.io';
  }

  const { data, error, loading, cancel, reset } = useRequest(
    () => (params !== undefined ? resource.list(params.current, params.pageSize, options) : resource.all(options)),
    {
      cacheKey:
        params !== undefined
          ? `${item.multiCacheKey}-${params.current}-${params.pageSize}`
          : `${item.multiCacheKey}-all`,
      formatResult: ({ data }) => (data.status ? data.data?.data.map((i) => new item.model(<any>i)) || [] : []),
    },
  );

  return {
    data,
    error,
    loading,
    cancel,
    reset,
  };
}

/**
 * Retrieves only one item wrapped in the corresponding model for a specific resource.
 * @param {string} modelName The name of one of the callable resources.
 * @param {string} pk The ID of the item to retrieve.
 * @author Adonis SIMO <simo.adonis@workerly.io>
 */
export function useResource(modelName: 'department', pk: string): UseResourceResponse<Department>;
export function useResource(modelName: 'device', pk: string): UseResourceResponse<Device>;
export function useResource(modelName: 'employee', pk: string): UseResourceResponse<Employee>;
export function useResource(modelName: 'industry', pk: string): UseResourceResponse<Industry>;
export function useResource(modelName: 'location', pk: string): UseResourceResponse<Location>;
export function useResource(modelName: 'rate', pk: string): UseResourceResponse<Rate>;
export function useResource(modelName: 'role', pk: string): UseResourceResponse<Role>;
export function useResource(modelName: 'position', pk: string): UseResourceResponse<Position>;
export function useResource(modelName: 'payroll', pk: string): UseResourceResponse<Payroll>;
export function useResource(modelName: keyof typeof modelsMap, pk: string): UseResourceResponse<DataModel> | null {
  if (modelsMap[modelName] === undefined) {
    return null;
  }

  const item = modelsMap[modelName];
  const resource = new item.resource();

  const options: RequestOptionsInit = {};
  if (item.defaultTenant) {
    if (options.headers === undefined) {
      options.headers = {};
    }

    (<any>options.headers)['Tenant'] = 'workerly.io';
  }

  const { data, error, loading, cancel, reset } = useRequest(() => resource.get(pk, options), {
    cacheKey: `${item.oneCacheKey}-${pk}`,
    formatResult: ({ data }) => (data.status ? new item.model(<any>data.data) : undefined),
  });

  return {
    data,
    error,
    loading,
    cancel,
    reset,
  };
}
