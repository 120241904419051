import React from 'react';
import { useBoolean } from '@umijs/hooks';
import { ProColumns } from '@ant-design/pro-table';

import { Location } from '@/data';
import { DataTable, Layout } from '@/components';
import { LocationResource } from '@/api/location';

const { WLyPageContainer } = Layout;

const dataTableColumns: ProColumns<Location>[] = [
  {
    title: 'Name',
    dataIndex: 'name',
  },
  {
    title: 'City',
    dataIndex: 'city',
  },
  {
    title: 'Address',
    dataIndex: 'address',
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
  },
  {
    title: 'Email',
    dataIndex: 'email',
  },
];

export default () => {
  return (
    <WLyPageContainer>
      <DataTable<Location, LocationResource>
        model={Location}
        resource={LocationResource}
        columns={dataTableColumns}
        drawerProps={{
          title: 'Consult Location',
          width: 640,
        }}
      />
    </WLyPageContainer>
  );
};
