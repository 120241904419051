import { Spin, Input, Modal as DefaultModal } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useIntl } from 'umi';

import { Button } from '@/components';

/**
 * Allowed properties for a Modal component.
 * @author Axel Nana <axel.nana@workerly.io>
 */
export interface ModalProps {
  /**
   * The modal title.
   * @type {React.ReactNode}
   */
  title?: React.ReactNode;

  /**
   * Defines if the modal is visible or not.
   * @type {boolean}
   */
  visible?: boolean;

  /**
   * Defines if the modal is doing something and requires
   * to render a loading animation.
   * @type {boolean}
   */
  loading?: boolean;

  /**
   * Defines the text to show during a loading animation.
   * @type {string}
   */
  loadingText?: string;

  /**
   * Defines the size of the loading spinner.
   * @type {'default' | 'small' | 'large'}
   */
  loadingSize?: 'default' | 'small' | 'large';

  /**
   * Defines the delay to wait before to show the loading animation.
   * @type {'default' | 'small' | 'large'}
   */
  loadingDelay?: number;

  /**
   * Event triggered when the user click on the positive button of this modal.
   * @param {React.MouseEvent<HTMLElement, MouseEvent>} e The event object.
   */
  onOk?(e?: React.MouseEvent<HTMLElement, MouseEvent>): void;

  /**
   * Event triggered when the user click on the negative button of this modal.
   * @param {React.MouseEvent<HTMLElement, MouseEvent>} e The event object.
   */
  onCancel?(e?: React.MouseEvent<HTMLElement, MouseEvent>): void;

  /**
   * The content of the positive button of this modal.
   * @type {React.ReactNode}
   */
  okText?: React.ReactNode;

  /**
   * The content of the negative button of this modal.
   * @type {React.ReactNode}
   */
  cancelText?: React.ReactNode;

  /**
   * The left icon of the positive button of this modal.
   * @type {React.ReactNode}
   */
  okLeftIcon?: React.ReactNode;

  /**
   * The right icon of the positive button of this modal.
   * @type {React.ReactNode}
   */
  okRightIcon?: React.ReactNode;

  /**
   * The left icon of the negative button of this modal.
   * @type {React.ReactNode}
   */
  cancelLeftIcon?: React.ReactNode;

  /**
   * The right icon of the negative button of this modal.
   * @type {React.ReactNode}
   */
  cancelRightIcon?: React.ReactNode;

  /**
   * If the ok button should appear.
   * @type {boolean}
   */
  ok?: boolean;

  /**
   * If the cancel button should appear.
   * @type {boolean}
   */
  cancel?: boolean;

  /**
   * The ok button type.
   * @type {"info" | "success" | "primary" | "disabled" | "danger" | "secondary" | "warning" | "text"}
   */
  okType?: 'info' | 'success' | 'primary' | 'disabled' | 'danger' | 'secondary' | 'warning' | 'text';

  /**
   * The cancel button type.
   * @type {"info" | "success" | "primary" | "disabled" | "danger" | "secondary" | "warning" | "text"}
   */
  cancelType?: 'info' | 'success' | 'primary' | 'disabled' | 'danger' | 'secondary' | 'warning' | 'text';
}

/**
 * Renders an application modal.
 * @author Axel Nana <axel.nana@workerly.io>
 * @param {React.PropsWithChildren<ModalProps>} param0 The Modal component properties.
 */
export function Modal({
  children,
  title,
  visible,
  loading,
  loadingText,
  loadingSize,
  loadingDelay,
  onOk,
  onCancel,
  okText,
  cancelText,
  okLeftIcon,
  okRightIcon,
  cancelLeftIcon,
  cancelRightIcon,
  ok,
  cancel,
  okType,
  cancelType,
}: React.PropsWithChildren<ModalProps>) {
  const intl = useIntl();

  okText = okText || intl.formatMessage({ id: 'action.ok' });
  cancelText = cancelText || intl.formatMessage({ id: 'action.cancel' });

  return (
    <DefaultModal
      className="wl-component-modal"
      visible={visible}
      title={title}
      onOk={onOk}
      onCancel={onCancel}
      footer={[
        <div key="modal-footer-wrapper" className="wl-component-modal-footer">
          {cancel ? (
            <Button
              key="back"
              type={cancelType}
              leftIcon={cancelLeftIcon}
              rightIcon={cancelRightIcon}
              disabled={loading}
              onClick={onCancel}
            >
              {cancelText}
            </Button>
          ) : (
            <span />
          )}
          {ok ? (
            <Button
              key="submit"
              type={okType}
              leftIcon={okLeftIcon}
              rightIcon={okRightIcon}
              loading={loading}
              onClick={onOk}
            >
              {okText}
            </Button>
          ) : (
            <span />
          )}
        </div>,
      ]}
    >
      <Spin
        indicator={<LoadingOutlined />}
        spinning={loading}
        tip={loadingText}
        size={loadingSize}
        delay={loadingDelay}
      >
        {children}
      </Spin>
    </DefaultModal>
  );
}

Modal.defaultProps = {
  visible: false,
  loading: false,
  ok: true,
  cancel: true,
  okType: 'success',
  cancelType: 'danger',
};
