import React from 'react';
import { Redirect, useModel, Link, useIntl } from 'umi';
import { Layout, Row, Col, Card, Form, Input, Checkbox, Spin } from 'antd';
import { LockOutlined, MailOutlined, LoadingOutlined } from '@ant-design/icons';

import WorkerLyLogoHorizontal from '@/assets/images/brand/logo_horizontal.png';
import { AppRoutes } from '@/utils/routes';
import { Button } from '@/components';

import '@/pages/auth/auth.less';

const { Content } = Layout;

interface LoginFormInputLabelProps {
  rightLink?: React.ReactNode;
  label: string;
}

function LoginFormInputLabel(props: LoginFormInputLabelProps) {
  return (
    <Row style={{ margin: 0, width: '100%' }}>
      <Col span={12} style={{ textAlign: 'left' }}>
        {props.label}
      </Col>
      {props.rightLink && (
        <Col span={12} style={{ textAlign: 'right' }}>
          {props.rightLink}
        </Col>
      )}
    </Row>
  );
}

export default () => {
  const { loading, logIn, isValidState } = useModel('user');
  const intl = useIntl();

  if (isValidState) {
    return <Redirect to={AppRoutes.accountWorkspaces.path} />;
  }

  return (
    <Layout className="wl-auth-page wl-fullscreen" hasSider={false}>
      <Content className="wl-fullscreen">
        <Row justify="space-around" align="middle" className="wl-fullscreen">
          <Col xs={22} sm={20} md={10} lg={6}>
            <Card bordered className="wl-auth-page-card-form">
              <Spin indicator={<LoadingOutlined />} spinning={loading} tip="Please wait...">
                <Row justify="space-around">
                  <Col>
                    <img className="wl-auth-page-logo" src={WorkerLyLogoHorizontal} />
                  </Col>
                </Row>
                <Row justify="space-around">
                  <Col span={24}>
                    <p className="wl-auth-page-intro-text">
                      {intl.formatMessage({ id: 'auth.login.page.form.intro' })}
                    </p>
                  </Col>
                  <Col span={24}>
                    <Form
                      name="login"
                      layout="vertical"
                      className="wl-auth-page-form"
                      initialValues={{ remember: true }}
                      onFinish={logIn}
                    >
                      <Form.Item
                        labelCol={{ span: 24 }}
                        label={
                          <LoginFormInputLabel
                            label={intl.formatMessage({ id: 'auth.login.page.form.label.email' })}
                            rightLink={
                              <Link to={AppRoutes.accountVerify.path}>
                                {intl.formatMessage({ id: 'auth.login.page.form.label.not_verified' })}
                              </Link>
                            }
                          />
                        }
                        name="username"
                        rules={[{ required: true, message: intl.formatMessage({ id: 'auth.login.errors.no_email' }) }]}
                      >
                        <Input
                          prefix={<MailOutlined className="wl-form-item-icon" />}
                          placeholder={intl.formatMessage({ id: 'auth.login.page.form.label.email' })}
                        />
                      </Form.Item>
                      <Form.Item
                        labelCol={{ span: 24 }}
                        label={
                          <LoginFormInputLabel
                            label={intl.formatMessage({ id: 'auth.login.page.form.label.password' })}
                            rightLink={
                              <Link to={AppRoutes.accountPasswordReset.path}>
                                {intl.formatMessage({ id: 'auth.login.page.form.label.forgot_password' })}
                              </Link>
                            }
                          />
                        }
                        name="password"
                        rules={[
                          { required: true, message: intl.formatMessage({ id: 'auth.login.errors.no_password' }) },
                        ]}
                      >
                        <Input
                          prefix={<LockOutlined className="wl-form-item-icon" />}
                          type="password"
                          placeholder={intl.formatMessage({ id: 'auth.login.page.form.label.password' })}
                        />
                      </Form.Item>
                      <Form.Item>
                        <Form.Item name="remember" valuePropName="checked" noStyle>
                          <Checkbox>{intl.formatMessage({ id: 'auth.login.page.form.label.remember_me' })}</Checkbox>
                        </Form.Item>
                      </Form.Item>

                      <Form.Item className="wl-auth-page-form-login-wrapper">
                        <Button type="primary" htmlType="submit" className="wl-auth-page-form-button-login">
                          {intl.formatMessage({ id: 'auth.login.page.form.action.login' })}
                        </Button>
                        {intl.formatMessage(
                          { id: 'auth.login.page.form.action.signup' },
                          { a: (...chunks) => <Link to={AppRoutes.accountSignup.path}>{chunks}</Link> },
                        )}
                      </Form.Item>
                    </Form>
                  </Col>
                </Row>
              </Spin>
            </Card>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};
