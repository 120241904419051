import React from 'react';
import { Select, Form, Input, Radio, message } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { useIntl, useModel, useRequest } from 'umi';
import { useCreation } from '@umijs/hooks';

import { Modal, ModalProps, PlacesAutocomplete } from '@/components';
import { filter, includes, isEmpty, lowerCase, remove, uniq } from 'lodash';
import { CompanyRequestData, CompanyResource, CompanyType } from '@/api/company';
import { useResources } from '@/api';

type ValidationState = '' | 'error' | 'success' | 'warning' | 'validating' | undefined;

export interface CreateCompanyModalProps extends ModalProps {
  callback?(company: any): void;
}

export function CreateCompanyModal({ callback, onCancel, ...props }: CreateCompanyModalProps) {
  const intl = useIntl();
  const industries = useResources('industry');
  const rates = useResources('rate');
  const { user, token } = useModel('user');
  const { refreshWorkspaces } = useModel('workspaces');
  const [form] = Form.useForm();

  const [companyName, setCompanyName] = React.useState<string>();
  const [companyAddress, setCompanyAddress] = React.useState<any>();
  const [companyIndustries, setCompanyIndustries] = React.useState<Array<string>>([]);
  const [companyType, setCompanyType] = React.useState<number>(-1);
  const [validationStatus, setValidationStatus] = React.useState<{
    company_name: ValidationState;
    company_address: ValidationState;
    company_industries: ValidationState;
    company_type: ValidationState;
  }>({
    company_name: '',
    company_address: '',
    company_industries: '',
    company_type: '',
  });

  const companyResource = useCreation(() => new CompanyResource(), []);
  const { run: _createCompany, loading } = useRequest(companyResource.create, {
    manual: true,
    onSuccess: (result, params) => {
      if (result.status) {
        message.success({
          content: intl.formatMessage(
            { id: 'workspaces.form.create_company.message.company_created_success' },
            { name: <b>{params[0].name}</b> },
          ),
        });
      }
    },
  });

  const _onCompanyNameChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setCompanyName(e.target.value);
    setValidationStatus(old => ({
      ...old,
      company_name: isEmpty(e.target.value) ? 'error' : 'success',
    }));
  }, []);

  const _onClearSelectedSubIndustries = React.useCallback(() => {
    setCompanyIndustries([]);
    setValidationStatus(old => ({
      ...old,
      company_industries: '',
    }));
  }, []);

  const _onDeselectSubIndustry = React.useCallback((value: string) => {
    const _s = [...companyIndustries];
    remove(_s, v => v !== value);
    setCompanyIndustries(_s);
    setValidationStatus(old => ({
      ...old,
      company_industries: isEmpty(_s) ? 'error' : 'success',
    }));
  }, []);

  const _onSelectSubIndustry = React.useCallback((value: string) => {
    const _s = [...companyIndustries];
    _s.push(value);
    setCompanyIndustries(uniq(_s));
    setValidationStatus(old => ({
      ...old,
      company_industries: isEmpty(_s) ? 'error' : 'success',
    }));
  }, []);

  const _onCompanyStaffingStateChange = React.useCallback((e: any) => {
    setCompanyType(e.target.value);
    setValidationStatus(old => ({
      ...old,
      company_type: e.target.value === -1 ? 'error' : 'success',
    }));
  }, []);

  const _onCreateCompany = React.useCallback(async () => {
    try {
      setValidationStatus(old => ({
        ...old,
        company_name: isEmpty(companyName) ? 'error' : 'success',
      }));

      setValidationStatus(old => ({
        ...old,
        company_industries: isEmpty(companyIndustries) ? 'error' : 'success',
      }));

      setValidationStatus(old => ({
        ...old,
        company_type: companyType === -1 ? 'error' : 'success',
      }));

      await form.validateFields();

      if (companyType === -1) {
        return message.error({
          content: intl.formatMessage({ id: 'workspaces.form.create_company.message.no_company_type' }),
        });
      }

      if (isEmpty(companyAddress)) {
        return message.error({
          content: intl.formatMessage({ id: 'workspaces.form.create_company.message.no_company_address' }),
        });
      }

      const state = filter(
        companyAddress.geocoding.address_components,
        address => includes(address.types, 'administrative_area_level_1') && address.long_name,
      )[0];

      const company: CompanyRequestData = {
        name: companyName,
        industries: companyIndustries,
        company_type: companyType,
        city: filter(
          companyAddress.geocoding.address_components,
          address => includes(address.types, 'locality') && address.long_name,
        )[0]?.long_name,
        state_or_region: state?.long_name,
        country: filter(
          companyAddress.geocoding.address_components,
          address => includes(address.types, 'country') && address.long_name,
        )[0]?.long_name,
        address_line_1: companyAddress.description,
        owner: user.pk,
        full_timezone: companyAddress.timezone,
        head_location: filter(rates.data, rate => lowerCase(rate.code_state) === lowerCase(state?.short_name))[0]?.pk,
        token,
        timezone: companyAddress.timezone,
      };

      const response = await _createCompany(company);

      if (response.status) {
        _onCancel();

        await refreshWorkspaces();
      } else {
        throw new Error('An error occurred while creating the company.');
      }
    } catch (e) {
      console.error(e);
    }
  }, [companyName, companyIndustries, companyType, companyAddress, user, rates]);

  const _onCompanyAddressChange = React.useCallback((place: any): void => {
    setCompanyAddress(place);
    form.setFields([{ name: 'company_address', value: place }]);
    setValidationStatus(old => ({
      ...old,
      company_address: isEmpty(place) ? 'error' : 'success',
    }));
  }, []);

  const _onCancel = React.useCallback(
    (e?: React.MouseEvent<HTMLElement>) => {
      form.resetFields();
      onCancel?.apply(null, [e]);
    },
    [onCancel, form],
  );

  return (
    <Modal
      {...props}
      onCancel={_onCancel}
      loading={loading}
      loadingText={intl.formatMessage({ id: 'workspaces.form.create_company.message.loading_text' })}
      title={intl.formatMessage({ id: 'workspaces.form.create_company.title' })}
      okText={<b>{intl.formatMessage({ id: 'action.create' })}</b>}
      okRightIcon={<CheckCircleOutlined />}
      onOk={_onCreateCompany}
    >
      <Form form={form} layout="vertical" requiredMark>
        <Form.Item
          name="company_name"
          label={intl.formatMessage({ id: 'workspaces.form.create_company.label.company_name' })}
          required
          validateStatus={validationStatus.company_name}
          rules={[
            {
              required: true,
              message: intl.formatMessage({ id: 'workspaces.form.create_company.message.no_company_name' }),
            },
          ]}
        >
          <Input value={companyName} onChange={_onCompanyNameChange} placeholder="Workerly, Inc." />
        </Form.Item>
        <Form.Item
          name="company_address"
          label={intl.formatMessage({ id: 'workspaces.form.create_company.label.company_headquarter' })}
          validateStatus={validationStatus.company_address}
          required
          rules={[
            {
              required: true,
              message: intl.formatMessage({ id: 'workspaces.form.create_company.message.no_company_address' }),
            },
          ]}
        >
          <PlacesAutocomplete
            placeholder="1177 Avenue of the Americas, New York, NY, USA"
            onSelectionChange={_onCompanyAddressChange}
          />
        </Form.Item>
        <Form.Item
          name="company_industries"
          label={intl.formatMessage({ id: 'workspaces.form.create_company.label.company_industries' })}
          validateStatus={validationStatus.company_industries}
          required
          rules={[
            {
              required: true,
              message: intl.formatMessage({ id: 'workspaces.form.create_company.message.no_company_industry' }),
            },
          ]}
        >
          <Select
            showSearch
            optionFilterProp="title"
            filterOption={(input, option) => option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            mode="multiple"
            allowClear
            placeholder={intl.formatMessage({ id: 'workspaces.form.create_company.placeholder.company_industries' })}
            loading={industries.loading}
            value={companyIndustries}
            onClear={_onClearSelectedSubIndustries}
            onDeselect={_onDeselectSubIndustry}
            onSelect={_onSelectSubIndustry}
          >
            {industries.data?.map(i => (
              <Select.Option title={i.name} value={i.pk} key={i.pk}>
                {i.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="company_type"
          label={intl.formatMessage({ id: 'workspaces.form.create_company.label.company_staffing' })}
          validateStatus={validationStatus.company_type}
          required
          rules={[
            {
              required: true,
              message: intl.formatMessage({ id: 'workspaces.form.create_company.message.no_company_type' }),
            },
          ]}
        >
          <Radio.Group onChange={_onCompanyStaffingStateChange} value={companyType}>
            <Radio value={CompanyType.Staffing}>{intl.formatMessage({ id: 'action.yes' })}</Radio>
            <Radio value={CompanyType.NonStaffing}>{intl.formatMessage({ id: 'action.no' })}</Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
}
