import React from 'react';
import { Space, Spin, Tag, Typography } from 'antd';
import ProCard from '@ant-design/pro-card';
import { EnvironmentFilled } from '@ant-design/icons';
import { useModel, useRequest } from 'umi';
import { useCreation, useSet, useToggle } from '@umijs/hooks';
import { isNull, isUndefined } from 'lodash';

import { CompanyResource } from '@/api/company';
import { Button, TextDescription } from '@/components';
import { Company, Industry } from '@/data';
import { WLyPageContainer } from '@/components/Layout';

const { Text, Title, Paragraph } = Typography;

/**
 * Company profile settings
 * @author Axel Nana <axel.nana@workerly.io>
 */
export default () => {
  const { activeWorkspace } = useModel('workspaces');

  const { state: activeTab, toggle: setActiveTab } = useToggle('0', '1');
  const [company, setCompany] = React.useState<Company>(new Company());
  const [industries, { add: _addIndustry, remove: _removeIndustry, reset: _resetIndustry }] = useSet<Industry>([]);

  const companyResource = useCreation(() => new CompanyResource(), []);
  const { run: _getCompanyData, loading: _loadingGetCompanyData } = useRequest(companyResource.get, { manual: true });

  React.useEffect(() => {
    const _updateCompany = async () => {
      if (!isNull(activeWorkspace) && !isUndefined(activeWorkspace.pk)) {
        const response = await _getCompanyData(activeWorkspace.pk);
        if (response.status) {
          const c = new Company(response.data);
          const i = await c.getRelated('industries');
          i.forEach((industry) => _addIndustry(industry));
          setCompany(c);
        }
      }
    };

    _updateCompany();
  }, [activeWorkspace]);

  return (
    <WLyPageContainer
      loading={_loadingGetCompanyData}
      subTitle="Customize your identity in the company and on the marketplace."
      tabActiveKey={activeTab}
      onTabChange={setActiveTab}
      tabList={[
        {
          tab: 'Company Profile',
          key: '0',
        },
        {
          tab: 'Marketplace Profile',
          key: '1',
        },
      ]}
    >
      <Spin spinning={_loadingGetCompanyData || isUndefined(company.pk)}>
        <ProCard
          tabs={{
            renderTabBar: () => <></>,
            tabPosition: 'top',
            activeKey: activeTab,
            onChange: (key) => {
              setActiveTab(key);
            },
          }}
        >
          <ProCard.TabPane key="0" tab="Company Profile">
            <Space direction="vertical" size="large">
              <TextDescription
                title={
                  <Text strong type="secondary">
                    Company Name
                  </Text>
                }
              >
                <Title
                  level={1}
                  editable={{
                    onChange: (value) =>
                      setCompany((c) => {
                        c.name = value;
                        return new Company(c.toJson());
                      }),
                  }}
                >
                  {company.name}
                </Title>
              </TextDescription>
              <Space direction="horizontal" split wrap size="large" align="start">
                <TextDescription
                  title={
                    <Text strong type="secondary">
                      Company Type
                    </Text>
                  }
                >
                  <Tag>{company.isStaffing ? 'Staffing' : 'Manufacturing'}</Tag>
                </TextDescription>
                <TextDescription
                  title={
                    <Text strong type="secondary">
                      Industries
                    </Text>
                  }
                >
                  {industries.size === 0 ? 'No industries' : Array.from(industries).map((i) => <Tag>{i.name}</Tag>)}
                </TextDescription>
              </Space>
              <Title level={3} style={{ marginTop: '64px' }}>
                Contact Information
              </Title>
              <TextDescription
                icon={<EnvironmentFilled />}
                title={
                  <Text strong type="secondary">
                    Address
                  </Text>
                }
              >
                <Text>{company.addressLine1}</Text>
              </TextDescription>
            </Space>
          </ProCard.TabPane>
          <ProCard.TabPane key="1" tab="Marketplace Profile">
            Marketplace Profile Form Here
          </ProCard.TabPane>
        </ProCard>
      </Spin>
    </WLyPageContainer>
  );
};
