import React from 'react';
import { Button as DefaultButton } from 'antd';
import classNames from 'classnames';

/**
 * The properties required for the Button component.
 * @author Axel Nana <axel.nana@workerly.io>
 */
export interface ButtonProps {
  /**
   * Defines the type of the button.
   */
  type?: 'primary' | 'secondary' | 'info' | 'warning' | 'danger' | 'success' | 'disabled' | 'text';

  /**
   * Defines if the button is disabled or not.
   */
  disabled?: boolean;

  /**
   * Defines if the button is displayed with dashed borders
   */
  dashed?: boolean;

  /**
   * Defines if the button is displayed as a block
   */
  block?: boolean;

  /**
   * Defines the icon to show in the button at the left.
   */
  leftIcon?: React.ReactNode;

  /**
   * Defines the icon to show in the button at the right.
   */
  rightIcon?: React.ReactNode;

  /**
   * Additional style to apply on the button.
   * Uses this property as less as possible, since it can break the UI.
   */
  style?: React.CSSProperties;

  /**
   * Additional classes to apply on the button.
   * Uses this property as less as possible, since it can break the UI.
   */
  className?: string;

  /**
   * For link buttons.
   */
  href?: string;

  /**
   * For forms.
   */
  htmlType?: 'button' | 'submit' | 'reset';

  /**
   * For anchor buttons.
   */
  target?: string;

  /**
   * Callback triggered when the component receive a `click`
   * event.
   */
  onClick?: React.MouseEventHandler<HTMLElement>;

  /**
   * Defines if the button is currently in a loading state.
   */
  loading?:
    | boolean
    | {
        delay?: number;
      };

  /**
   * Defines the button size.
   */
  size?: 'small' | 'middle' | 'large';

  /**
   * Defines the button HTML title.
   */
  title?: string;
}

/**
 * Renders a single button.
 * @author Axel Nana <axel.nana@workerly.io>
 * @param {React.PropsWithChildren<ButtonProps>} props Button properties.
 */
export function Button({
  href,
  disabled,
  htmlType,
  target,
  onClick,
  leftIcon,
  loading,
  type,
  children,
  rightIcon,
  style,
  className,
  dashed,
  block,
  size,
  title,
}: React.PropsWithChildren<ButtonProps>) {
  return (
    <DefaultButton
      type={dashed ? 'dashed' : undefined}
      block={block}
      disabled={disabled}
      href={href}
      htmlType={htmlType}
      target={target}
      onClick={onClick}
      loading={loading}
      style={style}
      size={size}
      title={title}
      className={classNames(`wl-component-button ${className ? className : ''}`, {
        [`wl-component-button-type-${type}`]: !dashed,
        'wl-component-button-disabled': disabled,
      })}
    >
      {!loading && leftIcon && <span className="wl-component-button-icon-left">{leftIcon}</span>}
      <span className="wl-component-button-content">{children}</span>
      {rightIcon && <span className="wl-component-button-icon-right">{rightIcon}</span>}
    </DefaultButton>
  );
}

Button.defaultProps = {
  type: 'primary',
};
