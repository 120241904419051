import { DataModel } from '@/data/DataModel';
import { AttendanceResponseData } from '@/api/attendance';

/**
 * Attendance model
 * @author Adonis SIMO <simo.adonis@workerly.io>
 */
export class Attendance extends DataModel<AttendanceResponseData | AttendanceResponseData> {
  public get pk(): string | undefined {
    throw new Error('Method not implemented.');
  }
  public getRelated(
    key: string | number,
  ): Promise<DataModel<{ [key: string]: any }> | DataModel<{ [key: string]: any }>[] | null> {
    throw new Error('Method not implemented.');
  }
}
