import { RequestOptionsInit } from 'umi-request';

import { CompanyDomain, FullTimezone, PricingPlan } from '@/api/types';
import { NeverUndefinedResponse, Resource } from '@/api/resource';

export enum CompanyType {
  Staffing = 0,
  NonStaffing = 1,
}

/**
 * The Company data interpreted by the API.
 * @author Axel Nana <axel.nana@workerly.io>
 */
export interface CompanyRequestData {
  pk?: string;
  domains?: CompanyDomain[];
  pricing_plan?: PricingPlan;
  head_location?: string;
  industries?: string[];
  full_timezone?: FullTimezone;
  name?: string;
  actived?: boolean;
  company_type?: CompanyType;
  public_description?: string;
  public_phone?: string;
  public_email?: string;
  public_logo?: string;
  public_cover_image?: string;
  country?: string;
  phone2?: string;
  email2?: string;
  address_line_1?: string;
  address_line_2?: string;
  state_or_region?: string;
  city?: string;
  po_box?: string;
  registration_id?: string;
  owner?: string;
  token?: string;
  timezone?: any;
}

/**
 * The Company raw data sent by the API.
 * @author Axel Nana <axel.nana@workerly.io>
 */
export interface CompanyResponseData extends CompanyRequestData {
  pk: string;
  domains: CompanyDomain[];
  pricing_plan: PricingPlan;
  head_location: string;
  industries: string[];
  full_timezone: any;
  created: string;
  modified: string;
  name: string;
  actived: boolean;
  company_type: CompanyType;
  public_description: string;
  public_phone: string;
  public_email: string;
  public_logo: string;
  public_cover_image: string;
  country: string;
  phone2: string;
  email2: string;
  address_line_1: string;
  address_line_2: string;
  state_or_region: string;
  city: string;
  po_box: string;
  registration_id: string;
}

/**
 * The company resource manager.
 * @author Axel Nana <axel.nana@workerly.io>
 */
export class CompanyResource extends Resource<CompanyRequestData, CompanyResponseData> {
  constructor() {
    super('/company');
  }

  /**
   * Returns an unique company, given its primary key.
   * @author Axel Nana <axel.nana@workerly.io>
   * @param {string} pk The company primary key.
   * @param {RequestOptionsInit | undefined} options The extra request options.
   */
  async get(pk: string, options?: RequestOptionsInit): Promise<NeverUndefinedResponse<CompanyResponseData>> {
    return await this.request(`/update-company/${pk}`, { ...options, method: 'get' });
  }
}
