import React from 'react';
import { Modal, Spin, Avatar, Layout, Popconfirm, Popover } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useCookies } from 'react-cookie';
import { useLocation, useModel, useHistory } from 'umi';
import { isEmpty } from 'lodash';
import { useBoolean } from '@umijs/hooks';
import classNames from 'classnames';

import { Company } from '@/data';
import { AppRoutes } from '@/utils/routes';
import { useTranslation } from '@/utils/hooks';

const { Sider } = Layout;

/**
 * Renders the sider used as the workspace switcher.
 * @author Axel Nana <axel.nana@workerly.io>
 */
export function WLyWorkspaceSwitcherSider() {
  const _ = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const [cookies, , removeCookie] = useCookies(['wl-workspace-tenant']);
  const { workspaces, loadingQueryWorkspaces, activeWorkspace, setActiveWorkspace, isCurrentWorkspace } = useModel(
    'workspaces',
  );

  const { state: _workspaceManagerPopoverVisible, toggle: _setWorkspaceManagerPopoverVisible } = useBoolean();

  const _onWorkspaceManagerPopconfirmVisibleChange = React.useCallback(
    (visible: boolean) => {
      if (visible) {
        _setWorkspaceManagerPopoverVisible(false);
      }
    },
    [_setWorkspaceManagerPopoverVisible],
  );

  const _onGoToWorkspaceManager = React.useCallback(() => {
    removeCookie('wl-workspace-tenant', { path: '/' });
    history.push(AppRoutes.accountWorkspaces.path);
  }, [removeCookie, activeWorkspace, history]);

  const _onSelectWorkspace = React.useCallback(
    (c: Company) => {
      if (!isCurrentWorkspace(c)) {
        Modal.confirm({
          title: _('workspaces.message.confirm_switch_workspace.title'),
          centered: true,
          content: _(
            location.pathname === AppRoutes.accountWorkspaces.path
              ? 'workspaces.message.confirm_switch_workspace.content_from_account'
              : 'workspaces.message.confirm_switch_workspace.content_from_workspace',
            { name: React.createElement('b', null, c.name) },
          ),
          onOk: async () => await setActiveWorkspace(c),
        });
      }
    },
    [_, setActiveWorkspace, isCurrentWorkspace],
  );

  const _workspacesSidebarMapper = React.useCallback(
    (c: Company) => (
      <Popover key={`wl-app-workspace-switcher-item-${c.pk}`} content={<b>{c.name}</b>} placement="right">
        <div
          className={classNames('wl-app-workspace-switcher-item', {
            selected: isCurrentWorkspace(c),
          })}
          onClick={() => _onSelectWorkspace(c)}
        >
          <div className="wl-app-workspace-switcher-item-logo">
            <Avatar shape="square" size={24} src={require('@/assets/images/brand/icon.png')} />
          </div>
        </div>
      </Popover>
    ),
    [_onSelectWorkspace, isCurrentWorkspace],
  );

  React.useEffect(() => {
    if (isEmpty(cookies['wl-workspace-tenant'])) {
      setActiveWorkspace(null);
    }
  }, [cookies]);

  return (
    <Sider className="wl-app-workspace-switcher" defaultCollapsed collapsed>
      <Spin
        spinning={loadingQueryWorkspaces}
        indicator={<LoadingOutlined />}
        className="wl-app-workspace-switcher-spinner"
      >
        <div className="wl-app-workspace-switcher-children">
          <div className="wl-app-workspace-switcher-items">{workspaces?.map(_workspacesSidebarMapper)}</div>
          <div style={{ flexGrow: 1 }} />
          {location.pathname !== AppRoutes.accountWorkspaces.path &&
            location.pathname !== AppRoutes.accountSettings.path && (
              <Popover
                trigger="hover"
                onVisibleChange={_setWorkspaceManagerPopoverVisible}
                visible={_workspaceManagerPopoverVisible}
                content={<b>{_('workspaces.menu.manager')}</b>}
                placement="right"
              >
                <Popconfirm
                  title={_('workspaces.message.confirm_close_workspace')}
                  onConfirm={_onGoToWorkspaceManager}
                  onVisibleChange={_onWorkspaceManagerPopconfirmVisibleChange}
                  okText={_('action.yes')}
                  cancelText={_('action.no')}
                  placement="topLeft"
                  overlayInnerStyle={{ marginLeft: 8, maxWidth: 256 }}
                >
                  <div className="wl-app-workspace-switcher-item">
                    <div className="wl-app-workspace-switcher-item-logo">
                      <img src={require('@/assets/images/brand/icon.png')} />
                    </div>
                  </div>
                </Popconfirm>
              </Popover>
            )}
        </div>
      </Spin>
    </Sider>
  );
}
