import 'velocity-animate/velocity.ui.js';

import velocity from 'velocity-animate';

velocity('registerSequence', 'fadeWrapUp', {
  duration: 1000,
  '0%': {
    transform: 'translate3d(0,0,0)',
    visibility: 'visible',
    opacity: '1',
  },
  '100%': {
    transform: 'translate3d(-100%,0,0)',
    visibility: 'hidden',
    opacity: '0',
  },
});
