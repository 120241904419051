import { isUndefined } from 'lodash';

import { CompanyRequestData, CompanyType } from '@/api/company';
import { WorkspaceCompaniesResponseData } from '@/api/workspace';
import { DataModel } from '@/data/DataModel';
import { Industry } from '@/data/Industry';
import { getCurrentUser } from '@/utils/auth';
import { IndustryResource } from '@/api/industry';

type CompanyData = CompanyRequestData & { tenant?: string; is_owner?: boolean };

/**
 * The Company data model interpreted by the app.
 * @author Axel Nana <axel.nana@workerly.io>
 */
export class Company extends DataModel<CompanyData> {
  public get pk(): string | undefined {
    return this._rawData.pk;
  }

  public set pk(value: string | undefined) {
    this._rawData.pk = value;
  }

  public get name(): string | undefined {
    return this._rawData.name;
  }

  public set name(value: string | undefined) {
    this._rawData.name = value;
  }

  public get description(): string | undefined {
    return this._rawData.public_description;
  }

  public set description(value: string | undefined) {
    this._rawData.public_description = value;
  }

  public get addressLine1(): string | undefined {
    return this._rawData.address_line_1;
  }

  public set addressLine1(value: string | undefined) {
    this._rawData.address_line_1 = value;
  }

  public get phone(): string | undefined {
    return this._rawData.public_phone;
  }

  public set phone(value: string | undefined) {
    this._rawData.public_phone = value;
  }

  public get coverImage(): string | undefined {
    return this._rawData.public_cover_image;
  }

  public get logo(): string | undefined {
    return this._rawData.public_logo;
  }

  public get tenant(): string | undefined {
    return this._rawData.tenant || (this._rawData.domains ? this._rawData.domains[0].domain : '');
  }

  public get isOwner(): boolean {
    if (!isUndefined(this._rawData.is_owner)) return this._rawData.is_owner;

    const [user] = getCurrentUser();
    if (!isUndefined(user)) return this._rawData.owner === user.pk;

    return false;
  }

  public get isStaffing(): boolean {
    return this._rawData.company_type === CompanyType.Staffing;
  }

  public get isManufacturing(): boolean {
    return this._rawData.company_type === CompanyType.NonStaffing;
  }

  public async getRelated(key: 'industries'): Promise<Industry[]>;
  public async getRelated(key: keyof CompanyRequestData): Promise<DataModel | DataModel[] | null> {
    switch (key) {
      case 'industries':
        if (!isUndefined(this._rawData.industries)) {
          const industryResource = new IndustryResource();
          const industries = [];
          for (let i = 0; i < this._rawData.industries.length; i++) {
            const industry = this._rawData.industries[i];
            const { data } = await industryResource.get(industry);
            if (data.status) {
              industries.push(new Industry(data.data));
            }
          }
          return industries;
        }
        return [];

      default:
        return null;
    }
  }

  public static fromWorkspace(workspace: WorkspaceCompaniesResponseData): Company {
    const data: CompanyData = {
      pk: workspace.company__company,
      name: workspace.company__company__name,
      address_line_1: workspace.company__company__address_line_1,
      public_cover_image: workspace.company__company__public_cover_image,
      public_description: workspace.company__company__public_description,
      public_logo: workspace.company__company__public_logo,
      public_phone: workspace.company__company__public_phone,
      tenant: workspace.company__company__domains__domain,
      is_owner: workspace.is_owner,
    };

    return new Company(data);
  }
}
