import React from 'react';
import { Space, Row, Col, Typography, List, Divider } from 'antd';
import { ProColumns } from '@ant-design/pro-table';
import { PlusOutlined } from '@ant-design/icons';
import { useBoolean, usePersistFn } from '@umijs/hooks';

import { DepartmentResource } from '@/api/department';
import { Button, Layout, TextDescription, DataTable } from '@/components';
import { Department } from '@/data';
import { CreateDepartmentModal } from '@/pages/company/settings/components/create-department-modal';
import { useTranslation } from '@/utils/hooks';

const { Title } = Typography;
const { WLyPageContainer } = Layout;

const dataTableColumns: ProColumns<Department>[] = [
  {
    title: 'Name',
    dataIndex: 'name',
    copyable: true,
    ellipsis: true,
    tip: 'The name of the department.',
  },
  {
    title: 'Code',
    dataIndex: 'code',
    copyable: true,
    ellipsis: false,
    tip: 'The code of the department.',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    copyable: true,
    ellipsis: true,
    search: false,
  },
  {
    title: 'Head',
    dataIndex: 'head',
    renderText: (head) => (head !== null ? `${head.user.first_name} ${head.user.last_name}` : ''),
    copyable: true,
    ellipsis: true,
  },
  {
    title: 'Parent Department',
    dataIndex: 'parent',
    renderText: (p) => (p !== null ? `${p.name}` : ''),
    copyable: true,
    ellipsis: true,
  },
  {
    title: 'Positions',
    dataIndex: 'positions',
    tip: 'The number of positions available in this department.',
    renderText: (positions) => positions.length,
    width: '10%',
    search: false,
  },
];

/**
 *
 * @author Adonis SIMO <simo.adonis@workerly.io>
 */
export default () => {
  const _ = useTranslation();

  const { state: createModalVisible, toggle: toggleCreateModalVisible } = useBoolean(false);
  const showCreateCompanyModal = React.useCallback(() => toggleCreateModalVisible(true), [toggleCreateModalVisible]);
  const hideCreateCompanyModal = React.useCallback(() => toggleCreateModalVisible(false), [toggleCreateModalVisible]);

  const drawerContentRenderer = usePersistFn((entity: Department) => (
    <Space direction="vertical">
      <div>
        <Title level={5}>Details</Title>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <TextDescription title="Name">{entity?.name}</TextDescription>
          </Col>
          <Col span={12}>
            <TextDescription title="Code">{entity?.code}</TextDescription>
          </Col>
          <Col span={24}>
            <TextDescription title="Description">{entity?.description}</TextDescription>
          </Col>
          <Col span={12}>
            <TextDescription title="Parent Department">
              {entity?.parent?.name || 'No parent department.'}
            </TextDescription>
          </Col>
          <Col span={12}>
            <TextDescription title="Department Head">{entity?.head?.user.first_name}</TextDescription>
          </Col>
        </Row>
      </div>
      <Divider />
      <div>
        <Title level={5}>Positions</Title>
        <List
          bordered={false}
          split
          dataSource={entity?.positions}
          renderItem={(item) => <List.Item>{item.name}</List.Item>}
        />
      </div>
    </Space>
  ));

  return (
    <WLyPageContainer
      extra={[
        <Button leftIcon={<PlusOutlined />} onClick={showCreateCompanyModal}>
          {_('action.create')}
        </Button>,
      ]}
    >
      <CreateDepartmentModal visible={createModalVisible} onCancel={hideCreateCompanyModal} />
      <DataTable<Department, DepartmentResource>
        columns={dataTableColumns}
        model={Department}
        resource={DepartmentResource}
        renderDrawerContent={drawerContentRenderer}
        drawerProps={{
          title: 'Consult Department',
          width: 480,
        }}
      />
    </WLyPageContainer>
  );
};
