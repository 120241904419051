import * as H from 'history';
import { Link as DefaultLink } from 'umi';
import { LinkProps as DefaultLinkProps } from 'react-router-dom';

type LinkPropsType<S> = React.PropsWithoutRef<LinkProps<S>> & React.RefAttributes<HTMLAnchorElement>;

/**
 * Additional props for an application Link component.
 * @author Axel Nana <axel.nana@workerly.io>
 */
export interface LinkProps<S = H.LocationState> extends DefaultLinkProps<S> {
  /**
   * An icon that should appear at the left side of the link.
   * @type {React.ReactNode}
   */
  leftIcon?: React.ReactNode;

  /**
   * An icon that should appear at the right side of the link.
   * @type {React.ReactNode}
   */
  rightIcon?: React.ReactNode;
}

/**
 * Renders a single Link component.
 * @author Axel Nana <axel.nana@workerly.io>
 */
export function Link<S = H.LocationState>({ leftIcon, rightIcon, children, className, ...props }: LinkPropsType<S>) {
  return (
    <DefaultLink {...props} className={`wl-component-link ${className}`}>
      {leftIcon && <span className="wl-component-link-icon-left">{leftIcon}</span>}
      <span className="wl-component-link-content">{children}</span>
      {rightIcon && <span className="wl-component-link-icon-right">{rightIcon}</span>}
    </DefaultLink>
  );
}
