import React from 'react';

/**
 * Available properties for a Badge component.
 * @author Axel Nana <axel.nana@workerly.io>
 */
export interface BadgeProps {
  /**
   * Defines the icon to display at the left side of the badge.
   * @type {React.ReactNode}
   */
  leftIcon?: React.ReactNode;

  /**
   * Defines the icon to display at the right side of the badge.
   * @type {React.ReactNode}
   */
  rightIcon?: React.ReactNode;

  /**
   * Defines the content to display in the badge.
   * @type {React.ReactNode}
   */
  content?: React.ReactNode;

  /**
   * The badge type, mostly used to defined the background color.
   * @type {'primary' | 'secondary' | 'info' | 'success' | 'danger' | 'warning' | 'custom'}
   */
  type: 'primary' | 'secondary' | 'info' | 'success' | 'danger' | 'warning' | 'custom';
}

export function Badge({ leftIcon, rightIcon, content, type }: BadgeProps) {
  return (
    <span className={`wl-component-badge wl-component-badge-${type}`}>
      {leftIcon && <span className="wl-component-badge-icon-left">{leftIcon}</span>}
      {content && <span className="wl-component-badge-content">{content}</span>}
      {rightIcon && <span className="wl-component-badge-icon-right">{rightIcon}</span>}
    </span>
  );
}

Badge.defaultProps = {
  type: 'primary',
};
