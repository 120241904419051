import React from 'react';
import { useIntl, useRequest } from 'umi';

import { ReadOnlyResource, ResourceConstructorOf } from '@/api/resource';
import { DataModel, DataModelConstructor } from '@/data';
import { useCreation } from '@umijs/hooks';

/**
 * Hook normalizing the usage of a request in the ProTable
 * component.
 * @author Axel Nana <axel.nana@workerly.io>
 * @param {ReadOnlyResource} resource The resource to use for the request.
 * @param {string | undefined} cacheKey The cache key to use for data caching.
 */
export function useProTableRequest<D extends any, M extends DataModel<D>, R extends ReadOnlyResource<D>>({
  model,
  resource,
  cacheKey,
}: {
  model: DataModelConstructor<D>;
  resource: ResourceConstructorOf<R>;
  cacheKey?: string;
}) {
  const apiResource = useCreation(() => new resource(), [resource]);
  const { run: _runRequest } = useRequest(apiResource.list, { manual: true, cacheKey });
  const callback = React.useCallback(
    async (params = {}) => {
      const { data } = await _runRequest(undefined, undefined, { params });
      const _raw = data || { data: <D[]>[], success: true };
      return { ..._raw, data: <M[]>_raw.data.map((i) => new model(i)) };
    },
    [_runRequest],
  );

  return callback;
}

/**
 * Hook used to facilitate the usage of react-intl.
 * @author Axel Nana <axel.nana@workerly.io>
 */
export function useTranslation() {
  const intl = useIntl();
  const callback = React.useCallback(
    (
      id: string,
      values?: Record<
        string,
        string | number | boolean | null | undefined | Date | React.ReactElement | ((...args: any[]) => string | object)
      >,
    ) => intl.formatMessage({ id }, values),
    [intl],
  );

  return callback;
}
