import React from 'react';
import State from 'deep-state-observer';
import GSTC, { Config, GSTCResult, Items, Row, Rows } from 'gantt-schedule-timeline-calendar';
import { Plugin as TimelinePointer } from 'gantt-schedule-timeline-calendar/dist/plugins/timeline-pointer.esm.min.js';
import { Plugin as Selection } from 'gantt-schedule-timeline-calendar/dist/plugins/selection.esm.min.js';
import { Plugin as ItemResizing } from 'gantt-schedule-timeline-calendar/dist/plugins/item-resizing.esm.min.js';
import { Plugin as ItemMovement } from 'gantt-schedule-timeline-calendar/dist/plugins/item-movement.esm.min.js';
import { useUnmount } from '@umijs/hooks';

import { Layout } from '@/components';

import 'gantt-schedule-timeline-calendar/dist/style.css';
import { Card } from 'antd';

const { WLyPageContainer } = Layout;

let gstc: GSTCResult, state: State;

// helper functions

function generateRows() {
  const rows: Rows = {};
  for (let i = 0; i < 100; i++) {
    const id = GSTC.api.GSTCID(i.toString());
    rows[id] = {
      id,
      label: `Row ${i}`,
    };
  }
  return rows;
}

function generateItems() {
  const items: Items = {};
  let start = GSTC.api.date().startOf('day').subtract(6, 'day');
  for (let i = 0; i < 100; i++) {
    const id = GSTC.api.GSTCID(i.toString());
    const rowId = GSTC.api.GSTCID(Math.floor(Math.random() * 100).toString());
    start = start.add(1, 'day');
    items[id] = {
      id,
      label: `Item ${i}`,
      rowId,
      time: {
        start: start.valueOf(),
        end: start.add(1, 'day').endOf('day').valueOf(),
      },
    };
  }
  return items;
}

function initializeGSTC(element: HTMLElement | null) {
  const config: Config = {
    licenseKey:
      '====BEGIN LICENSE KEY====\nszx4LAzqEMSoBApV/yktmyBfid7Tho3cy/c6bJZSX04RiXJ9UnNtZPsdex3bVCM/fTfeVyOgnyQ8cOFn1u4tnSBj9aPc2afy+fR49Yq6RKu1RCGOlE7eseZxE3LrUwvftCI3GEvlrjaH2B2InOPfo+heLEqnizXypKgjzSbU0gIa7/kApmocTa/lrrSeScWp2f+/sKuuT253KDTWBAvi29IHNvT4go441wXI2ZbpHkM3OfcbVEU/PYNBA2c3wD47A3b67gJA8Ftu1SwBjZdTKcXmM8Crqr8D0jqkbOERxAIKlXJMWE1P97MCiv0P0LA4wfu+4uHZYS4GhDlbGsaDgQ==||U2FsdGVkX1+CMHFINN1SQciXOwIV+5Pvz51VuKFQLEil/yfu0aBldy2pZZCaEXixnTqRu0k1HNWgcMq3PBnNkSVhV8fwfCXeLke+7WiNRzw=\naXSq4aGRfjhHxQNiouGGINJp9SvN/UOiQYuYllQbansqGMMT2G3q5055K3iMlX969FZzZ5TeLSMYVAoELwO3P8G7ZV0dzPokkyeMqx7OexB/ZXzLujjjh0PcmRtJ8JmYAdSWfl4DmqrtiaD+8lvPhteC2Lo/+o5uJO3FJdVRYurjKceJqTrkKOCNqR2gOhSQJ4afDmoiLub7vxL6U0fMOHKr07yliKZumD/MIrw+OkgYES06O83h19VjfXhWg1xfCTJyHcOBwJ7HSo0R0XVPP676+t2ESDVF7bzXgBMzLtklmC9KKO0Wg11mkOVn/a3DnOtWZrcNnJv0rcITcE7Vpg==\n====END LICENSE KEY====',
    plugins: [TimelinePointer(), Selection(), ItemResizing(), ItemMovement()],
    list: {
      columns: {
        data: {
          [GSTC.api.GSTCID('id')]: {
            id: GSTC.api.GSTCID('id'),
            width: 60,
            data: ({ row }) => GSTC.api.sourceID(row.id),
            header: {
              content: 'ID',
            },
          },
          [GSTC.api.GSTCID('label')]: {
            id: GSTC.api.GSTCID('label'),
            width: 200,
            data: 'label',
            header: {
              content: 'Label',
            },
          },
        },
      },
      rows: generateRows(),
    },
    chart: {
      items: generateItems(),
    },
  };

  state = GSTC.api.stateFromConfig(config);

  gstc = GSTC({
    element,
    state,
  });
}

/**
 * @author Adonis SIMO <simo.adonis@workerly.io>
 */
export default () => {
  const callback = React.useCallback((element: HTMLElement | null) => {
    if (element) initializeGSTC(element);
  }, []);

  useUnmount(() => {
    if (gstc) {
      gstc.destroy();
    }
  });

  function updateFirstRow() {
    state.update(`config.list.rows.${GSTC.api.GSTCID('0')}`, (row: Row) => {
      row.label = 'Changed dynamically';
      return row;
    });
  }

  function changeZoomLevel() {
    state.update('config.chart.time.zoom', 21);
  }

  return (
    <WLyPageContainer>
      <div className="App">
        <Card>
          <div className="gstc-wrapper" ref={callback} />
        </Card>
      </div>
    </WLyPageContainer>
  );
};
