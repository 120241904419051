export default {
  'settings.title': 'Settings',
  'settings.subtitle': 'Manage settings related to this company',

  'settings.profile.title': 'Profile',

  'settings.locations.title': 'Locations',

  'settings.departments.form.name': 'Name',
  'settings.departments.form.name.required': 'The Name Is Required',
  'settings.departments.form.description': 'Description',
  'settings.departments.form.head': 'Head Of The Department',
  'settings.departments.form.code': 'Code',
  'settings.departments.form.parent': 'Parent Department',
  'settings.departments.form.name.description': 'What is the Department Name',

  'settings.workweek.form.name': 'Name',
  'settings.workweek.form.nbrHours': 'Number Of Hours',
  'settings.workweek.form.computeDelay': 'Delay To Compute Attendance (In Days)',
  'settings.workweek.form.workWeekStart': 'Week Start Day',

  'settings.payroll.form.name': 'Name',
  'settings.payroll.form.payrollSchedule': 'Payroll Cycle',
  'settings.payroll.form.referenceDate': 'Reference Date',
  'settings.payroll.form.workWeekStart': 'Week Start Day',
};
