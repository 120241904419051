import React from 'react';
import ProForm, { ProFormText } from '@ant-design/pro-form';
import { Form, Input, Select } from 'antd';
import { Modal, ModalProps } from '@/components';
import { Typography, Col, Row, Card } from 'antd';
import { Button } from '@/components';

const { Text, Paragraph } = Typography;

export interface PreventDeletionProps extends ModalProps {
  requiredText: string;
  content: string;
  title: string;
  onSuccess?: () => {};
}

export function PreventDeletion(props: PreventDeletionProps) {
  const { requiredText, content, title, onSuccess } = props;
  return (
    <Modal {...props} ok={false} cancel={false}>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Paragraph>{content}</Paragraph>
        </Col>
        <Col span={24}>
          <Text>Please type {requiredText} to proceed !!</Text>
        </Col>
        <Col span={24}>
          <Form layout="vertical" requiredMark>
            <Form.Item label="" required>
              <Input />
            </Form.Item>
          </Form>
        </Col>
        <Col span={24}>
          <Button type="danger" size="large">
            Obtain A New One
          </Button>
        </Col>
      </Row>
    </Modal>
  );
}
