import React from 'react';
import { message, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { CookiesProvider } from 'react-cookie';
import { RequestConfig } from 'umi';
import { ResponseError, RequestOptionsInit } from 'umi-request';
import { Cookies } from 'react-cookie';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import _ from 'lodash';

import { logout, getCurrentTenant, getCurrentUser, refreshToken } from '@/utils/auth';

// Register velocity custom animations
import '@/utils/velocity';

// Initialize the Sentry.io analyzer
Sentry.init({
  dsn: 'https://52e09f3ead874c57815345283fd2665f@o511652.ingest.sentry.io/5609322',
  integrations: [new Integrations.BrowserTracing()],
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  environment: process.env.NODE_ENV,
});

const cookies = new Cookies();

/**
 * Sets the default indicator of each spinner globally.
 */
Spin.setDefaultIndicator(<LoadingOutlined />);

/**
 * Requests error handler.
 * @author Axel Nana <axel.nana@workerly.io>
 * @param {ResponseError} error The response error.
 */
const requestErrorHandler = (error: ResponseError) => {
  if (!_.includes(['token_not_valid', 'T002'], error.data.code)) {
    const { response = {} as Response }: { response: Response } = error;
    const errorText = (() => {
      if (!_.isEmpty(error.data) && _.isString(error.data.details)) return error.data.details;
      if (!_.isEmpty(error.data) && _.isString(error.data.detail)) return error.data.detail;
      if (_.isString(error.data)) return error.data;
      if (_.isString(response.statusText)) return response.statusText;
      return 'An error occurred. Please try again later. If the error persist, please contact support for help.';
    })();

    if (!_.isEmpty(errorText)) {
      message.error({
        content: <span>{errorText}</span>,
      });
    }
  }

  throw error;
};

/**
 * Intercepts all requests to add required authorization token.
 * @author Axel Nana <axel.nana@workerly.io>
 * @param {string} url The request url.
 * @param {RequestOptionsInit} options The request options.
 */
const requestAuthHeaderInterceptor = (url: string, options: RequestOptionsInit) => {
  const [user, token] = getCurrentUser();

  if (_.isEmpty(user)) return { url, options };

  const headers = { ...options.headers, Authorization: `Bearer ${token?.access}` };

  return {
    url,
    options: { ...options, interceptors: true, headers },
  };
};

/**
 * Intercepts all requests to add required tenant token.
 * @author Axel Nana <axel.nana@workerly.io>
 * @param {string} url The request url.
 * @param {RequestOptionsInit} options The request options.
 */
const requestTenantHeaderInterceptor = (url: string, options: RequestOptionsInit) => {
  const tenant = getCurrentTenant();

  if (_.isEmpty(tenant)) return { url, options };

  const headers = { ...options.headers, Tenant: tenant };

  return {
    url,
    options: { ...options, interceptors: true, headers },
  };
};

/**
 * Intercepts all requests to add required Sentry.io token.
 * @author Axel Nana <axel.nana@workerly.io>
 * @param {string} url The request url.
 * @param {RequestOptionsInit} options The request options.
 */
const requestSentryHeaderInterceptor = (url: string, options: RequestOptionsInit) => {
  if (!url.toLowerCase().includes('sentry')) return { url, options };

  const headers = { ...options.headers, 'X-Sentry-Token': '2563974a60f711ebaeb84201c0a8d02b' };

  return {
    url,
    options: { ...options, interceptors: true, headers },
  };
};

/**
 * Intercepts all responses to parse the returned data.
 * @author Axel Nana <axel.nana@workerly.io>
 * @param {Response} response The response object.
 * @param {RequestOptionsInit} options The request options.
 */
const responseInterceptors = async (response: Response) => {
  if (!response.ok) {
    const data = await response.clone().json();
    if (response.status === 404 && data.code === 'T002') {
      logout();
    }

    if (response.status === 401 && data.code === 'T002') {
      logout();
    }

    if (response.status === 401 && data.code === 'token_not_valid') {
      refreshToken();
    }
  }

  return response;
};

/**
 * Configures the request executioner object.
 * @author Axel Nana <axel.nana@workerly.io>
 */
export const request: RequestConfig = {
  prefix: 'https://api.staging.workerly.io/api/v2',
  errorHandler: requestErrorHandler,
  requestInterceptors: [requestAuthHeaderInterceptor, requestTenantHeaderInterceptor, requestSentryHeaderInterceptor],
  responseInterceptors: [responseInterceptors],
};

/**
 * Generates the initial state of the application.
 * Useful to get the current user and his permissions.
 * @author Axel Nana <axel.nana@workerly.io>
 */
export async function getInitialState(): Promise<any> {
  return {
    user: (() => {
      const user = cookies.get('wl-user-data');
      if (!_.isEmpty(user)) return user;
      else return undefined;
    })(),
    token: (() => {
      const token = cookies.get('wl-user-token');
      if (!_.isEmpty(token)) return token;
      else return undefined;
    })(),
    permissions: (() => {
      const permissions = cookies.get('wl-user-permissions');
      if (!_.isEmpty(permissions)) return permissions;
      else return undefined;
    })(),
    settings: {
      logout,
    },
  };
}

/**
 * Changes the root container of the application.
 * @author Axel Nana <axel.nana@workerly.io>
 * @param {React.ReactNode} container The application old root container.
 */
export function rootContainer(container: React.ReactNode) {
  return <CookiesProvider>{container}</CookiesProvider>;
}
