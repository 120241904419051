export default {
  'auth.login.messages.welcome': 'Welcome back, {name}',
  'auth.login.errors.no_email': 'Please input your email address.',
  'auth.login.errors.no_password': 'Please input your account password.',
  'auth.login.page.form.intro': 'Enter your email address and password to access admin panel.',
  'auth.login.page.form.label.email': 'Email',
  'auth.login.page.form.label.password': 'Password',
  'auth.login.page.form.label.not_verified': 'Not verified?',
  'auth.login.page.form.label.forgot_password': 'Forgot password?',
  'auth.login.page.form.label.remember_me': 'Remember me',
  'auth.login.page.form.action.login': 'Log in',
  'auth.login.page.form.action.signup': "Don't have an account? <a>Create one now!</a>",

  'auth.signup': 'Signup',
  'auth.signup.messages.welcome': 'Welcome, {name}',
  'auth.signup.errors.no_first_name': 'Please fill your first name.',
  'auth.signup.errors.no_last_name': 'Please fill your last name.',
  'auth.signup.errors.no_email': 'Please fill your email address.',
  'auth.signup.page.form.title': '',
  'auth.signup.page.form.intro': "Create a Workerly account now, it's free!",
  'auth.signup.page.form.label.first_name': 'First Name',
  'auth.signup.page.form.label.last_name': 'Last Name',
  'auth.signup.page.form.label.email': 'Email',
  'auth.signup.page.form.label.accept_tos': 'I accept the <a>Terms and Conditions</a>',
  'auth.signup.page.form.action.signup': 'Create my account',
  'auth.signup.page.form.action.login': 'Already have an account? <a>Login here!</a>',

  'auth.logout.messages.goodby': 'You have successfully logged out.',

  'auth.reset_password.errors.no_password': 'Please enter the new password of your account.',
  'auth.reset_password.messages.operation_success':
    'You have successfully changed your password. You can now login with the new one.',
  'auth.reset_password.page.form.intro': 'Set the new password of your account.',
  'auth.reset_password.page.form.label.password': 'Password',
  'auth.reset_password.page.form.action.login': 'No need to be there? <a>Login instead!</a>',
  'auth.reset_password.page.form.action.workspace': 'No need to be there? <a>Go back to your workspace!</a>',

  'title.signup': 'Workerly Account Registration',
};
