import React from 'react';
import { Drawer, Empty, Popconfirm, Space } from 'antd';
import { DrawerProps } from 'antd/lib/drawer';
import { EyeOutlined, DeleteOutlined, EditOutlined, UserAddOutlined } from '@ant-design/icons';
import ProTable, { ProTableProps, ActionType } from '@ant-design/pro-table';
import { isNull, isUndefined, merge } from 'lodash';
import classNames from 'classnames';

import { Button } from '@/components';
import { ConstrainedDataModel, DataModelConstructor, DataModelConstructorOf } from '@/data';
import { useBoolean, useCreation } from '@umijs/hooks';
import { useProTableRequest, useTranslation } from '@/utils/hooks';
import { ReadOnlyResource, Resource, ResourceConstructor, ResourceConstructorOf } from '@/api/resource';

/**
 * Required props for the DataTable component.
 * @author Axel Nana <axel.nana@workerly.io>
 */
export interface DataTableProps<
  T extends ConstrainedDataModel,
  R extends ReadOnlyResource,
  U extends Record<string, any> = Record<string, any>,
  V = 'text'
> extends Omit<ProTableProps<T, U, V>, 'request'> {
  /**
   * Display extra actions on the toolbar.
   * @type {React.ReactNode[]}
   */
  extra?: React.ReactNode[];

  /**
   * The properties to pass to the internal drawer component.
   * @type {DrawerProps}
   */
  drawerProps?: Omit<DrawerProps, 'visible' | 'onClose'>;

  /**
   * The data model to use.
   * @type {DataModelConstructor}
   */
  model: DataModelConstructorOf<T>;

  /**
   * The resource to use in the data table.
   * @type {ResourceConstructorOf<R>}
   */
  resource: ResourceConstructorOf<R>;

  /**
   * Renders the component to render in the drawer for
   * the selected entity.
   * @param {T} entity The selected entity.
   * @returns {React.ReactNode}
   */
  renderDrawerContent?(entity: T): React.ReactNode;
}

/**
 * Renders a table displaying in an uniform way a list of data of the same type T.
 * @author Axel Nana <axel.nana@workerly.io>
 *
 * @template T The data model type. Must be a base class of ConstrainedDataModel.
 * @template R The resource type. Must be a base class of ReadOnlyResource.
 */
export function DataTable<
  T extends ConstrainedDataModel,
  R extends ReadOnlyResource,
  U extends Record<string, any> = Record<string, any>,
  V = 'text'
>({ extra, drawerProps, model, resource, renderDrawerContent, ...props }: DataTableProps<T, R, U, V>) {
  const _ = useTranslation();

  const [selectedEntity, setSelectedEntity] = React.useState<T | null>(null);
  const { state: drawerVisible, toggle: toggleDrawerVisible } = useBoolean(false);

  /**
   * Effectively closes the drawer.
   */
  const onCloseDrawer = React.useCallback(() => toggleDrawerVisible(false), [toggleDrawerVisible]);

  /**
   * Consults an entity. This will open the drawer.
   */
  const onConsultEntity = React.useCallback(
    (entity: T) => {
      setSelectedEntity(entity);
      toggleDrawerVisible(true);
    },
    [toggleDrawerVisible],
  );

  /**
   * Deletes the given entity.
   */
  const onDeleteEntity = React.useCallback(
    async (entity: T) => {
      if (!isUndefined(entity.pk)) {
        await ((resource as unknown) as Resource).delete(entity.pk);
        onCloseDrawer();
      }
    },
    [resource, onCloseDrawer],
  );

  /**
   * Deletes the selected entity.
   */
  const onDeleteSelectedEntity = React.useCallback(async () => {
    if (!isNull(selectedEntity)) await onDeleteEntity(selectedEntity);
  }, [selectedEntity, onDeleteEntity]);

  /**
   * Stores the content of the drawer.
   * Automatically updated when the selected entity changes.
   */
  const drawerContent = React.useMemo(() => {
    if (!isUndefined(renderDrawerContent) && !isNull(selectedEntity)) {
      return renderDrawerContent(selectedEntity);
    }

    return <Empty />;
  }, [renderDrawerContent, selectedEntity]);

  const request = useProTableRequest<any, T, R>({ model, resource });

  const actionRef = React.useRef<ActionType>();

  const pagination = React.useMemo(
    () =>
      merge(
        {
          pageSize: 10,
        },
        props.pagination ? props.pagination : {},
      ),
    [props.pagination],
  );

  const columns = React.useMemo(() => {
    const c = props.columns ? [...props.columns] : undefined;

    c?.push({
      title: 'Actions',
      key: 'option',
      valueType: 'option',
      render: (dom, entity) => [
        <Button
          title={_('action.consult') as string}
          onClick={() => onConsultEntity(entity)}
          size="small"
          type="secondary"
          key="view"
        >
          <EyeOutlined />
        </Button>,
        entity.updatable && (
          <Button title={_('action.edit') as string} size="small" type="primary" key="edit">
            <EditOutlined />
          </Button>
        ),
        entity.deletable && (
          <Button title={_('action.delete') as string} size="small" type="danger" key="delete">
            <DeleteOutlined />
          </Button>
        ),
      ],
    });

    return c;
  }, [props.columns]);

  return (
    <React.Fragment>
      <Drawer
        {...drawerProps}
        className={classNames('wl-component-data-table-drawer', drawerProps?.className)}
        visible={drawerVisible}
        onClose={onCloseDrawer}
        footer={
          <Space className="wl-component-data-table-drawer-footer-container" align="end" direction="horizontal">
            <Button size="small" leftIcon={<EditOutlined />} type="primary">
              {_('action.edit')}
            </Button>
            <Popconfirm title="Are you sure ?" onConfirm={onDeleteSelectedEntity} okButtonProps={{ danger: true }}>
              <Button size="small" leftIcon={<DeleteOutlined />} type="danger">
                {_('action.delete')}
              </Button>
            </Popconfirm>
          </Space>
        }
      >
        {drawerContent}
      </Drawer>

      <ProTable<T, U, V>
        rowKey="pk"
        search={{ layout: 'vertical' }}
        {...props}
        request={request}
        actionRef={actionRef}
        columns={columns}
        pagination={pagination}
        toolBarRender={extra ? () => extra : undefined}
      />
    </React.Fragment>
  );
}
