import { DataModel } from '@/data/DataModel';
import { WorkWeekResponseData, WeekDays } from '@/api/workweek';
import { ConstrainedDataModel } from '@/data/ConstrainedDataModel';

/**
 * @author Adonis SIMO <simo.adonis@worerly.io>
 */
export class WorkWeek extends ConstrainedDataModel<WorkWeekResponseData> {
  public getRelated(key: keyof WorkWeekResponseData): Promise<DataModel | DataModel[] | null> {
    throw new Error('Method not implemented.');
  }

  public set pk(value: string) {
    this._rawData.pk = value;
  }

  public get pk(): string {
    return this._rawData.pk;
  }

  public get name(): string {
    return this._rawData.name;
  }

  public set name(value: string) {
    this._rawData.name = value;
  }

  public get workWeekStart(): WeekDays {
    return this._rawData.work_week_start;
  }

  public set workWeekStart(value: WeekDays) {
    this._rawData.work_week_start = value;
  }

  public set attendanceComputeDelay(value: number) {
    this._rawData.attendance_compute_delay = value;
  }

  public get attendanceComputeDelay(): number {
    return this._rawData.attendance_compute_delay;
  }

  public workWeekStartDay(): string {
    // let workWeekStart = this.workWeekStartDay;
    switch (this.workWeekStart) {
      case 0:
        return 'Monday';
      case 1:
        return 'Tuesday';
      case 2:
        return 'Wednesday';
      case 3:
        return 'Thursday';
      case 4:
        return 'Friday';
      case 5:
        return 'Saturday';
      case 6:
        return 'Sunday';
      default:
        return '';
    }
  }

  public set weekWork(value: WeekDays) {
    this._rawData.week_work = value;
  }

  public get weekWork(): WeekDays {
    return this._rawData.week_work;
  }
}
