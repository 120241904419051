import { isEmpty } from 'lodash';
import { request } from 'umi';
import { RequestOptionsInit } from 'umi-request';

/**
 * Constructor of a CustomResource.
 * @author Axel Nana <axel.nana@workerly.io>
 */
export interface CustomResourceConstructor {
  new (): CustomResource;
}

/**
 * Constructor of a ReadOnlyResource.
 * @author Axel Nana <axel.nana@workerly.io>
 */
export interface ReadOnlyResourceConstructor {
  new (path: string): ReadOnlyResource;
}

/**
 * Constructor of a Resource.
 * @author Axel Nana <axel.nana@workerly.io>
 */
export interface ResourceConstructor {
  new (path: string): Resource;
}

/**
 * Constructor of an implemented Resource class.
 * @author Axel Nana <axel.nana@workerly.io>
 */
export interface ResourceConstructorOf<T extends CustomResource> {
  new (): T;
}

/**
 * Defines a response which will never have the `undefined` value.
 * @author Axel Nana <axel.nana@workerly.io>
 * @template T The API response object.
 */
export interface NeverUndefinedResponse<T extends any = any> {
  /**
   * The data object.
   * Will never be undefined.
   * @type {ResponseData<T>}
   */
  data: ResponseData<T>;
}

/**
 * Defines the response data structure.
 * @author Axel Nana <axel.nana@workerly.io>
 * @template T The API response object.
 */
export interface ResponseData<T extends any = any> {
  /**
   * The response status. `false` when an error occurred, `true` otherwise.
   * @type {boolean}
   */
  status: boolean;

  /**
   * The response data.
   * @type {T | undefined}
   */
  data?: T;
}

/**
 * Defines the response data structure of a list of resources.
 * @author Axel Nana <axel.nana@workerly.io>
 * @template T The resource response type.
 */
export interface ResponseDataList<T extends any = any> {
  /**
   * The resource items.
   * @type {Array<T>}
   */
  data: Array<T>;

  /**
   * The current page, for pagination.
   * @type {number}
   */
  page: number;

  /**
   * The request state.
   * @type {boolean}
   */
  success: boolean;

  /**
   * The total number of items in the data storage.
   * @type {number}
   */
  total: number;
}

/**
 * The most basic implementation of a resource. Allows to build a custom
 * resource, not based on CRUD operations.
 * @author Axel Nana <axel.nana@workerly.io>
 * @template Response The response data type of the resource. Defaults to any.
 */
export abstract class CustomResource {
  constructor() {
    this.request = this.request.bind(this);
    this._parseResponse = this._parseResponse.bind(this);
    this._parseResponseSuccess = this._parseResponseSuccess.bind(this);
    this._parseResponseFailure = this._parseResponseFailure.bind(this);
  }

  /**
   * Executes a custom request.
   * @author Axel Nana <axel.nana@workerly.io>
   * @param {string} path The resource path.
   * @param {RequestOptionsInit & { skipErrorHandler?: boolean }} options The request options.
   */
  async request<T>(
    path: string,
    options?: RequestOptionsInit & { skipErrorHandler?: boolean },
  ): Promise<NeverUndefinedResponse<T>> {
    return await request<T>(path, options).then(this._parseResponseSuccess).catch(this._parseResponseFailure);
  }

  /**
   * Parses the response data to always output an object value when successful.
   * @author Axel Nana <axel.nana@workerly.io>
   * @template D The response data object type to parse.
   * @param {D | undefined} data The response data.
   * @returns {{ data: ResponseData<D> }}
   */
  protected _parseResponseSuccess<D extends any = any>(data?: D): NeverUndefinedResponse<D> {
    return this._parseResponse(true, data);
  }

  /**
   * Parses the response data to always output an object value on failure.
   * @author Axel Nana <axel.nana@workerly.io>
   * @template D The response data object type to parse.
   * @param {D | undefined} data The response data.
   * @returns {{ data: ResponseData<D> }}
   */
  protected _parseResponseFailure<D extends any = any>(data?: D): NeverUndefinedResponse<D> {
    return this._parseResponse(false, data);
  }

  /**
   * Generic implementation of response parsing, used internally.
   * @author Axel Nana <axel.nana@workerly.io>
   * @template D The response data object type to parse.
   * @param {boolean} status The response status.
   * @param {D | undefined} data The response data.
   * @returns {{ data: ResponseData<D> }}
   */
  private _parseResponse<D extends any = any>(status: boolean, data?: D): NeverUndefinedResponse<D> {
    // data is the keyword used by Umi.js to recognize the effective response result.
    // By wrapping the given data in this object we force Umi to always return a value.
    return { data: !isEmpty(data) ? { status, data } : { status } };
  }
}

/**
 * The base implementation of a single readonly server resource.
 * @author Axel Nana <axel.nana@workerly.io>
 * @template Response The response data type of the resource. Defaults to any.
 */
export abstract class ReadOnlyResource<Response extends any = any> extends CustomResource {
  /**
   * The path to the resource.
   * @type {string}
   */
  protected _path: string = '';

  /**
   * Creates a new instance of a resource.
   * @param path The path to the server resource.
   * @author Axel Nana <axel.nana@workerly.io>
   */
  constructor(path: string) {
    super();

    this._path = path;

    this.all = this.all.bind(this);
    this.list = this.list.bind(this);
    this.get = this.get.bind(this);
  }

  /**
   * Returns the list of all items for this resource.
   * @author Axel Nana <axel.nana@workerly.io>
   * @param {RequestOptionsInit | undefined} options The extra request options.
   */
  async all(options?: RequestOptionsInit): Promise<NeverUndefinedResponse<ResponseDataList<Response>>> {
    return await this.request(this._path, {
      ...options,
      method: 'get',
      params: { 'no-paginate': true, ...options?.params },
    });
  }

  /**
   * Returns a slice of items for this resource.
   * @author Axel Nana <axel.nana@workerly.io>
   * @param {number | undefined} current The page from which starts the slice.
   * @param {number | undefined} pageSize The number of items in the slice.
   * @param {RequestOptionsInit | undefined} options The extra request options.
   */
  async list(
    current?: number,
    pageSize?: number,
    options?: RequestOptionsInit,
  ): Promise<NeverUndefinedResponse<ResponseDataList<Response>>> {
    return await this.request(this._path, {
      ...options,
      method: 'get',
      params: { current, pageSize, ...options?.params },
    });
  }

  /**
   * Returns an unique item for this resource, given its primary key.
   * @author Axel Nana <axel.nana@workerly.io>
   * @param {string} pk The item primary key.
   * @param {RequestOptionsInit | undefined} options The extra request options.
   */
  async get(pk: string, options?: RequestOptionsInit): Promise<NeverUndefinedResponse<Response>> {
    return await this.request(`${this._path}/${pk}`, { ...options, method: 'get' });
  }
}

/**
 * The base implementation of a single read-write server resource. Have support of CRUD operations.
 * @author Axel Nana <axel.nana@workerly.io>
 * @template Request The request data type of the resource. Defaults to any.
 * @template Response The response data type of the resource. Defaults to any.
 */
export abstract class Resource<
  Request extends any = any,
  Response extends any = any
> extends ReadOnlyResource<Response> {
  /**
   * Creates a new instance of a resource.
   * @param path The path to the server resource.
   * @author Axel Nana <axel.nana@workerly.io>
   */
  constructor(path: string) {
    super(path);

    this.create = this.create.bind(this);
    this.update = this.update.bind(this);
    this.delete = this.delete.bind(this);
  }

  /**
   * Creates a new item for this resource.
   * @author Axel Nana <axel.nana@workerly.io>
   * @param {Request} data The item to create.
   * @param {RequestOptionsInit | undefined} options The extra request options.
   */
  async create(data: Request, options?: RequestOptionsInit): Promise<NeverUndefinedResponse<Response>> {
    return await this.request(this._path, { ...options, method: 'post', data });
  }

  /**
   * Updates an item for this resource, given its primary key.
   * @author Axel Nana <axel.nana@workerly.io>
   * @param {string} pk The item primary key.
   * @param {Request} data The updated item.
   * @param {RequestOptionsInit | undefined} options The extra request options.
   */
  async update(pk: string, data: Request, options?: RequestOptionsInit): Promise<NeverUndefinedResponse<Response>> {
    return await this.request(`${this._path}/${pk}`, { ...options, method: 'patch', data });
  }

  /**
   * Deletes an item for this resource, given its primary key.
   * @author Axel Nana <axel.nana@workerly.io>
   * @param {string} pk The item primary key.
   * @param {RequestOptionsInit | undefined} options The extra request options.
   */
  async delete(pk: string, options?: RequestOptionsInit): Promise<NeverUndefinedResponse<undefined>> {
    return await this.request(`${this._path}/${pk}`, { ...options, method: 'delete' });
  }
}
