import React, { useCallback } from 'react';
import { Col, Row, Drawer, Popconfirm, Divider, Typography, Table } from 'antd';
import { EyeOutlined, DeleteOutlined, EditOutlined, UserAddOutlined } from '@ant-design/icons';
import { ProColumns } from '@ant-design/pro-table';
import { useBoolean, usePersistFn } from '@umijs/hooks';

import { Overtime } from '@/data/Overtime';
import { useProTableRequest } from '@/utils/hooks';
import { Button, DataTable, Layout, TextDescription } from '@/components';
import { OvertimeResponseData, OvertimeResource } from '@/api/overtime';

const { Text } = Typography;
const { WLyPageContainer } = Layout;

const dataTableColumns: ProColumns<Overtime>[] = [
  {
    title: 'Name',
    dataIndex: 'name',
    copyable: true,
    ellipsis: true,
  },
  {
    title: 'Description',
    dataIndex: 'description',
    copyable: true,
    ellipsis: true,
    tooltip: 'What schedule does this payroll follow.',
  },
  {
    title: 'Multiplication Factor',
    dataIndex: 'payFactor',
    ellipsis: true,
    tooltip: 'If applied this will be use to calculate the overtimed price.',
    renderText: (text) => `x ${text}`,
  },
];

/**
 * @author Adonis SIMO <simo.adonis@workerly.io>
 */
export default () => {
  const drawerContentRenderer = usePersistFn((entity: Overtime) => {
    const columns = [
      {
        title: 'Hour Daily Limit',
        dataIndex: 'dailyLimit',
      },
      {
        title: 'Pay Factor',
        dataIndex: 'dailyRate',
      },
    ];

    return (
      <Row gutter={[18, 7]}>
        <Col span={12}>
          <TextDescription title="Name">{entity.name}</TextDescription>
        </Col>
        <Col span={12}>
          <TextDescription title="Overtime Factor" description="Will be used to calculate the effective wage">
            <Text>x {entity.payFactor}</Text>
          </TextDescription>
        </Col>
        <Col span={24}>
          <TextDescription title="Description">{entity.description}</TextDescription>
        </Col>
        {!entity.dailyOvertime && (
          <>
            <Col span={24}>
              <Divider>
                <small>Additional Daily Limits</small>
              </Divider>
            </Col>
            <Col>
              <TextDescription title="State">{entity.stateObject.state}</TextDescription>
            </Col>
            <Col span={24}>
              <Table
                bordered={false}
                pagination={false}
                dataSource={[
                  {
                    key: '1',
                    dailyLimit: `${entity.hourFirstMilestone} Hours`,
                    dailyRate: `x ${entity.hourFirstMilestonePayFactor}`,
                  },
                  {
                    key: '2',
                    dailyLimit: `${entity.hourSecondMilestone} Hours`,
                    dailyRate: `x ${entity.hourSecondMilestonePayFactor}`,
                  },
                ]}
                columns={columns}
              />
            </Col>
          </>
        )}
      </Row>
    );
  });

  return (
    <WLyPageContainer>
      <DataTable<Overtime, OvertimeResource>
        model={Overtime}
        resource={OvertimeResource}
        columns={dataTableColumns}
        renderDrawerContent={drawerContentRenderer}
        drawerProps={{
          title: 'Overtime Policies',
          width: '40%',
        }}
      />
    </WLyPageContainer>
  );
};
