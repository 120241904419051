import { DataModel } from '@/data/DataModel';
import { DepartmentResponseData, DepartmentHeadData, DepartmentParentData, DepartmentResource } from '@/api/department';
import { PositionResponseData, PositionResource } from '@/api/position';
import { Position } from '@/data/Position';
import { Employee } from '@/data/Employee';
import { EmployeeListResource } from '@/api/employees';
import { ConstrainedDataModel } from '@/data/ConstrainedDataModel';

/**
 * @author Adonis SIMO <simo.adonis@worerly.io>
 */
export class Department extends ConstrainedDataModel<DepartmentResponseData> {
  public get pk(): string {
    return this._rawData.pk;
  }

  public set pk(value: string) {
    this._rawData.pk = value;
  }

  public set name(value: string) {
    this._rawData.name = value;
  }

  public get name(): string {
    return this._rawData.name;
  }

  public set description(value: string) {
    this._rawData.description = value;
  }

  public get description(): string {
    return this._rawData.description;
  }

  public set code(value: string) {
    this._rawData.code = value;
  }

  public get code(): string {
    return this._rawData.code;
  }

  public set head(value: DepartmentHeadData | null) {
    this._rawData.head = value;
  }

  public get head(): DepartmentHeadData | null {
    return this._rawData.head;
  }

  public set parent(value: DepartmentParentData | null) {
    this._rawData.parent = value;
  }

  public get parent(): DepartmentParentData | null {
    return this._rawData.parent;
  }

  public set positions(values: PositionResponseData[]) {
    this._rawData.positions = values;
  }

  public get positions(): PositionResponseData[] {
    return this._rawData.positions;
  }

  public async getRelated(key: 'parent'): Promise<Department | null>;
  public async getRelated(key: 'head'): Promise<Employee | null>;
  public async getRelated(key: 'positions'): Promise<Position[] | null>;
  public async getRelated(key: keyof DepartmentResponseData): Promise<DataModel | null | DataModel[]> {
    switch (key) {
      case 'parent':
        if (this.parent?.pk === undefined) {
          return null;
        } else {
          const departmentRessource = new DepartmentResource();

          const { data } = await departmentRessource.get(this.parent.pk);
          return data.status ? new Department(data.data) : null;
        }

      case 'head':
        if (this.head?.pk === undefined) {
          return null;
        } else {
          const empResource = new EmployeeListResource();
          const { data } = await empResource.get(this.head.pk);
          return data.status ? new Employee(data.data) : null;
        }

      case 'positions':
        const positionResource = new PositionResource();
        const positions = [];
        for (var i = 0; i++; i < this.positions.length) {
          const { data } = await positionResource.get(this.positions[i].pk);
          if (data.status) {
            positions.push(new Position(data.data));
          }
        }
        return positions;

      default:
        break;
    }
    return null;
  }
}
