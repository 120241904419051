import { Resource } from '@/api/resource';

/**
 * Week day enumeration.
 * @author Adonis SIMO <simo.adonis@workerly.io>
 */
export enum WeekDays {
  Monday = 0,
  Tuesday = 1,
  Wednesday = 2,
  Thursday = 3,
  Friday = 4,
  Saturday = 5,
  Sunday = 6,
}

/**
 * WorkWeek request data sent to the api.
 * @author Adonis SIMO <simo.adonis@workerly.io>
 */
export interface WorkWeekRequestData {
  pk?: string;
  default?: boolean;
  name: string;
  description?: string;
  work_week_start: WeekDays;
  attendance_compute_delay: number;
  week_work: number;
  code: string;
}

/**
 * WorkWeek response data retrieved from the API.
 * @author Adonis SIMO <simo.adonis@workerly.io>
 */
export interface WorkWeekResponseData extends WorkWeekRequestData {
  pk: string;
}

/**
 * WorkWeek resource manager.
 * @author Adonis SIMO <simo.adonis@workerly.io>
 */
export class WorkWeekResource extends Resource<WorkWeekRequestData, WorkWeekResponseData> {
  constructor() {
    super('/work-week');
  }
}
