import { ReadOnlyResource } from '@/api/resource';

/**
 * The Industry raw data sent by the API.
 * @author Axel Nana <axel.nana@workerly.io>
 */
export interface IndustryResponseData {
  pk: string;
  name: string;
  description: string;
  parent: string;
  sub_industries: string[];
}

/**
 * The industry resource manager.
 * @author Axel Nana <axel.nana@workerly.io>
 */
export class IndustryResource extends ReadOnlyResource<IndustryResponseData> {
  constructor() {
    super('/industries');
  }
}
