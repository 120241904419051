import { Space, Typography } from 'antd';
import { isString } from 'lodash';
import classNames from 'classnames';

import { IconText, Link } from '@/components';

const { Text } = Typography;

/**
 * Required properties for the TextDescription component.
 * @author Axel Nana <axel.nana@workerly.io>
 */
export interface TextDescriptionProps {
  /**
   * The icon to display at the left of the title.
   * @type {React.ReactNode | undefined}
   */
  icon?: React.ReactNode;

  /**
   * The extra content to display at the right, on
   * the same line as the title.
   * @type {React.ReactNode | undefined}
   */
  extra?: React.ReactNode;

  /**
   * The title.
   * @type {React.ReactNode}
   */
  title: React.ReactNode;

  /**
   * The small text to display after the content.
   * @type {string | undefined}
   */
  description?: string;

  /**
   * Defines if this component must take all the available
   * horizontal space.
   * @type {boolean}
   */
  block?: boolean;
}

/**
 * Required properties for the LinkDescription component.
 * @author Axel Nana <axel.nana@workerly.io>
 */
export interface LinkDescriptionProps extends TextDescriptionProps {
  /**
   * The title.
   * @type {string}
   */
  href: string;
}

/**
 * Renders a title with an optional icon which describe a text or an arbitrary content.
 * @author Axel Nana <axel.nana@workerly.io>
 */
export function TextDescription({
  children,
  block,
  extra,
  icon,
  title,
  description,
}: React.PropsWithChildren<TextDescriptionProps>) {
  // TODO: display extra component at the most right position
  if (isString(title)) {
    title = <Text strong>{title}</Text>;
  }

  return (
    <Space
      className={classNames('wl-component-text-description', {
        'wl-component-text-description-block': block,
      })}
      direction="vertical"
    >
      <IconText
        leftIcon={icon}
        text={
          <Space direction="horizontal" size="large">
            <span className="wl-component-text-description-title">{title}</span>
            <span className="wl-component-text-description-extra">{extra}</span>
          </Space>
        }
      />
      <Space size="small" direction="vertical" className="wl-component-text-description-content">
        {children}
        {description && <small>{description}</small>}
      </Space>
    </Space>
  );
}

/**
 * Renders a title with an optional icon which describe a link.
 * @author Axel Nana <axel.nana@workerly.io>
 */
export function LinkDescription({ children, href, ...baseProps }: React.PropsWithChildren<LinkDescriptionProps>) {
  return (
    <TextDescription {...baseProps}>
      <Link to={href}>{children}</Link>
    </TextDescription>
  );
}
