import React from 'react';
import { message } from 'antd';
import { getIntl, history, useModel, useRequest } from 'umi';
import { useCookies } from 'react-cookie';
import { isEmpty, omit, pick } from 'lodash';
import { useCreation, useUpdateEffect } from '@umijs/hooks';

import {
  AuthLoginRequestData,
  AuthResource,
  AuthSignupRequestData,
  AuthResponseData,
  AuthResponseUserData,
  AuthResetPasswordRequestData,
} from '@/api/auth';
import { User } from '@/data';
import { logout } from '@/utils/auth';
import { AppRoutes } from '@/utils/routes';

interface UserModelState {
  user?: Partial<AuthResponseUserData>;
  token?: { access?: string; refresh?: string };
  permissions?: any;
  companies?: Array<any>;
}

export interface UserModel {
  user: User;
  token?: string;
  isValidState: boolean;
  loading: boolean;
  logIn(data: AuthLoginRequestData): void;
  logOut(): void;
  signUp(data: AuthSignupRequestData): void;
  resetPassword(data: AuthResetPasswordRequestData): void;
}

export default function useUser(): UserModel {
  const { setInitialState } = useModel('@@initialState');
  const [cookies, setCookie, removeCookie] = useCookies(['wl-user-data', 'wl-user-token', 'wl-user-permissions']);
  const intl = getIntl();

  const [state, setState] = React.useState<UserModelState>({
    user: cookies['wl-user-data'],
    token: cookies['wl-user-token'],
    permissions: cookies['wl-user-permissions'],
  });

  const authResource = useCreation(() => new AuthResource(), []);
  const user = useCreation(
    () => new User({ ...state.user, permissions: state.permissions, companies: state.companies }),
    [state],
  );
  const token = useCreation(() => state.token?.access, [state]);

  const _updateStateWithResponse = (data?: AuthResponseData) => {
    setState({
      user: omit(data?.user, ['permissions', 'companies']),
      token: { access: data?.access, refresh: data?.refresh },
      ...pick(data?.user, ['permissions']),
      ...pick(data?.user, ['companies']),
    });
  };

  const _cleanState = () => {
    setState({
      user: undefined,
      token: undefined,
      permissions: undefined,
      companies: undefined,
    });
  };

  const { run: runLoginRequest, loading: loadingLoginRequest } = useRequest(authResource.login, {
    manual: true,
    onSuccess: result => {
      if (result.status) {
        _updateStateWithResponse(result.data);

        message.success({
          content: intl.formatMessage(
            { id: 'auth.login.messages.welcome' },
            { name: React.createElement('b', null, `${result.data?.user.first_name} ${result.data?.user.last_name}`) },
          ),
        });
      }
    },
  });

  const { run: runSignupRequest, loading: loadingSignupRequest } = useRequest(authResource.signup, {
    manual: true,
    onSuccess: (result, params) => {
      if (result.status) {
        _updateStateWithResponse(result.data);

        message.success({
          content: intl.formatMessage(
            { id: 'auth.signup.messages.welcome' },
            { name: React.createElement('b', null, `${params[0].first_name} ${params[0].last_name}`) },
          ),
        });
      }
    },
  });

  const { run: runLogoutRequest, loading: loadingLogoutRequest } = useRequest(authResource.logout, {
    manual: true,
    onSuccess: result => {
      if (result.status) {
        _cleanState();

        message.success({
          content: intl.formatMessage({ id: 'auth.logout.messages.goodby' }),
        });
      }
    },
  });

  const { run: runResetPasswordRequest, loading: loadingResetPasswordRequest } = useRequest(
    authResource.resetPassword,
    {
      manual: true,
      onSuccess: result => {
        if (result.status) {
          history.push(isValidState ? AppRoutes.accountWorkspaces.path : AppRoutes.accountLogin.path);
          message.success({
            content: intl.formatMessage({ id: 'auth.reset_password.messages.operation_success' }),
          });
        }
      },
    },
  );

  const loading = useCreation(
    () => loadingLoginRequest || loadingSignupRequest || loadingLogoutRequest || loadingResetPasswordRequest,
    [loadingLoginRequest, loadingSignupRequest, loadingLogoutRequest, loadingResetPasswordRequest],
  );

  const isValidState = useCreation(
    () => state !== null && !isEmpty(state.user) && !isEmpty(state.token), // && !isEmpty(state.permissions),
    [state],
  );

  const logIn = React.useCallback(
    async ({ username, password, remember }: AuthLoginRequestData) => {
      await runLoginRequest({ username, password, remember });
    },
    [runLoginRequest],
  );

  const logOut = React.useCallback(async () => {
    await runLogoutRequest({ refresh_token: state.token?.refresh });
    logout();
  }, [state, runLogoutRequest]);

  const signUp = React.useCallback(
    async ({ first_name, last_name, email }: AuthSignupRequestData) => {
      await runSignupRequest({ first_name, last_name, email });
    },
    [runSignupRequest],
  );

  const resetPassword = React.useCallback(
    async (data: AuthResetPasswordRequestData) => {
      await runResetPasswordRequest(data);
    },
    [runResetPasswordRequest],
  );

  // Updates cookies and initial state on user login/logout
  useUpdateEffect(() => {
    if (!isValidState) {
      for (const name in cookies) {
        removeCookie(name, { path: '/' });
      }

      setInitialState({
        user: undefined,
        token: undefined,
        permissions: undefined,
        companies: undefined,
      });
    } else {
      setCookie('wl-user-data', JSON.stringify(state.user), { path: '/' });
      setCookie('wl-user-token', JSON.stringify(state.token), { path: '/' });
      // setCookie('wl-user-permissions', JSON.stringify(state.permissions), { path: '/' });

      setInitialState(state);
    }
  }, [isValidState]);

  return {
    user,
    token,
    isValidState,
    loading,
    logIn,
    logOut,
    signUp,
    resetPassword,
  };
}
