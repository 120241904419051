import { Space } from 'antd';
import { isUndefined } from 'lodash';

/**
 * The properties required for the IconText component.
 * @author Axel Nana <axel.nana@workerly.io>
 */
export interface IconTextProps {
  /**
   * The icon which appear on the left of the text.
   * @type {React.ReactNode}
   */
  leftIcon?: React.ReactNode;

  /**
   * The text to display between left and right icons.
   * @type {React.ReactNode}
   */
  text: React.ReactNode;

  /**
   * The icon which appear on the right of the text.
   * @type {React.ReactNode}
   */
  rightIcon?: React.ReactNode;
}

/**
 * Renders a text wrapped by icons on the left and right sides.
 * @author Axel Nana <axel.nana@workerly.io>
 * @param {IconTextProps} param0 The properties assigned to this component.
 */
export function IconText({ leftIcon, text, rightIcon }: IconTextProps) {
  return (
    <Space className="wl-component-icon-text">
      {leftIcon && <span className="wl-component-icon-text-left-icon">{leftIcon}</span>}
      <span className="wl-component-icon-text-content">{text}</span>
      {rightIcon && <span className="wl-component-icon-text-right-icon">{rightIcon}</span>}
    </Space>
  );
}
