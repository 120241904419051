import React from 'react';
import { AutoComplete } from 'antd';
import usePlacesAutocomplete, { getGeocode, getLatLng, Suggestion } from 'use-places-autocomplete';
import { extend } from 'umi-request';
import { useUpdateEffect } from '@umijs/hooks';

/**
 * Custom request object for Google Maps API.
 */
const request = extend({
  prefix: 'https://maps.googleapis.com/maps/api',
  responseType: 'json',
  getResponse: true,
  parseResponse: true,
});

request.use(async (ctx, next) => {
  const { req } = ctx;
  const { options } = req;

  ctx.req.options = {
    ...options,
    headers: {},
  };

  await next();
});

/**
 * Properties used in a PlacesAutoComplete component.
 * @author Axel Nana <axel.nana@workerly.io>
 */
export interface PlacesAutocompleteProps {
  /**
   * Event triggered when the user select a place in the list.
   * @param {Suggestion} place The selected place suggestion.
   */
  onSelectionChange?(place: Suggestion): void;

  /**
   * The placeholder text when no place is selected.
   */
  placeholder?: string;
}

/**
 * Renders an autocomplete input displaying places from the Google Maps API.
 * @author Axel Nana <axel.nana@workerly.io>
 * @param param0 PlacesAutocomplete properties
 */
export function PlacesAutocomplete({ onSelectionChange, placeholder }: PlacesAutocompleteProps) {
  const {
    value,
    suggestions: { data },
    setValue,
  } = usePlacesAutocomplete({
    callbackName: 'initMap',
    debounce: 250,
    requestOptions: {
      types: ['geocode'],
      fields: [
        'address_component',
        'geometry',
        'icon',
        'name',
        'business_status',
        'place_id',
        'plus_code',
        'types',
        'price_level',
        'rating',
        'user_ratings_total',
        'opening_hours',
        'photos',
      ],
      componentRestrictions: {
        country: ['us'],
      },
    },
  });

  const [selectedPlace, setSelectedPlace] = React.useState<Suggestion>();

  const places = React.useMemo(
    () =>
      data.map((suggestion: Suggestion) => {
        const {
          id,
          structured_formatting: { main_text, secondary_text },
          description,
        } = suggestion;

        return {
          value: description,
          label: (
            <span className="wl-component-places-autocomplete-suggestion" key={id}>
              <b>{main_text}</b>
              <small>{secondary_text}</small>
            </span>
          ),
          suggestion,
        };
      }),
    [data],
  );

  const _onSearch = (address: string) => {
    setSelectedPlace(undefined);
    setValue(address);
  };

  const _onChange = (address: string) => {
    setValue(address);
  };

  const _onSelect = (description: string, { suggestion }: any) => {
    // When user select a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"
    setValue(description, false);

    // Set the selected place
    setSelectedPlace((oldValue: Suggestion) => ({ ...oldValue, ...suggestion }));

    // Get latitude and longitude via utility functions
    getGeocode({ address: description })
      .then(results => {
        // Set the selected place + geocoding
        setSelectedPlace((oldValue: Suggestion) => ({ ...oldValue, geocoding: { ...results[0] } }));
        return getLatLng(results[0]);
      })
      .then(({ lat, lng }) => {
        // Set the selected place + geolocation
        setSelectedPlace((oldValue: Suggestion) => ({ ...oldValue, coordinates: { lat, lng } }));
        return request.get(
          `/timezone/json?location=${lat},${lng}&timestamp=${Math.round(
            Date.now() / 1000,
          )}&key=AIzaSyBhkz99Q2DK_ufaSGh7Rx2SUgDWSppwPBI`,
        );
      })
      .then(response => {
        // Set the selected place + timezone
        setSelectedPlace((oldValue: Suggestion) => ({ ...oldValue, timezone: response.data }));
      })
      .catch(error => {
        console.error('Error: ', error);
      });
  };

  useUpdateEffect(() => {
    if (onSelectionChange !== undefined) {
      onSelectionChange(selectedPlace);
    }
  }, [onSelectionChange, selectedPlace]);

  return (
    <AutoComplete
      className="wl-component-places-autocomplete"
      value={value}
      options={places}
      onSelect={_onSelect}
      onSearch={_onSearch}
      onChange={_onChange}
      placeholder={placeholder}
    />
  );
}
