import { DataModel } from '@/data/DataModel';
import { ActivityDataTimesheet, TimesheetResponseData } from '@/api/timesheet';
import { ConstrainedDataModel } from './ConstrainedDataModel';
import { DateTime } from 'luxon';
import _ from 'lodash';

export class Timesheet extends ConstrainedDataModel<TimesheetResponseData> {
  public getRelated(
    key: string | number,
  ): Promise<DataModel<{ [key: string]: any }> | DataModel<{ [key: string]: any }>[] | null> {
    throw new Error('Method not implemented.');
  }

  public get fullName(): string {
    return this._rawData.worker;
  }

  public get activity(): ActivityDataTimesheet {
    return this._rawData.activity;
  }

  public get weekStartTime(): DateTime {
    return DateTime.fromISO(this._rawData.week_start_time);
  }

  public get weekEndTime(): DateTime {
    return DateTime.fromISO(this._rawData.week_end_time);
  }

  public get formerDisputedAttendances(): any {
    return this._rawData.former_disputed_attendances;
  }

  public get totalEarnings(): number {
    return this._rawData.activity.week_processed.total_earned;
  }

  public get dayWorked(): number {
    return _.reduce(
      this._rawData.activity.sub_periods,
      (result, value, key) => {
        return result + value.period_work_done.effective_work_days;
      },
      0,
    );
  }

  public get departments(): string {
    if (this._rawData.departments.length > 0) {
      return this._rawData.departments[0].name;
    } else {
      return '';
    }
  }

  get pk(): string | undefined {
    return undefined;
  }
}
