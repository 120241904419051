import { DateTime } from 'luxon';

import { AuthInvitationResponseData } from '@/api/auth';
import { CompanyResource } from '@/api/company';
import { Company, DataModel } from '@/data';

/**
 * The Invitation data model interpreted by the app.
 * @author Axel Nana <axel.nana@workerly.io>
 */
export class Invitation extends DataModel<AuthInvitationResponseData> {
  public get pk(): string | undefined {
    return this._rawData.pk;
  }

  public get accepted(): boolean {
    return this._rawData.accepted;
  }

  public get company(): string {
    return this._rawData.company;
  }

  public get companyName(): string {
    return this._rawData.company_name;
  }

  public get email(): string {
    return this._rawData.email;
  }

  public get key(): string {
    return this._rawData.key;
  }

  public get sent(): DateTime {
    return DateTime.fromISO(this._rawData.sent, { setZone: true });
  }

  public async getRelated(key: 'company'): Promise<Company | null>;
  public async getRelated(key: keyof AuthInvitationResponseData): Promise<DataModel | null> {
    switch (key) {
      case 'company':
        const companyResource = new CompanyResource();
        const { data } = await companyResource.get(this.company);
        return data.status ? new Company(data.data) : null;

      default:
        throw new Error(`The given key "${key}" is not for an entity related to this one.`);
    }
  }
}
