import React from 'react';
import { DateTime } from 'luxon';
import { ProColumns } from '@ant-design/pro-table';
import { Col, Row, Typography, List, Divider } from 'antd';
import { useCreation, usePersistFn } from '@umijs/hooks';

import { TextDescription, Layout, DataTable } from '@/components';
import { useProTableRequest } from '@/utils/hooks';
import { PayrollResponseData, PayrollResource } from '@/api/payroll';
import { Payroll } from '@/data/Payroll';

const { Text } = Typography;
const { WLyPageContainer } = Layout;

const dataTableColumns: ProColumns<Payroll>[] = [
  {
    title: 'Name',
    dataIndex: 'name',
    copyable: true,
    ellipsis: true,
    tip: "Payroll's  Name",
  },
  {
    title: 'Schedule',
    dataIndex: 'payrollScheduleText',
    copyable: true,
    ellipsis: true,
    tip: 'What Schedule Does This Payroll Follow',
  },
  {
    title: 'Next Deadline',
    dataIndex: 'nextDeadline',
    copyable: false,
    ellipsis: true,
    renderText: (nextDeadline: any) =>
      nextDeadline ? nextDeadline.toLocaleString(DateTime.DATETIME_MED) : new Date().toString(),
    //   tip: ,
  },
  {
    title: 'Previous Deadline',
    dataIndex: 'previousDeadline',
    copyable: false,
    ellipsis: true,
    //   tip: "The Next Deadline",
    renderText: (previousDeadline: any) =>
      previousDeadline ? previousDeadline.toLocaleString(DateTime.DATETIME_MED) : new Date().toString(),
  },
  {
    title: 'Payroll Periods',
    dataIndex: 'yearPayroll',
    copyable: false,
    ellipsis: true,
    tip: 'Number of Fimeframe In One Year Corresponding To This Payroll',
    renderText: (item) => item?.length,
  },
];

/**
 * @author Adonis SIMO <simo.adonis@workerly.io>
 */
export default () => {
  const drawerContentRenderer = usePersistFn((entity: Payroll) => (
    <Row gutter={[16, 32]}>
      <Col span={12}>
        <TextDescription title="Name">{entity.name}</TextDescription>
      </Col>
      <Col span={12}>
        <TextDescription title="Schedule">{entity.payrollScheduleText}</TextDescription>
      </Col>
      <Col span={12}>
        <TextDescription title="Next Deadline">{entity.nextDeadline.toLocaleString(DateTime.DATE_MED)}</TextDescription>
      </Col>
      <Col span={12}>
        <TextDescription title="Previous Deadline">
          {entity.previousDeadline.toLocaleString(DateTime.DATE_MED)}
        </TextDescription>
      </Col>
      <Col span={24}>
        <Divider plain>
          <Text strong>Payroll Periods</Text>
        </Divider>
        <List
          bordered={false}
          split
          style={{ width: '100%' }}
          dataSource={entity.yearPayroll}
          renderItem={(item) => (
            <List.Item>
              <Row style={{ width: '100%' }}>
                <Col span={12}>
                  <TextDescription block title="Start">
                    {item.start.toLocaleString(DateTime.DATE_MED)}
                  </TextDescription>
                </Col>
                <Col span={12}>
                  <TextDescription block title="End">
                    {item.end.toLocaleString(DateTime.DATE_MED)}
                  </TextDescription>
                </Col>
              </Row>
            </List.Item>
          )}
        />
      </Col>
    </Row>
  ));

  return (
    <WLyPageContainer>
      <DataTable<Payroll, PayrollResource>
        model={Payroll}
        resource={PayrollResource}
        drawerProps={{
          title: 'Consult Payroll',
          width: 420,
          closable: true,
        }}
        renderDrawerContent={drawerContentRenderer}
        columns={dataTableColumns}
      />
    </WLyPageContainer>
  );
};
