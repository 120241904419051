import React from 'react';
import { PlusOutlined, CaretDownOutlined } from '@ant-design/icons';
import { Button } from '@/components/Button';
import { WLyPageContainer } from '@/components/Layout/WLyPageContainer';
import { Chip } from '@/components/Chip';
import _ from 'lodash';
import { CompanyCard } from '@/components';

export default () => {
  const [selected, setSelected] = React.useState<Array<string>>([]);

  return (
    <WLyPageContainer ghost>
      {/*<CompanyCard hoverable company={{}} />*/}
      <div style={{ height: 32 }} />
      {/*<EmployeeCard employee={{}} hoverable />*/}
      <div style={{ height: 32 }} />
      <Button type="primary">Simple Button</Button>
      <Button leftIcon={<PlusOutlined />} type="primary" style={{ marginLeft: 16 }}>
        With Left Icon
      </Button>
      <Button rightIcon={<CaretDownOutlined />} type="primary" style={{ marginLeft: 16 }}>
        With Right Icon
      </Button>
      <Button leftIcon={<PlusOutlined />} rightIcon={<CaretDownOutlined />} type="primary" style={{ marginLeft: 16 }}>
        With Both Icons
      </Button>
      <Button loading type="primary" style={{ marginLeft: 16 }}>
        Loading Button
      </Button>
      <div style={{ height: 32 }} />
      <Chip
        selectable
        onSelectionChange={(s) => {
          const __ = [...selected];
          if (s) {
            __.push('Pharma');
            setSelected(_.uniq(__));
          } else {
            setSelected(_.remove(__, (i) => i !== 'Pharma'));
          }
        }}
      >
        Pharma
      </Chip>
      <Chip
        selectable
        style={{ marginLeft: 16 }}
        onSelectionChange={(s) => {
          const __ = [...selected];
          if (s) {
            __.push('Health');
            setSelected(_.uniq(__));
          } else {
            setSelected(_.remove(__, (i) => i !== 'Health'));
          }
        }}
      >
        Health
      </Chip>
      <Chip style={{ marginLeft: 16 }}>Selected: {selected.join(', ')}</Chip>
      <Chip avatar="https://i.pravatar.cc/" style={{ marginLeft: 16 }}>
        With avatar
      </Chip>
      <Chip hoverable avatar="https://i.pravatar.cc/" style={{ marginLeft: 16 }}>
        Hoverable with avatar
      </Chip>
    </WLyPageContainer>
  );
};
