import React from 'react';
import { Redirect, useModel, Link, useIntl } from 'umi';
import { Layout, Row, Col, Card, Form, Input, Checkbox, Spin } from 'antd';
import { MailOutlined, LoadingOutlined } from '@ant-design/icons';

import WorkerLyLogoHorizontal from '@/assets/images/brand/logo_horizontal.png';
import { AppRoutes } from '@/utils/routes';
import { Button } from '@/components';

import '@/pages/auth/auth.less';

const { Content } = Layout;

export default () => {
  const { loading, signUp, isValidState } = useModel('user');

  const intl = useIntl();
  const [acceptTOS, setAcceptTOS] = React.useState(false);

  if (isValidState) {
    return <Redirect to={AppRoutes.accountWorkspaces.path} />;
  }

  return (
    <Layout className="wl-auth-page wl-fullscreen" hasSider={false}>
      <Content className="wl-fullscreen">
        <Row justify="space-around" align="middle" className="wl-fullscreen">
          <Col xs={22} sm={20} md={10} lg={6}>
            <Card bordered className="wl-auth-page-card-form">
              <Spin indicator={<LoadingOutlined />} spinning={loading} tip="Please wait...">
                <Row justify="space-around">
                  <Col>
                    <img className="wl-auth-page-logo" src={WorkerLyLogoHorizontal} />
                  </Col>
                </Row>
                <Row justify="space-around">
                  <Col span={24}>
                    <p className="wl-auth-page-intro-text">
                      {intl.formatMessage({ id: 'auth.signup.page.form.intro' })}
                    </p>
                  </Col>
                  <Col span={24}>
                    <Form
                      name="signup"
                      layout="vertical"
                      className="wl-auth-page-form"
                      initialValues={{ remember: true }}
                      onFinish={signUp}
                    >
                      <Row gutter={[8, 0]}>
                        <Col span={12}>
                          <Form.Item
                            labelCol={{ span: 24 }}
                            label={intl.formatMessage({ id: 'auth.signup.page.form.label.first_name' })}
                            name="first_name"
                            rules={[
                              {
                                required: true,
                                message: intl.formatMessage({ id: 'auth.signup.errors.no_first_name' }),
                              },
                            ]}
                          >
                            <Input placeholder={intl.formatMessage({ id: 'auth.signup.page.form.label.first_name' })} />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            labelCol={{ span: 24 }}
                            label={intl.formatMessage({ id: 'auth.signup.page.form.label.last_name' })}
                            name="last_name"
                            rules={[
                              {
                                required: true,
                                message: intl.formatMessage({ id: 'auth.signup.errors.no_last_name' }),
                              },
                            ]}
                          >
                            <Input placeholder={intl.formatMessage({ id: 'auth.signup.page.form.label.last_name' })} />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item
                            labelCol={{ span: 24 }}
                            label={intl.formatMessage({ id: 'auth.signup.page.form.label.email' })}
                            name="email"
                            rules={[
                              {
                                required: true,
                                message: intl.formatMessage({ id: 'auth.signup.errors.no_email' }),
                              },
                            ]}
                          >
                            <Input
                              prefix={<MailOutlined className="wl-form-item-icon" />}
                              placeholder={intl.formatMessage({ id: 'auth.signup.page.form.label.email' })}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item>
                            <Form.Item name="accept_tos" valuePropName="checked" noStyle>
                              <Checkbox value={acceptTOS} onChange={e => setAcceptTOS(e.target.checked)}>
                                {intl.formatMessage({ id: 'auth.signup.page.form.label.accept_tos' })}
                              </Checkbox>
                            </Form.Item>
                          </Form.Item>
                        </Col>
                      </Row>

                      <Form.Item className="wl-auth-page-form-login-wrapper">
                        <Button
                          disabled={!acceptTOS}
                          type="primary"
                          htmlType="submit"
                          className="wl-auth-page-form-button-login"
                        >
                          {intl.formatMessage({ id: 'auth.signup.page.form.action.signup' })}
                        </Button>
                        {intl.formatMessage(
                          { id: 'auth.signup.page.form.action.login' },
                          { a: (...chunks) => <Link to={AppRoutes.accountLogin.path}>{chunks}</Link> },
                        )}
                      </Form.Item>
                    </Form>
                  </Col>
                </Row>
              </Spin>
            </Card>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};
