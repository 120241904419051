import { DateTime } from 'luxon';
import { DataModel } from '@/data/DataModel';
import { ConstrainedDataModel } from '@/data/ConstrainedDataModel';
import { PayrollResponseData, PayrollSchedule } from '@/api/payroll';
import { values } from 'lodash';

/**
 * @author Adonis SIMO <simo.adonis@worerly.io>
 */
export class Payroll extends ConstrainedDataModel<PayrollResponseData> {
  public getRelated(key: keyof PayrollResponseData): Promise<DataModel | DataModel[] | null> {
    throw new Error('Method not implemented.');
  }

  // TODO: review how to handle this
  // public get default(): boolean{
  //   return this._rawData.default || undefined
  // }

  public get pk(): string {
    return this._rawData.pk;
  }

  public set pk(value: string) {
    this._rawData.pk = value;
  }

  public get nextDeadline(): DateTime {
    return DateTime.fromISO(this._rawData.next_deadline);
  }

  public get previousDeadline(): DateTime {
    return DateTime.fromISO(this._rawData.previous_deadline);
  }

  public get yearPayroll(): Array<{ start: DateTime; end: DateTime }> {
    // 2020-10-01 00:00:00+00:00
    // DateTime.fromFormat(start, "yyyy-MM-dd HH:mm:ssZZ");
    return this._rawData.year_payrolls?.map((period) => {
      const [start, end] = period.split('--', 2);
      return {
        start: DateTime.fromFormat(start, 'yyyy-MM-dd HH:mm:ssZZ', {
          setZone: true,
        }),
        end: DateTime.fromFormat(end, 'yyyy-MM-dd HH:mm:ssZZ', {
          setZone: true,
        }),
      };
    });
  }

  public get name(): string {
    return this._rawData.name;
  }

  public set name(value: string) {
    this._rawData.name = value;
  }

  public get payrollSchedule(): PayrollSchedule {
    return this._rawData.payroll_schedule;
  }

  public set payrollSchedule(value: PayrollSchedule) {
    this._rawData.payroll_schedule = value;
  }

  public get payrollScheduleText(): string {
    switch (this.payrollSchedule) {
      case PayrollSchedule.Monthly:
        return 'Monthly';
      case PayrollSchedule.SemiMonthly:
        return 'Semi-Montly';
      case PayrollSchedule.BiWeekly:
        return 'Bi-Weekly';
      case PayrollSchedule.Weekly:
        return 'Weekly';
      case PayrollSchedule.Daily:
        return 'Daily';
      default:
        return '';
    }
  }

  public get start(): DateTime {
    return DateTime.fromISO(this._rawData?.start, { setZone: true });
  }

  public set start(value: DateTime) {
    return (this._rawData.start = value.toISO());
  }
}
