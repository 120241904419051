import { DataModel } from '@/data/DataModel';
import { ShiftResponseData } from '@/api/shift';
import { BreakResource } from '@/api/break';
import { EmployeeCreationResource } from '@/api/employees';
import { Break } from '@/data/Break';
import { Employee } from '@/data/Employee';

/**
 * @author Adonis SIMO <simo.adonis@worerly.io>
 */
export class Shift extends DataModel<ShiftResponseData> {
  public async getRelated(key: 'breaks'): Promise<Break[] | null>;
  public async getRelated(key: 'employees_assigned'): Promise<Employee[] | null>;
  public async getRelated(key: keyof ShiftResponseData): Promise<DataModel | DataModel[] | null> {
    switch (key) {
      case 'breaks':
        const brResource = new BreakResource();
        const breaks: Break[] = [];
        for (var i = 0; i++; i < this._rawData.breaks.length) {
          const { data } = await brResource.get(this._rawData.breaks[i].pk);
          if (data.status) {
            breaks.push(new Break(data.data));
          }
        }
        return breaks;

      case 'employees_assigned':
        const empResource = new EmployeeCreationResource();
        const employees: Employee[] = [];
        for (var i = 0; i++; i < this._rawData.employees_assigned.length) {
          const { data } = await empResource.get(this._rawData.employees_assigned[i].pk);
          if (data.status) {
            employees.push(new Employee(data.data));
          }
        }
        return employees;

      default:
        throw new Error(`No relation found`);
    }
    return null;
  }

  public async assignEmployees(employees: Employee[], isOvertime: boolean = false, isRemote: boolean = false) {
    return await Promise.resolve();
  }

  public get pk(): string {
    return this._rawData.pk;
  }

  public set pk(value: string) {
    this._rawData.pk = value;
  }

  public get timezone(): string {
    return this._rawData.timezone;
  }

  public set timezone(value: string) {
    this._rawData.timezone = this.timezone;
  }

  public get capacity(): number {
    return this._rawData.capacity;
  }

  public set capacity(value: number) {
    this._rawData.capacity = value;
  }

  public get primaryCrossingEmployees(): Array<string> {
    return this._rawData.primary_crossing_employees;
  }

  public get secondaryCrossingEmployees(): Array<string> {
    return this._rawData.secondary_crossing_employees;
  }

  public get shiftPolulation(): number {
    return this._rawData.shift_population;
  }

  public get locked(): string {
    return this._rawData.locked;
  }

  public set locked(value: string) {
    this._rawData.locked = value;
  }

  public set earlyStartGapMinutes(value: number) {
    this._rawData.early_end_gap_minutes = value;
  }

  public get earlyStartGapMinutes(): number {
    return this._rawData.early_start_gap_minutes;
  }

  public get lateStartGapMinutes(): number {
    return this._rawData.late_start_gap_minutes;
  }

  public set lateStartGapMinutes(value: number) {
    this._rawData.late_start_gap_minutes = value;
  }

  public get startTimeframe(): string {
    return this._rawData.start_timeframe;
  }

  public set startTimeframe(value: string) {
    this._rawData.start_timeframe = value;
  }

  public get days(): number[] {
    return this._rawData.days;
  }

  public set days(value: number[]) {
    this._rawData.days = value;
  }

  public get enable(): boolean {
    return this._rawData.enable;
  }

  public set enable(value: boolean) {
    this._rawData.enable = value;
  }

  public get description(): string {
    return this._rawData.description;
  }

  public set description(value: string) {
    this._rawData.description = value;
  }

  public get midNightCross(): boolean {
    return this._rawData.mid_night_cross;
  }

  public get startGapMinutes(): number {
    return this._rawData.start_gap_minutes;
  }

  public set startGapMinutes(value: number) {
    this._rawData.start_gap_minutes = value;
  }

  public get endGapMinutes(): number {
    return this._rawData.end_gap_minutes;
  }

  public set endGapMinutes(value: number) {
    this._rawData.end_gap_minutes = value;
  }

  /**
   * Assigne a set of employees for onvertime to a specific shift
   * @param employees employee to be assigned in overtime
   * @author Adonis SIMO <simo.adonis@workerly.io>
   */
  public assignOvertimeEmployee(employees: Employee[]) {}

  /**
   * Assigne a set of employees for regular hours to a specific shift
   * @param employees employee to be assigned regularly
   * @author Adonis SIMO <simo.adonis@workerly.io>
   */
  public assigneRegularEmployes(employees: Employee[]) {}

  /**
   * Un-assigne an employee from a shift after he's being assigned
   * @param employee employee to un-assigne
   * @author Adonis SIMO <simo.adonis@workerly.io>
   */
  public unAssignEmployee(employee: Employee) {}
}
