import { FormattedMessage } from 'umi';
import { List } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';

import { AuthResponseUserDataEmailAddress } from '@/api/auth';
import { Badge, Button } from '@/components';

/**
 * Display an email address in the account settings.
 * @author Axel Nana <axel.nana@workerly.io>
 * @param {{ address: AuthResponseUserDataEmailAddress }} param0 An object containing the address field to display
 */
export function EmailAddressItem({ address }: { address: AuthResponseUserDataEmailAddress }) {
  return (
    <List.Item className="wl-app-workspace-settings-form-email-address-list-item">
      <Badge
        type={address.verified ? 'success' : 'danger'}
        content={<FormattedMessage id={address.verified ? 'status.verified' : 'status.unverified'} />}
      />
      {address.primary && <Badge content="Primary" />}
      <span className="wl-app-workspace-settings-form-email-address-list-item-content">{address.email}</span>
      {!address.verified && (
        <Button type="success" leftIcon={<CheckCircleOutlined />}>
          <FormattedMessage id="action.verify" />
        </Button>
      )}
    </List.Item>
  );
}
