import React from 'react';
import {
  message,
  Alert,
  Avatar,
  Card,
  Col,
  Divider,
  Form,
  Input,
  List,
  PageHeader,
  Popconfirm,
  Row,
  Space,
  Switch,
  Tabs,
  Typography,
} from 'antd';
import {
  CheckCircleOutlined,
  DeleteOutlined,
  FileDoneOutlined,
  LockOutlined,
  MailOutlined,
  MergeCellsOutlined,
  MinusCircleOutlined,
  UploadOutlined,
  UsergroupAddOutlined,
  UserOutlined,
  ToolOutlined,
  PlayCircleOutlined,
  PlusOutlined,
  SafetyOutlined,
  StopOutlined,
  SyncOutlined,
  WarningFilled,
} from '@ant-design/icons';
import ProList from '@ant-design/pro-list';
import { FormattedMessage, useIntl, useModel, useRequest } from 'umi';
import { isUndefined } from 'lodash';
import { useBoolean, useCreation } from '@umijs/hooks';
import { DateTime } from 'luxon';

import { AuthResource, useInvitations } from '@/api/auth';
import { Button } from '@/components';
import { Invitation } from '@/data/Invitation';
import { MergeAccountsModal } from '@/pages/account/components/merge-accounts-modal';
import { EmailAddressItem } from '@/pages/account/components/email-address-item';

import '@/pages/account/styles/settings.less';
import { IconText } from '@/components/IconText';

const { TabPane } = Tabs;
const { Text, Title, Paragraph } = Typography;

/**
 * The account settings page.
 * @author Axel Nana <axel.nana@workerly.io>
 */
function AccountSettings() {
  const [form] = Form.useForm();
  const { user } = useModel('user');
  const intl = useIntl();

  const _onSaveChanges = React.useCallback(() => {}, []);
  const _onResetChanges = React.useCallback(() => {}, []);

  return (
    <Form form={form} className="wl-app-workspace-settings-container">
      <Row>
        <Col span={24}>
          <Title
            className="wl-app-workspace-settings-title"
            level={3}
            title={intl.formatMessage({ id: 'workspaces.settings.account.title' })}
          >
            {intl.formatMessage({ id: 'workspaces.settings.account.title' })}
          </Title>
        </Col>
      </Row>
      <Row gutter={[24, 0]}>
        <Col span={14}>
          <Row gutter={[8, 16]}>
            <Col span={12}>
              <Title className="wl-app-workspace-settings-form-title" level={5}>
                {intl.formatMessage({ id: 'workspaces.settings.account.form.label.first_name' })}
              </Title>
              <Form.Item
                name="first_name"
                required
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'workspaces.settings.account.form.message.no_first_name' }),
                  },
                ]}
              >
                <Input
                  placeholder={intl.formatMessage({ id: 'workspaces.settings.account.form.label.first_name' })}
                  defaultValue={user.first_name}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Title className="wl-app-workspace-settings-form-title" level={5}>
                {intl.formatMessage({ id: 'workspaces.settings.account.form.label.last_name' })}
              </Title>
              <Form.Item
                name="last_name"
                required
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'workspaces.settings.account.form.message.no_last_name' }),
                  },
                ]}
              >
                <Input
                  placeholder={intl.formatMessage({ id: 'workspaces.settings.account.form.label.last_name' })}
                  defaultValue={user.last_name}
                />
              </Form.Item>
            </Col>

            <Divider style={{ marginTop: 0 }} />

            <Col span={24}>
              <Title className="wl-app-workspace-settings-form-title" level={5}>
                {intl.formatMessage({ id: 'workspaces.settings.account.form.label.email_addresses' })}
              </Title>

              <List
                className="wl-app-workspace-settings-form-email-address-list"
                dataSource={user.emailAddressesSet}
                renderItem={item => <EmailAddressItem address={item} />}
              />

              <Form.List name="emails">
                {(fields, { add, remove }) => (
                  <React.Fragment>
                    {fields.map(field => (
                      <div
                        key={field.key}
                        style={{ display: 'flex', flexDirection: 'row', marginBottom: 8, alignItems: 'baseline' }}
                      >
                        <Form.Item
                          {...field}
                          name={[field.name, 'email']}
                          fieldKey={[field.fieldKey, 'email']}
                          rules={[
                            {
                              required: true,
                              message: intl.formatMessage({ id: 'workspaces.settings.account.form.message.no_email' }),
                            },
                          ]}
                          style={{ flexGrow: 1 }}
                        >
                          <Input
                            prefix={<MailOutlined />}
                            type="email"
                            placeholder={intl.formatMessage({ id: 'workspaces.settings.account.form.label.email' })}
                          />
                        </Form.Item>
                        <MinusCircleOutlined
                          style={{ marginLeft: 8, marginRight: 8 }}
                          onClick={() => remove(field.name)}
                          title={intl.formatMessage({ id: 'action.remove' })}
                        />
                      </div>
                    ))}
                    <Form.Item>
                      <Button dashed onClick={() => add()} block leftIcon={<PlusOutlined />}>
                        {intl.formatMessage({ id: 'action.add' })}
                      </Button>
                    </Form.Item>
                  </React.Fragment>
                )}
              </Form.List>
            </Col>

            <Divider style={{ marginTop: 0 }} />

            <Col span={24}>
              <Button type="danger" style={{ marginRight: 16 }} onClick={_onResetChanges}>
                {intl.formatMessage({ id: 'action.reset' })}
              </Button>
              <Button type="success" rightIcon={<CheckCircleOutlined />} onClick={_onSaveChanges}>
                {intl.formatMessage({ id: 'action.save' })}
              </Button>
            </Col>
          </Row>
        </Col>
        <Col span={10}>
          <Title className="wl-app-workspace-settings-form-title" level={5}>
            {intl.formatMessage({ id: 'workspaces.settings.account.form.label.avatar' })}
            <Button style={{ marginLeft: '2rem' }} size="small" leftIcon={<UploadOutlined />}>
              {intl.formatMessage({ id: 'action.upload' })}
            </Button>
          </Title>
          <div className="wl-app-workspace-settings-form-avatar-wrapper">
            <Avatar size={128} icon={<UserOutlined />} />
          </div>
        </Col>
      </Row>
    </Form>
  );
}

/**
 * The invitations settings page.
 * @author Axel Nana <axel.nana@workerly.io>
 */
function InvitationSettings() {
  const intl = useIntl();
  const { data: _invitationsList, loading: loadingInvitations } = useInvitations();

  const { state: showOnlyUnaccepted, toggle: _toggleShowOnlyUnaccepted } = useBoolean();

  const invitations = React.useMemo(
    () => (showOnlyUnaccepted ? _invitationsList?.filter(item => !item.accepted) : _invitationsList),
    [showOnlyUnaccepted, _invitationsList],
  );

  const authResource = useCreation(() => new AuthResource(), []);
  const { run: _runAcceptInvitationRequest, loading: _acceptInvitationRequestLoading } = useRequest(
    authResource.acceptInvitation,
    {
      manual: true,
      onSuccess({ status }) {
        if (status) {
          message.success({
            content: intl.formatMessage({ id: 'workspaces.settings.invitations.message.accept_invitation_success' }),
          });
        }
      },
    },
  );

  /**
   * Accepts the given invitation.
   * @param {Invitation} invitation The invitation to accept.
   */
  const _onAcceptInvitation = React.useCallback(
    async (invitation: Invitation) => await _runAcceptInvitationRequest(invitation),
    [_runAcceptInvitationRequest],
  );

  return (
    <div className="wl-app-workspace-settings-container">
      <Row>
        <Col span={24}>
          <Title
            className="wl-app-workspace-settings-title"
            level={3}
            title={intl.formatMessage({ id: 'workspaces.settings.advanced.title' })}
          >
            {intl.formatMessage({ id: 'workspaces.settings.invitations.title' })}
          </Title>
        </Col>
        <Col span={24}>
          <ProList<Invitation>
            loading={loadingInvitations}
            toolBarRender={() => [
              <Space>
                <FormattedMessage tagName={Text} id="workspaces.settings.invitations.action.show_only_unaccepted" />
                <Switch key="0" checked={showOnlyUnaccepted} onChange={_toggleShowOnlyUnaccepted} />
              </Space>,
            ]}
            rowKey="pk"
            headerTitle={intl.formatMessage({ id: 'workspaces.settings.invitations.list_title' })}
            dataSource={invitations}
            showActions="hover"
            grid={{ gutter: 16, column: 2 }}
            pagination={{
              defaultPageSize: 30,
              showSizeChanger: true,
            }}
            metas={{
              title: {
                dataIndex: 'companyName',
              },
              avatar: {
                dataIndex: 'image',
              },
              description: {
                dataIndex: 'desc',
              },
              subTitle: {
                render: (dom, item) => {
                  return (
                    <Text type="secondary">
                      <small>{item.sent.toLocaleString(DateTime.DATETIME_MED)}</small>
                    </Text>
                  );
                },
              },
              actions: {
                render: (text, item) => [
                  item.accepted ? (
                    <Text type="success">
                      <Space>
                        <CheckCircleOutlined />
                        <FormattedMessage tagName="span" id="status.accepted" />
                      </Space>
                    </Text>
                  ) : (
                    <Space>
                      <Button
                        loading={_acceptInvitationRequestLoading}
                        size="small"
                        type="success"
                        leftIcon={<CheckCircleOutlined />}
                        onClick={() => _onAcceptInvitation(item)}
                      >
                        <FormattedMessage id="action.accept" />
                      </Button>
                      {/* <Button
                        loading={_acceptInvitationRequestLoading}
                        size="small"
                        type="danger"
                        leftIcon={<CloseCircleOutlined />}
                      >
                        <FormattedMessage id="action.reject" />
                      </Button> */}
                    </Space>
                  ),
                ],
              },
            }}
          />
        </Col>
      </Row>
    </div>
  );
}

/**
 * The advanced settings page.
 * @author Axel Nana <axel.nana@workerly.io>
 */
function AdvancedSettings() {
  const intl = useIntl();

  const [mergeAccountsModalVisible, setMergeAccountsModalVisible] = React.useState(false);

  const authResource = useCreation(() => new AuthResource(), []);
  const { data: mergeOperations, loading: loadingFetchMergeOperations, run: _fetchMergeOperations } = useRequest(
    authResource.mergeOperations,
    {
      formatResult: ({ data }) => {
        return data.status ? data.data?.data || [] : [];
      },
    },
  );
  const { loading: loadingCancelMergeOperations, run: _cancelMergeOperation } = useRequest(
    authResource.cancelMergeOperation,
    {
      manual: true,
      onSuccess: response => {
        if (response.status) {
          message.success({
            content: intl.formatMessage({ id: 'workspaces.settings.advanced.merge_accounts.message.merge_cancelled' }),
          });
        }
      },
    },
  );

  /**
   * Callbacks triggered when the merge accounts operation modal got closed.
   */
  const _onCloseMergeOperationModal = React.useCallback(() => _fetchMergeOperations(), [_fetchMergeOperations]);

  /**
   * Toggles the visibility of the merge accounts operation modal.
   */
  const _toggleMergeAccountsModalVisibility = React.useCallback(
    () => setMergeAccountsModalVisible(!mergeAccountsModalVisible),
    [mergeAccountsModalVisible],
  );

  /**
   * Returns the last pending merge accounts operation. Normally, it should
   * exists only one operation at time, so using this way is "safe".
   */
  const _lastPendingMergeAccountOperation = useCreation(() => mergeOperations?.find(op => op.status === 'PENDING'), [
    mergeOperations,
  ]);

  /**
   * Checks if the current account has pending merge accounts operations.
   */
  const _hasPendingMergeOperation = React.useCallback(() => !isUndefined(_lastPendingMergeAccountOperation), [
    _lastPendingMergeAccountOperation,
  ]);

  /**
   * Cancels the current pending merge accounts operation.
   */
  const _cancelLastPendingMergeOperation = React.useCallback(async () => {
    if (!isUndefined(_lastPendingMergeAccountOperation)) {
      await _cancelMergeOperation(_lastPendingMergeAccountOperation.key);
      await _fetchMergeOperations();
    }
  }, [_lastPendingMergeAccountOperation, _cancelMergeOperation]);

  return (
    <div className="wl-app-workspace-settings-container">
      <Row>
        <Col span={24}>
          <Title
            className="wl-app-workspace-settings-title"
            level={3}
            title={intl.formatMessage({ id: 'workspaces.settings.advanced.title' })}
          >
            {intl.formatMessage({ id: 'workspaces.settings.advanced.title' })}
          </Title>

          <Card
            className="wl-component-card"
            size="small"
            title={
              <IconText
                leftIcon={<MergeCellsOutlined />}
                text={intl.formatMessage({ id: 'workspaces.settings.advanced.merge_accounts.title' })}
              />
            }
            type="inner"
          >
            <Paragraph>
              {intl.formatMessage({ id: 'workspaces.settings.advanced.merge_accounts.description' })}
            </Paragraph>
            {_hasPendingMergeOperation() && (
              <Paragraph>
                <Alert
                  type="warning"
                  message={intl.formatMessage(
                    {
                      id: 'workspaces.settings.advanced.merge_accounts.pending_operation_message',
                    },
                    { b: (...chunks) => <b>{chunks}</b> },
                  )}
                />
              </Paragraph>
            )}
            <Button
              loading={loadingFetchMergeOperations}
              type={_hasPendingMergeOperation() ? 'success' : 'primary'}
              onClick={_toggleMergeAccountsModalVisibility}
              leftIcon={<PlayCircleOutlined />}
              style={{ marginRight: '1rem' }}
            >
              {intl.formatMessage({ id: _hasPendingMergeOperation() ? 'action.continue' : 'action.start' })}
            </Button>
            {_hasPendingMergeOperation() && (
              <Popconfirm
                title={intl.formatMessage({
                  id: 'workspaces.settings.advanced.merge_accounts.message.confirm_cancel_merge',
                })}
                okText={intl.formatMessage({ id: 'action.yes' })}
                cancelText={intl.formatMessage({ id: 'action.no' })}
                icon={<WarningFilled className="wl-component-icon-danger" />}
                okType="danger"
                onConfirm={_cancelLastPendingMergeOperation}
              >
                <Button
                  loading={loadingCancelMergeOperations || loadingFetchMergeOperations}
                  type="danger"
                  leftIcon={<StopOutlined />}
                >
                  {intl.formatMessage({ id: 'action.cancel' })}
                </Button>
              </Popconfirm>
            )}
          </Card>

          <Card
            className="wl-component-card"
            style={{ marginTop: '1.5rem' }}
            size="small"
            title={
              <IconText
                leftIcon={<FileDoneOutlined />}
                text={intl.formatMessage({ id: 'workspaces.settings.advanced.request_data.title' })}
              />
            }
            type="inner"
          >
            <p>{intl.formatMessage({ id: 'workspaces.settings.advanced.request_data.description' })}</p>
            <Button leftIcon={<PlayCircleOutlined />}>{intl.formatMessage({ id: 'action.start' })}</Button>
          </Card>

          <Card
            className="wl-component-card wl-component-card-danger"
            style={{ marginTop: '1.5rem' }}
            size="small"
            title={
              <IconText
                leftIcon={<DeleteOutlined />}
                text={intl.formatMessage({ id: 'workspaces.settings.advanced.delete_account.title' })}
              />
            }
            type="inner"
          >
            <p>{intl.formatMessage({ id: 'workspaces.settings.advanced.delete_account.description' })}</p>
            <Popconfirm
              title={intl.formatMessage({ id: 'workspaces.settings.advanced.delete_account.message.confirm_delete' })}
              okText={intl.formatMessage({ id: 'action.yes' })}
              cancelText={intl.formatMessage({ id: 'action.no' })}
              icon={<WarningFilled className="wl-component-icon-danger" />}
              okType="danger"
            >
              <Button type="danger" leftIcon={<DeleteOutlined />}>
                {intl.formatMessage({ id: 'action.delete' })}
              </Button>
            </Popconfirm>
          </Card>
        </Col>
      </Row>
      <MergeAccountsModal
        mergeOperation={_lastPendingMergeAccountOperation}
        visible={mergeAccountsModalVisible}
        onCancel={_toggleMergeAccountsModalVisibility}
        callback={_onCloseMergeOperationModal}
      />
    </div>
  );
}

/**
 * The security settings.
 * @author Axel Nana <axel.nana@workerly.io>
 */
function SecuritySettings() {
  const intl = useIntl();
  const { user } = useModel('user');

  const authResource = useCreation(() => new AuthResource(), []);
  const { run: _runResetPasswordRequest, loading: _resetPasswordRequestLoading } = useRequest(
    authResource.sendResetPasswordLink,
    {
      manual: true,
      onSuccess: ({ status }) => {
        if (status) {
          message.success({
            content: intl.formatMessage(
              { id: 'workspaces.settings.security.reset_password.message.reset_password_success' },
              { email: <b>{user.email}</b> },
            ),
          });
        }
      },
    },
  );

  /**
   * Starts the reset password process by sending a link by email.
   */
  const _onResetPasswordButtonClick = React.useCallback(async () => {
    if (!isUndefined(user.email)) {
      await _runResetPasswordRequest(user.email);
    }
  }, [user, _runResetPasswordRequest]);

  return (
    <div className="wl-app-workspace-settings-container">
      <Row>
        <Col span={24}>
          <Title
            className="wl-app-workspace-settings-title"
            level={3}
            title={intl.formatMessage({ id: 'workspaces.settings.security.title' })}
          >
            {intl.formatMessage({ id: 'workspaces.settings.security.title' })}
          </Title>

          <Card
            className="wl-component-card"
            size="small"
            title={
              <IconText
                leftIcon={<SyncOutlined />}
                text={intl.formatMessage({ id: 'workspaces.settings.security.reset_password.title' })}
              />
            }
            type="inner"
          >
            <p>{intl.formatMessage({ id: 'workspaces.settings.security.reset_password.description' })}</p>
            <Button
              loading={_resetPasswordRequestLoading}
              onClick={_onResetPasswordButtonClick}
              leftIcon={<PlayCircleOutlined />}
            >
              {intl.formatMessage({ id: 'action.start' })}
            </Button>
          </Card>

          <Card
            className="wl-component-card"
            style={{ marginTop: '1.5rem' }}
            size="small"
            title={
              <IconText
                leftIcon={<SafetyOutlined />}
                text={intl.formatMessage({ id: 'workspaces.settings.security.2fa_auth.title' })}
              />
            }
            type="inner"
          >
            <p>{intl.formatMessage({ id: 'workspaces.settings.security.2fa_auth.description' })}</p>
            <Button leftIcon={<PlayCircleOutlined />}>{intl.formatMessage({ id: 'action.start' })}</Button>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

/**
 * The settings page. Each settings are grouped and each groups are separated in tabs.
 * @author Axel Nana <axel.nana@workerly.io>
 */
export default () => {
  const intl = useIntl();

  return (
    <React.Fragment>
      <PageHeader
        title={intl.formatMessage({ id: 'workspaces.settings.title' })}
        subTitle={intl.formatMessage({ id: 'workspaces.settings.subtitle' })}
        ghost
      />
      <Tabs className="wl-component-tab wl-component-tab-theme-dark" defaultActiveKey="1" tabPosition="left">
        <TabPane
          forceRender
          tab={
            <span>
              <UserOutlined />
              <FormattedMessage id="workspaces.settings.account.menu" />
            </span>
          }
          key="1"
        >
          <AccountSettings />
        </TabPane>
        <TabPane
          forceRender
          tab={
            <span>
              <UsergroupAddOutlined />
              <FormattedMessage id="workspaces.settings.invitations.menu" />
            </span>
          }
          key="2"
        >
          <InvitationSettings />
        </TabPane>
        <TabPane
          forceRender
          tab={
            <span>
              <LockOutlined />
              <FormattedMessage id="workspaces.settings.security.menu" />
            </span>
          }
          key="3"
        >
          <SecuritySettings />
        </TabPane>
        <TabPane
          forceRender
          tab={
            <span>
              <ToolOutlined />
              <FormattedMessage id="workspaces.settings.advanced.menu" />
            </span>
          }
          key="4"
        >
          <AdvancedSettings />
        </TabPane>
      </Tabs>
    </React.Fragment>
  );
};
