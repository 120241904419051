import { AuthResource } from '@/api/auth';
import Cookies from 'universal-cookie';

/**
 * Sign out the currently logged in user.
 * @author Axel Nana <axel.nana@workerly.io>
 */
export const logout = () => {
  // TODO: make a api call to invalidate the access_token
  const cookies = new Cookies();
  for (const name in cookies.getAll()) {
    cookies.remove(name, { path: '/' });
  }

  window.location.reload();
};

/**
 * Refresh the current access token.
 * @author Axel Nana <Axel.nana@workerly.io>
 */
export const refreshToken = async () => {
  const cookies = new Cookies();
  const [, token] = getCurrentUser();
  const auth = new AuthResource();
  const { data: response } = await auth.refresh(token.refresh);
  if (response.status && response.data !== undefined) {
    token.access = response.data.access;
    cookies.set('wl-user-token', token);
  } else {
    logout();
  }
};

/**
 * Returns the current user.
 * @author Axel Nana <axel.nana@workerly.io>
 */
export const getCurrentUser = () => {
  const cookies = new Cookies();
  return [cookies.get('wl-user-data'), cookies.get('wl-user-token')];
};

/**
 * Returns the current tenant.
 * @author Axel Nana <axel.nana@workerly.io>
 */
export const getCurrentTenant = () => {
  const cookies = new Cookies();
  return cookies.get('wl-workspace-tenant');
};
