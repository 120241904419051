/**
 * Defines the constructor of a data model.
 * Used in place where we don't know the real type of the model to use.
 * @author Axel Nana <axel.nana@workerly.io>
 */
export interface DataModelConstructorOf<T = DataModel> {
  new (data: any): T;
  new (): T;
}

/**
 * Defines the constructor of a data model.
 * Used in place where we don't know the real type of the model to use.
 * @author Axel Nana <axel.nana@workerly.io>
 */
export interface DataModelConstructor<T = Record<string, any>> {
  new (data: T): DataModel<T>;
  new (): DataModel<T>;
}

/**
 * The base class of each data models.
 * @author Axel Nana <axel.nana@workerly.io>
 */
export abstract class DataModel<T = Record<string, any>> {
  /**
   * The raw value of the model, as coming from the API.
   */
  protected _rawData: T;

  /**
   * Gets the unique identifier of this model.
   */
  public abstract get pk(): string | undefined;

  /**
   * Creates a data model from API data.
   * @author Axel Nana <axel.nana@workerly.io>
   * @param data The raw data coming from the API.
   */
  constructor(data: T = <T>{}) {
    this._rawData = data;

    this.toJson = this.toJson.bind(this);
  }

  /**
   * Returns the JSON version of this DataModel, suitable to be used
   * in requests.
   * @author Axel Nana <axel.nana@workerly.io>
   * @returns {T}
   */
  public toJson(): T {
    return this._rawData;
  }

  /**
   * Finds the entity related to a field of this data model.
   * @author Axel Nana <axel.nana@workerly.io>
   * @abstract
   * @param key The of the raw data to use when searching the related entity.
   * @returns {Promise<DataModel | null>} The related entity.
   */
  public abstract getRelated(key: keyof T): Promise<DataModel | null | DataModel[]>;
}
