import { createFromIconfontCN } from '@ant-design/icons';

/**
 * Create an icon font component from the Workerly icon pack
 * created at iconfont.cn
 * @author Axel Nana <axel.nana@workerly.io>
 */
export const IconFont = createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/font_2346547_7bnymb4bma2.js',
});
