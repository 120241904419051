import { Resource } from '@/api/resource';

/**
 * The Break raw data interpreted by the API.
 * @author Axel Nana <axel.nana@workerly.io>
 */
export interface BreakRequestData {
  pk?: string;
  name: string;
  start: string;
  end: string;
  shift: string;
  early_start?: number;
  late_start?: number;
  early_end?: number;
  late_end?: number;
}

/**
 * The Break raw data sent by the API.
 * @author Axel Nana <axel.nana@workerly.io>
 */
export interface BreakResponseData extends BreakRequestData {
  pk: string;
  early_start: number;
  late_start: number;
  early_end: number;
  late_end: number;
}

/**
 * A clock break from attendance
 * @author Adonis SIMO <simo.adonis@workerly.io>
 */
export interface ClockBreak {
  break_rel: {
    pk: string;
    name: string;
  };
  attendance: string;
  clock_in: string;
  pk: string;
  early_clock_in: boolean;
  late_clock_in: boolean;
  emp_clock_out: string;
  emp_clock_in: string;
  is_active: boolean;
  clock_out: string;
  early_clock_out: boolean;
  late_clock_out: boolean;
  temperature: number;
  temperature_record_hour: string;
  temperature_out: number;
  temperature_record_hour_out: string;
  remote: boolean;
}

/**
 * The break resource manager.
 * @author Axel Nana <axel.nana@workerly.io>
 */
export class BreakResource extends Resource<BreakRequestData, BreakResponseData> {
  constructor() {
    super('/break');
  }
}
