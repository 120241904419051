import React from 'react';

import { Col, Row } from 'antd';
import { ProColumns } from '@ant-design/pro-table';
import { usePersistFn } from '@umijs/hooks';

import { DataTable, Layout, TextDescription } from '@/components';
import { Position } from '@/data';
import { PositionResource } from '@/api/position';

const { WLyPageContainer } = Layout;

const dataTableColumns: ProColumns<Position>[] = [
  {
    title: 'Name',
    dataIndex: 'name',
    copyable: true,
    ellipsis: true,
    tooltip: "Job Title's  Name",
  },
  {
    title: 'Code',
    dataIndex: 'code',
    copyable: true,
    ellipsis: true,
    tooltip: "Job Title's  Code",
  },
  {
    title: 'Job Description',
    dataIndex: 'description',
    copyable: true,
    ellipsis: true,
    tooltip: 'Job Description',
  },
  {
    title: 'Department',
    dataIndex: 'departmentName',
    copyable: true,
    ellipsis: true,
    tooltip: "Job Title's  Name",
  },
];

/**
 * @author Adonis SIMO <simo.adonis@workerly.io>
 */
export default () => {
  const drawerContentRenderer = usePersistFn((entity: Position) => (
    <Row gutter={[16, 16]}>
      <Col span={12}>
        <TextDescription title="Name">{entity.name}</TextDescription>
      </Col>
      <Col span={12}>
        <TextDescription title="Code">{entity.code}</TextDescription>
      </Col>
      <Col span={12}>
        <TextDescription title="Department">{entity.departmentName}</TextDescription>
      </Col>
      <Col span={24}>
        <TextDescription title="Description">{entity.description}</TextDescription>
      </Col>
    </Row>
  ));

  return (
    <WLyPageContainer>
      <DataTable<Position, PositionResource>
        model={Position}
        resource={PositionResource}
        columns={dataTableColumns}
        renderDrawerContent={drawerContentRenderer}
        drawerProps={{
          title: 'Position details',
          width: 420,
        }}
      />
    </WLyPageContainer>
  );
};
