import React from 'react';
import { Redirect, useModel, Link, useIntl, IRouteComponentProps } from 'umi';
import { Layout, Row, Col, Card, Form, Input, Checkbox, Spin } from 'antd';
import { LockOutlined, MailOutlined, LoadingOutlined } from '@ant-design/icons';
import _, { isUndefined } from 'lodash';

import WorkerLyLogoHorizontal from '@/assets/images/brand/logo_horizontal.png';
import { AppRoutes } from '@/utils/routes';
import { Button } from '@/components';

import '@/pages/auth/auth.less';

const { Content } = Layout;

export default ({
  location: {
    query: { user_id, timestamp, signature },
  },
}: IRouteComponentProps) => {
  const intl = useIntl();
  const { loading, resetPassword, isValidState } = useModel('user');

  if (isUndefined(user_id) || isUndefined(timestamp) || isUndefined(signature)) {
    return <Redirect to={AppRoutes.accountLogin.path} />;
  }

  return (
    <Layout className="wl-auth-page wl-fullscreen" hasSider={false}>
      <Content className="wl-fullscreen">
        <Row justify="space-around" align="middle" className="wl-fullscreen">
          <Col xs={22} sm={20} md={10} lg={6}>
            <Card bordered className="wl-auth-page-card-form">
              <Spin indicator={<LoadingOutlined />} spinning={loading} tip="Please wait...">
                <Row justify="space-around">
                  <Col>
                    <img className="wl-auth-page-logo" src={WorkerLyLogoHorizontal} />
                  </Col>
                </Row>
                <Row justify="space-around">
                  <Col span={24}>
                    <p className="wl-auth-page-intro-text">
                      {intl.formatMessage({ id: 'auth.reset_password.page.form.intro' })}
                    </p>
                  </Col>
                  <Col span={24}>
                    <Form
                      name="reset_password"
                      layout="vertical"
                      className="wl-auth-page-form"
                      initialValues={{ remember: true }}
                      onFinish={values =>
                        resetPassword({ ...values, user_id, timestamp: parseInt(timestamp as string), signature })
                      }
                    >
                      <Form.Item
                        labelCol={{ span: 24 }}
                        label={intl.formatMessage({ id: 'auth.reset_password.page.form.label.password' })}
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: intl.formatMessage({ id: 'auth.reset_password.errors.no_password' }),
                          },
                        ]}
                      >
                        <Input
                          type="password"
                          placeholder={intl.formatMessage({ id: 'auth.reset_password.page.form.label.password' })}
                        />
                      </Form.Item>

                      <Form.Item className="wl-auth-page-form-login-wrapper">
                        <Button type="primary" htmlType="submit" className="wl-auth-page-form-button-login">
                          {intl.formatMessage({ id: 'action.confirm' })}
                        </Button>
                        {isValidState
                          ? intl.formatMessage(
                              { id: 'auth.reset_password.page.form.action.workspace' },
                              { a: (...chunks) => <Link to={AppRoutes.accountWorkspaces.path}>{chunks}</Link> },
                            )
                          : intl.formatMessage(
                              { id: 'auth.reset_password.page.form.action.login' },
                              { a: (...chunks) => <Link to={AppRoutes.accountLogin.path}>{chunks}</Link> },
                            )}
                      </Form.Item>
                    </Form>
                  </Col>
                </Row>
              </Spin>
            </Card>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};
