import { DataModel } from '@/data/DataModel';
import { AuthResponseUserData, AuthResponseUserDataEmailAddress } from '@/api/auth';

export class User extends DataModel<AuthResponseUserData> {
  public get pk(): string | undefined {
    return this._rawData.id;
  }

  public set pk(value: string | undefined) {
    this._rawData.id = value;
  }

  public get name(): string {
    return `${this._rawData.first_name} ${this._rawData.last_name}`;
  }

  public get first_name(): string | undefined {
    return this._rawData.first_name;
  }

  public set first_name(value: string | undefined) {
    this._rawData.first_name = value;
  }

  public get last_name(): string | undefined {
    return this._rawData.last_name;
  }

  public set last_name(value: string | undefined) {
    this._rawData.last_name = value;
  }

  public get email(): string | undefined {
    return this._rawData.email;
  }

  public set email(value: string | undefined) {
    this._rawData.email = value;
  }

  public get is_active(): boolean | undefined {
    return this._rawData.is_active;
  }

  public get is_staff(): boolean | undefined {
    return this._rawData.is_staff;
  }

  public get is_superuser(): boolean | undefined {
    return this._rawData.is_superuser;
  }

  public get companies(): any[] | undefined {
    return this._rawData.companies;
  }

  public get permissions(): any | undefined {
    return this._rawData.permissions;
  }

  public get emailAddressesSet(): AuthResponseUserDataEmailAddress[] {
    return this._rawData.emailaddress_set || [];
  }

  public getRelated(key: keyof AuthResponseUserData): Promise<DataModel | null> {
    return Promise.resolve(null);
  }
}
