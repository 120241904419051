import { RateResponseData } from '@/api/rate';
import { DataModel } from '@/data/DataModel';

/**
 * The Rate data model interpreted by the app.
 * @author Axel Nana <axel.nana@workerly.io>
 */
export class Rate extends DataModel<RateResponseData> {
  public get pk(): string {
    return this._rawData.pk;
  }

  public get country(): string {
    return this._rawData.country;
  }

  public get state(): string {
    return this._rawData.state;
  }

  public get code_state(): string {
    return this._rawData.code_state;
  }

  public get rate(): number {
    return this._rawData.rate;
  }

  public async getRelated(key: keyof RateResponseData): Promise<DataModel | null> {
    return null;
  }
}
