import { AppRoutes } from '@/utils/routes';

/**
 * Creates a locale translation for a menu item and its page title at the same time.
 * @author Axel Nana <axel.nana@workerly.io>
 * @param {string} code The route name.
 * @param {string} menu The translation to use in the menu.
 * @param {string | undefined} title The translation to use in the title. If not defined, defaults to `menu`.
 * @returns The generated menu and title entries for the given `code`.
 */
const makePageMenuAndTitle = (code: string, menu: string, title?: string) => {
  return { [code]: menu, [`menu.${code}`]: menu, [`title.${code}`]: `${title || menu} - Workerly` };
};

export default {
  // Dashboard menu
  ...makePageMenuAndTitle(AppRoutes.dashboard.name, 'Analytics'),

  // Employees menu
  ...makePageMenuAndTitle(AppRoutes.employees.name, 'Employees'),

  // attendance menu
  ...makePageMenuAndTitle(AppRoutes.attendances.name, 'Attendance'),

  // global payroll menu
  ...makePageMenuAndTitle(AppRoutes.payrolls.name, 'Payrolls'),

  // work shift menu
  ...makePageMenuAndTitle(AppRoutes.shifts.name, 'Work Shifts'),

  // timeoff approval menu
  ...makePageMenuAndTitle(AppRoutes.timeOffApprovals.name, 'Time-Off Approval'),

  // global timesheet menu
  ...makePageMenuAndTitle(AppRoutes.timesheets.name, 'Timesheet'),

  // Company settings menu
  ...makePageMenuAndTitle(AppRoutes.companySettings.name, 'Settings'),

  companySettingsProfile: 'Profile',
  'menu.company.settings.profile': 'Profile',
  'title.company.settings.profile': 'Settings - Profile - Workerly',

  companySettingsLocations: 'Locations',
  'menu.company.settings.locations': 'Locations',
  'title.company.settings.locations': 'Settings - Locations - Workerly',

  companySettingsDepartments: 'Departments',
  'menu.company.settings.departments': 'Departments',
  'title.company.settings.departments': 'Settings - Departments - Workerly',

  companySettingsPositions: 'Job Titles',
  'menu.company.settings.positions': 'Job Titles',
  'title.company.settings.positions': 'Settings - Job Titles - Workerly',

  companySettingsDevices: 'Punch Devices',
  'menu.company.settings.devices': 'Punch Devices',
  'title.company.settings.devices': 'Settings - Punch Devices - Workerly',

  companySettingsPayrolls: 'Payroll Cycles',
  'menu.company.settings.payrolls': 'Payroll Cycles',
  'title.company.settings.payrolls': 'Settings - Payroll Cycles - Workerly',

  companySettingsWorkWeeks: 'Work Weeks',
  'menu.company.settings.workweeks': 'Work Weeks',
  'title.company.settings.workweeks': 'Settings - Work Week - Workerly',

  companySettingsOvertime: 'Overtime Policies',
  'menu.company.settings.overtimes': 'Overtime Policies',
  'title.company.settings.overtimes': 'Settings - Overtime Policies - Workerly',

  companySettingsPTOPolicy: 'Time-Off Policies',
  'menu.company.settings.timeoffpolicys': 'Time Off Policies',
  'title.company.settings.timeoffpolicys': 'Settings - Work Week - Workerly',

  companySettingsHolidays: "Company's Holidays",
  'menu.company.settings.holidays': 'Holidays',
  'title.company.settings.holidays': 'Settings - Holidays - Workerly',

  companySettingsPermissions: 'Roles & Permissions',
  'menu.company.settings.permissions': 'Roles',
  'title.company.settings.permissions': 'Settings - Roles And Permissions - Workerly',

  companySettingsHRAdvisory: 'HR Advisory',
  'menu.company.settings.hradvisory': 'HR Advisory',
  'title.company.settings.hradvisory': 'Settings - HR Advisory & Compliance - Workerly',
};
