import React from 'react';
import { SubPeriodData } from '@/api/timesheet';
import { Col, Collapse, Row, Table } from 'antd';
import { TextDescription } from '@/components/TextDescription';
import Text from 'antd/es/typography/Text';
import Title from 'antd/es/typography/Title';
import ActivityHistory from '@/components/ActivityHistory';
import { DateTime } from 'luxon';

const { Panel } = Collapse;

/**
 * Subperiod Component props, contain an pbject tomap subperiod object coming from
 * timesheet and payroll
 * @author Adoni SIMO <simo.adonis@workerly.io>
 */
export interface SubPeriodProps {
  /**
   * SubPeriod object
   * @author Adoni SIMO <simo.adonis@workerly.io>
   */
  subPeriod: SubPeriodData;
}

/**
 * Display the sub-period compenent for payroll and timesheet view
 * @param props
 * @constructor
 * @author Adonis SIMO <simo.adonis@workerly.io>
 */
export default function SubPeriod(props: SubPeriodProps) {
  const { subPeriod } = props;
  return (
    <Collapse defaultActiveKey={['1']} bordered={false}>
      <Panel header="Sub Period" key="1" style={{ backgroundColor: 'white' }}>
        <Row gutter={[16, 32]}>
          <Col span={12}>
            <TextDescription title={'Period Start'}>
              <Text>{DateTime.fromISO(subPeriod?.start || '').toLocaleString(DateTime.DATE_MED)}</Text>
            </TextDescription>
          </Col>
          <Col span={12}>
            <TextDescription title={'Period End'}>
              <Text>{DateTime.fromISO(subPeriod?.end || '').toLocaleString(DateTime.DATE_MED)}</Text>
            </TextDescription>
          </Col>

          <Col span={12}>
            <TextDescription title={'Days Worked'}>
              <Text>{subPeriod?.period_work_done.effective_work_days} day(s)</Text>
            </TextDescription>
          </Col>
          <Col span={12}>
            <TextDescription title={'Number of Work Days'}>
              <Text> {subPeriod?.period_work_done.number_of_work_days} day(s)</Text>
            </TextDescription>
          </Col>

          {subPeriod?.period_work_done.weekly_activity_history.map((h, i) => (
            <Col key={i} span={24}>
              <ActivityHistory activity={h} />
            </Col>
          ))}
        </Row>
      </Panel>
    </Collapse>
  );
}
