import { Redirect, useModel } from 'umi';
import { useMount } from '@umijs/hooks';

import { AppRoutes } from '@/utils/routes';

export default () => {
  const { isValidState, logOut } = useModel('user');

  // Directly log the user out
  useMount(() => {
    logOut();
  });

  if (!isValidState) {
    return <Redirect to={AppRoutes.accountLogin.path} />;
  }

  return null;
};
