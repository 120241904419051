export default {
  'workspaces.welcome': 'Hello, {name}',

  'workspaces.menu.manager': 'My Account',
  'workspaces.menu.settings': 'Settings',
  'workspaces.menu.logout': 'Logout',

  'workspaces.message.empty': "It's so empty here... Create your first company now!",
  'workspaces.message.loading_companies': 'Loading your workspaces...',
  'workspaces.message.workspace_switch_error':
    'Unable to switch to the selected workspace. Please try again, if the error persist, contact the Workerly support.',
  'workspaces.message.workspace_switch_success': 'Successfully switched to workspace {name}.',
  'workspaces.message.confirm_close_workspace': 'This will close the current workspace, are you sure to continue?',
  'workspaces.message.confirm_switch_workspace.title': 'Switch workspace?',
  'workspaces.message.confirm_switch_workspace.content_from_workspace':
    'This will close the current workspace to switch on {name}. Do you want to continue?',
  'workspaces.message.confirm_switch_workspace.content_from_account':
    'This will open the workspace {name}. Do you want to continue?',

  'workspaces.manager.title': 'Your Companies',
  'workspaces.manager.subtitle': 'Manage the companies where you belong to',
  'workspaces.settings.title': 'Settings',
  'workspaces.settings.subtitle': 'Edit your personal account settings',

  'workspaces.settings.account.menu': 'Account',
  'workspaces.settings.account.title': 'Account Settings',
  'workspaces.settings.account.form.label.first_name': 'First Name',
  'workspaces.settings.account.form.label.last_name': 'Last Name',
  'workspaces.settings.account.form.label.email_addresses': 'Email Addresses',
  'workspaces.settings.account.form.label.email': 'Email',
  'workspaces.settings.account.form.label.avatar': 'Avatar',
  'workspaces.settings.account.form.message.no_first_name': 'Please enter your first name.',
  'workspaces.settings.account.form.message.no_last_name': 'Please enter your last name.',
  'workspaces.settings.account.form.message.no_email': 'Please enter an email address, or remove this field.',

  'workspaces.settings.advanced.menu': 'Advanced',
  'workspaces.settings.advanced.title': 'Advanced Settings',
  'workspaces.settings.advanced.merge_accounts.title': 'Merge Accounts',
  'workspaces.settings.advanced.merge_accounts.description':
    'This section helps you to merge one or more Workerly accounts into another (the master account).',
  'workspaces.settings.advanced.merge_accounts.pending_operation_message':
    'A merge operation is pending on this account. You can continue that operation by clicking the <b>Continue</b> button. If you want to cancel that operation instead, click the <b>Cancel</b> button.',
  'workspaces.settings.advanced.request_data.title': 'Request Data',
  'workspaces.settings.advanced.request_data.description':
    'This section helps you to get a report of your data. The report will be available as a zip file under the next 07 business days after the request succeed.',
  'workspaces.settings.advanced.delete_account.title': 'Delete Account',
  'workspaces.settings.advanced.delete_account.description':
    'This section helps you to delete your account and all related data. An account deletion request will disable your account for the next 07 business days and your data will be downloadable as a zip file. After 30 days your account will be fully deleted and archived, and you will be able to request that archive. After 90 days, archives will be permanently deleted from our servers.',
  'workspaces.settings.advanced.delete_account.message.confirm_delete': 'Do you really want to delete this account?',

  'workspaces.settings.advanced.merge_accounts.steps.setup.title': 'Setup',
  'workspaces.settings.advanced.merge_accounts.steps.setup.intro':
    'Welcome to the merge account wizard. This process will help you to connect one or more Workerly accounts (slave accounts) into only one (the master account).',
  'workspaces.settings.advanced.merge_accounts.steps.setup.guide_intro': 'Which kind of account this one is?',
  'workspaces.settings.advanced.merge_accounts.steps.setup.guide_master_account':
    'The master account will receive the data of every merged slave accounts. This account will remain alive after the merge operation succeed.',
  'workspaces.settings.advanced.merge_accounts.steps.setup.guide_slave_account':
    'The slave account will send all its data to the master account. This account will disabled after the merge operation succeed.',
  'workspaces.settings.advanced.merge_accounts.steps.setup.master_account_button': 'Master Account',
  'workspaces.settings.advanced.merge_accounts.steps.setup.slave_account_button': 'Slave Account',

  'workspaces.settings.advanced.merge_accounts.steps.merge.title': 'Merge',
  'workspaces.settings.advanced.merge_accounts.steps.merge.master_account_intro':
    'The merge account operation key for this master account has been successfully generated. Please copy this key and paste it on each slave account you want to merge into this one before to launch the merge process.',
  'workspaces.settings.advanced.merge_accounts.steps.merge.slave_account_intro':
    'Enter down here the merge account operation key generated from the master account and to add this account as a slave account.',
  'workspaces.settings.advanced.merge_accounts.steps.merge.input_label': 'Merge Operation Key',
  'workspaces.settings.advanced.merge_accounts.steps.merge.merge_account_button': 'Launch Merge',
  'workspaces.settings.advanced.merge_accounts.steps.merge.add_account_button': 'Add Slave Account',

  'workspaces.settings.advanced.merge_accounts.steps.finish.title': 'Finish',
  'workspaces.settings.advanced.merge_accounts.steps.finish.result_title': 'Successfully merged accounts!',
  'workspaces.settings.advanced.merge_accounts.steps.finish.result_message_master':
    'The application will now reload to refresh your account data.',
  'workspaces.settings.advanced.merge_accounts.steps.finish.result_message_slave':
    'The data of this account will be moved to the master account at the end of the merge.',

  'workspaces.settings.advanced.merge_accounts.action.continue_merge_later': 'Continue later',

  'workspaces.settings.advanced.merge_accounts.message.merge_cancelled': 'Merge account operation cancelled.',
  'workspaces.settings.advanced.merge_accounts.message.confirm_cancel_merge':
    'Do you really want to cancel this operation?',
  'workspaces.settings.advanced.merge_accounts.message.confirm_cancel_merge.title': 'Quit the merge account wizard?',
  'workspaces.settings.advanced.merge_accounts.message.confirm_cancel_merge.content':
    'The merge account operation is not yet completed. Do you really want to quit the wizard now? Quitting the wizard will cancel the merge operation. Instead, you can close this modal and continue later.',

  'workspaces.settings.security.menu': 'Security',
  'workspaces.settings.security.title': 'Security Settings',
  'workspaces.settings.security.reset_password.title': 'Reset Password',
  'workspaces.settings.security.reset_password.description':
    'This process will help you to change your current password to a new one.',
  'workspaces.settings.security.reset_password.message.reset_password_success':
    'An email was sent to {email}. Please check your inbox to reset your password.',
  'workspaces.settings.security.2fa_auth.title': 'Two Factors Authentication (2FA)',
  'workspaces.settings.security.2fa_auth.description':
    'This process will help you to add a second layer of security to your account though a two factors authentication.',

  'workspaces.settings.invitations.menu': 'Invitations',
  'workspaces.settings.invitations.title': 'Invitations',
  'workspaces.settings.invitations.message.accept_invitation_success': 'Invitation accepted.',
  'workspaces.settings.invitations.list_title': 'Browse the list of your invitations to companies.',
  'workspaces.settings.invitations.action.accept_all': 'Accept all',
  'workspaces.settings.invitations.action.accept_selected': 'Accept selected',
  'workspaces.settings.invitations.action.show_only_unaccepted': 'Only show not accepted invitations',

  'workspaces.action.new_company': 'New Company',

  'workspaces.form.create_company.title': 'Create a new company',
  'workspaces.form.create_company.label.company_name': "What's your Company name?",
  'workspaces.form.create_company.label.company_headquarter': 'Where is the company headquartered?',
  'workspaces.form.create_company.label.company_industries': 'Which Industry best describes your business?',
  'workspaces.form.create_company.label.company_staffing': 'Is it a staffing company?',

  'workspaces.form.create_company.placeholder.company_industries': 'Select some industries',

  'workspaces.form.create_company.message.no_company_name': 'The company name is required.',
  'workspaces.form.create_company.message.no_company_industry': 'Please select at least one company industry.',
  'workspaces.form.create_company.message.company_created_success': 'Successfully created the company {name}.',
  'workspaces.form.create_company.message.no_company_type': 'Please select if this company is a staffing one or not.',
  'workspaces.form.create_company.message.no_company_address': 'Please fill the full address of your company.',
  'workspaces.form.create_company.message.loading_text':
    'We are creating a Workerly workspace for your company. This may take a while, please be patient...',
};
