import { DataModel } from '@/data/DataModel';
import { PermissionData } from '@/api/permission';

/**
 * @author Adonis SIMO <simo.adonis@workerly.io>
 */
export class Permission extends DataModel<PermissionData> {
  public getRelated(key: keyof PermissionData): Promise<DataModel | DataModel[] | null> {
    throw new Error('Method not implemented.');
  }

  public get pk(): number {
    return this._rawData.pk;
  }

  /**
   * code name
   */
  public get codename(): string {
    return this._rawData.codename;
  }

  /**
   * groupname
   */
  public get groupname(): string {
    return this._rawData.groupname;
  }

  /**
   * name
   */
  public get name(): string {
    return this._rawData.name;
  }
}
