import { DataModel } from '@/data/DataModel';
import { DeviceResponseData } from '@/api/device';
import { ConstrainedDataModel } from '@/data/ConstrainedDataModel';

export class Device extends ConstrainedDataModel<DeviceResponseData> {
  public getRelated(key: keyof DeviceResponseData): Promise<DataModel | DataModel[] | null> {
    throw new Error('Method not implemented.');
  }

  public set pk(value: string) {
    this._rawData.pk = value;
  }

  public get pk(): string {
    return this._rawData.pk;
  }

  public set name(value: string) {
    this._rawData.name = value;
  }

  public get name(): string {
    return this._rawData.name;
  }

  public set location(value: string) {
    this._rawData.location = value;
  }

  public get location(): string {
    return this._rawData.location;
  }

  public set uniqueId(value: string) {
    this._rawData.unique_id = value;
  }

  public get uniqueId(): string {
    return this._rawData.unique_id;
  }

  public get locationName(): string {
    return this._rawData.location_name;
  }
}
