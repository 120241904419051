import React from 'react';
import { Col, Table } from 'antd';
import { WeeklyActivityHistoryData } from '@/api/timesheet';
import Text from 'antd/es/typography/Text';

/**
 * Represent props for activity history, contain a history object from
 * timesheet or payroll
 * @author Adonis SIMO <simo.adonis@workerly.io>
 */
export interface ActivityProps {
  /**
   * Weekly history object
   * @author Adonis SIMO <simo.adonis@workerly.io>
   */
  activity: WeeklyActivityHistoryData;
}

export default function ActivityHistory(props: ActivityProps) {
  const { activity } = props;
  console.log(activity, "l'activite ici ");
  const buildDataSource = React.useCallback(
    (p: WeeklyActivityHistoryData) => {
      // TODO: improve this to contain dynamic data(overtime milestone, holiday, etc)

      const regularPay = {
        label: 'Regular',
        hours: p.regular_work,
        payRate: p.hourly_pay,
        earning: p.weekly_pay,
      };

      const overtimePay = {
        label: 'Weekly Overtime',
        hours: p.weekly_overtime_work,
        payRate: p.pay_factor,
        earning: p.overtime_salary_earned,
      };

      const total = {
        label: 'Total',
        hours: '',
        payRate: '',
        earning: p.regular_salary_earned,
      };
      return [regularPay, overtimePay, total];
    },
    [activity],
  );

  // const dataSource = buildDataSource(activity)

  const columns = [
    {
      title: '',
      dataIndex: 'label',
      key: 'label',
    },
    {
      title: 'Work Hours',
      dataIndex: 'hours',
      key: 'hours',
    },
    {
      title: 'Pay Rate',
      dataIndex: 'payRate',
      key: 'payRate',
      render: (t: number) => `$ ${t}`,
    },
    {
      title: 'Earnings',
      dataIndex: 'earning',
      key: 'earning',
      render: (t: number) => `$ ${t}`,
    },
  ];

  return (
    <>
      <Text>Activity History</Text>
      <Table pagination={false} dataSource={buildDataSource(activity)} columns={columns} />
    </>
  );
}
