import { Resource } from '@/api/resource';

/**
 * Position object sent to API.
 * @author Adonis SIMO <simo.adonis@workely.io>
 */
export interface PositionRequestData {
  pk?: string;
  name: string;
  description: string;
  department: string;
  code: string;
  department_head: boolean;
}

/**
 * Position object returned by API.
 * @author Adonis SIMO <simo.adonis@workely.io>
 */
export interface PositionResponseData extends PositionRequestData {
  pk: string;
  name: string;
  description: string;
  department: string;
  department_name: string;
  code: string;
  department_head: boolean;
}

/**
 * The association model between position and the file of an employee
 * this is not create in frontend so we just use this interface
 * to properly interpret data.
 * @author Adonis SIMO <simo.adonis@workerly.io>
 */
export interface FilePositionData {
  id: string;
  active: boolean;
  mission?: string;
  start: string;
  end?: string;
  file: string;
  name: string;
  position: string;
  //TODO: add this to related in data model
}

/**
 * Position Api ressource
 * @author Adonis SIMO <simo.adonis@workerly.io>
 */
export class PositionResource extends Resource<PositionRequestData, PositionResponseData> {
  constructor() {
    super('/positions');
  }
}
