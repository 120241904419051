import React from 'react';
import { DateTime } from 'luxon';
import { ProColumns } from '@ant-design/pro-table';
import { Col, Row } from 'antd';
import { usePersistFn } from '@umijs/hooks';

import { DataTable, TextDescription, Layout } from '@/components';
import { useTranslation } from '@/utils/hooks';
import { HolidayResource } from '@/api/holiday';
import { Holiday } from '@/data';

const { WLyPageContainer } = Layout;

const dataTableColumns: ProColumns<Holiday>[] = [
  {
    title: 'Name',
    dataIndex: 'name',
    copyable: true,
    ellipsis: true,
  },
  {
    title: 'Date',
    dataIndex: 'date',
    copyable: false,
    ellipsis: true,
    renderText: (date: any) => (date ? date.toLocaleString(DateTime.DATETIME_MED) : ''),
  },
];

/**
 *
 * @author Adonis SIMO <simo.adonis@workerly.io>
 */
// TODO ensure the logic of this module with @ttgedeon
export default () => {
  const _ = useTranslation();

  const drawerContentRenderer = usePersistFn((entity: Holiday) => (
    <Row gutter={[16, 24]}>
      <Col span={12}>
        <TextDescription title="Name">{entity.name}</TextDescription>
      </Col>
      <Col span={12}>
        <TextDescription title="Date">{entity.date.toLocaleString(DateTime.DATE_MED)}</TextDescription>
      </Col>
      <Col span={12}>
        <TextDescription title="Is Active ?">{entity.active ? _('action.yes') : _('action.no')}</TextDescription>
      </Col>
      <Col span={24}>
        <TextDescription title="Description">{entity.description}</TextDescription>
      </Col>
      <Col span={12}>
        <TextDescription title="NE Salaried Pay Rate">$ {entity.nonExemptSalariedPayRate}</TextDescription>
      </Col>
      <Col span={12}>
        <TextDescription title="NE Hourly Pay Rate">$ {entity.nonExemptHourlyPayRate}</TextDescription>
      </Col>
      <Col span={24}>
        <TextDescription title="Non-Working Hourly Paid Time">
          <span>{entity.nonWorkingHourlyPaidTime} Hour(s)</span>
        </TextDescription>
      </Col>
    </Row>
  ));

  return (
    <WLyPageContainer>
      <DataTable<Holiday, HolidayResource>
        model={Holiday}
        resource={HolidayResource}
        columns={dataTableColumns}
        renderDrawerContent={drawerContentRenderer}
        drawerProps={{
          title: 'Consult Holidays',
          width: '40%',
        }}
      />
    </WLyPageContainer>
  );
};
