import { Resource } from '@/api/resource';

/**
 * Holiday data sent to the api.
 * @author Adonis SIMO <simo.adonis@workerly.io>
 */
export interface HolidayRequestData {
  pk?: string;
  name: string;
  date?: string;
  active: boolean;
  req_rule?: {
    freq: number;
    bymonth: number;
    interval: number;
    bymonthday: number;
  };
  description: string;
  non_exempt_salaried_pay_rate: number;
  non_exempt_hourly_pay_rate: number;
  non_working_hourly_paid_time: number;
}

/**
 * Holiday data returned by the api.
 * @author Adonis SIMO <simo.adonis@workerly.io>
 */
export interface HolidayResponseData extends HolidayRequestData {
  pk: string;
  created: string;
  default: boolean;
  modified: string;
}

/**
 * Holiday resource manager.
 * @author Adonis SIMO <simo.adonis@workerly.io>
 */
export class HolidayResource extends Resource<HolidayRequestData, HolidayResponseData> {
  constructor() {
    super('/holidays');
  }
}
