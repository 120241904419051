import React from 'react';
import { Row, Col, Input, Space, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { Button, TextDescription, IconFont, EmployeeCard, Link } from '@/components';
import { WLyPageContainer } from '@/components/Layout';
import { useResources } from '@/api';
import { filter } from 'lodash';
import { Department, Position, Location } from '@/data';
import { usePersistFn } from '@umijs/hooks';

const { Option } = Select;

/**
 * EMployee list page
 * @author Axel NANA <axel.nana@workerly.io>
 */
export default function Employees() {
  const departments = useResources('department');
  const locations = useResources('location');
  const positions = useResources('position');
  const employees = useResources('employee');

  const [selectedDepartment, setSelectedDepartment] = React.useState<Department | null>(null);
  const [selectedLocation, setSelectedLocation] = React.useState<Location | null>(null);
  const [selectedPosition, setSelectedPosition] = React.useState<Position | null>(null);

  const resetFilter = usePersistFn(() => {
    setSelectedDepartment(null);
    setSelectedLocation(null);
    setSelectedPosition(null);
  });

  const filterLocationCB = React.useCallback(
    (e) => setSelectedLocation(locations.data?.find((l) => l.pk === e) || null),
    [locations],
  );

  const filterPositionCB = React.useCallback(
    (e) => setSelectedPosition(positions.data?.find((l) => l.pk === e) || null),
    [positions],
  );

  const filterDepartmentB = React.useCallback(
    (e) => setSelectedDepartment(departments.data?.find((l) => l.pk === e) || null),
    [departments],
  );

  const filteredEmployees = React.useMemo(
    () =>
      filter(
        filter(
          filter(employees.data, (e) =>
            selectedDepartment !== null ? e.department.pk === selectedDepartment.pk : true,
          ),
          (e) => (selectedLocation !== null ? e.location.pk === selectedLocation.pk : true),
        ),
        (e) => (selectedPosition !== null ? e.position.pk === selectedPosition.pk : true),
      ),
    [selectedDepartment, selectedLocation, selectedPosition, employees],
  );

  return (
    <WLyPageContainer
      ghost
      subTitle="Manage Company's Employees"
      extra={[<Button dashed>Import CSV</Button>, <Button>New Employee</Button>]}
      content={<Input size="large" placeholder="Search employee" prefix={<SearchOutlined />} />}
    >
      <Row gutter={[16, 32]} style={{ marginTop: '16px' }}>
        <Col span={24}>
          <TextDescription
            block={true}
            title={<b>Filter</b>}
            icon={<IconFont type="icon-filter" />}
            extra={[
              <Button size="small" dashed onClick={resetFilter}>
                Reset Filters
              </Button>,
            ]}
          >
            <Space>
              <Select
                value={selectedLocation?.pk}
                onChange={filterLocationCB}
                style={{ width: 200 }}
                placeholder="Filter By  Location"
              >
                {locations.data &&
                  locations.data.map((loc, idx) => (
                    <Option key={idx} value={loc.pk}>
                      {loc.name}
                    </Option>
                  ))}
              </Select>

              <Select
                value={selectedPosition?.pk}
                onChange={filterPositionCB}
                style={{ width: 200 }}
                placeholder="Filter By Job Title"
              >
                {positions.data &&
                  positions.data.map((position, idx) => (
                    <Option key={idx} value={position.pk}>
                      {position.name}
                    </Option>
                  ))}
              </Select>

              <Select
                value={selectedDepartment?.pk}
                onChange={filterDepartmentB}
                style={{ width: 200 }}
                placeholder="Filter By  Department"
              >
                {departments.data &&
                  departments.data.map((department, idx) => (
                    <Option key={idx} value={department.pk}>
                      {department.name}
                    </Option>
                  ))}
              </Select>
            </Space>
          </TextDescription>
        </Col>
        <Col span={24}>
          <Space wrap>
            {filteredEmployees &&
              filteredEmployees.map((employee, idx) => <EmployeeCard employee={employee} hoverable key={idx} />)}
          </Space>
        </Col>
      </Row>
    </WLyPageContainer>
  );
}
