import { Resource } from '@/api/resource';
import { RateResponseData } from '@/api/rate';

/**
 * Overtime data to api.
 * @author Adonis SIMO <simo.adonis@workerly.io>
 */
export interface OvertimeRequestData {
  pk?: string;
  pay_factor: number;
  name: string;
  description: string;

  state: string;
  daily_overtime: boolean;
  hour_first_milestone: number;
  hour_first_milestone_pay_factor: number;
  hour_second_milestone: number;
  hour_second_milestone_pay_factor: number;
}

/**
 * Overtime data from api.
 * @author Adonis SIMO <simo.adonis@workerly.io>
 */
export interface OvertimeResponseData extends OvertimeRequestData {
  pk: string;
  default: boolean;
  state_object: RateResponseData;
}

/**
 * Overtime resource.
 * @author Adonis SIMO <simo.adonis@workerly.io>
 */
export class OvertimeResource extends Resource<OvertimeRequestData, OvertimeResponseData> {
  constructor() {
    super('/overtime');
  }
}
