import { Resource } from '@/api/resource';
import { FullTimezone } from '@/api/types';

/**
 * Location request data
 * @author Adonis SIMO <simo.adonis@workerly.io>
 */
export interface LocationRequestData {
  pk?: string;

  is_headquater: boolean;
  name: string;
  state: string;
  city: string;

  default_yearly_rate: number;
  default_hourly_rate: number;

  full_timezone: FullTimezone;
  work_week_start: number;
  regular_start_time: string;
  regular_end_time: string;
  full_address: string;

  phone: string;
  email: string;
  break_start: string;
  break_end: string;
  work_weeks: string;
  break_gap: number;
  time_gap: number;
  meta?: any;
  //TODO: add work_week_start
}

/**
 * Location response data
 * @author Adonis SIMO <simo.adonis@workerly.io>
 */
export interface LocationResponseData extends LocationRequestData {
  pk: string;
  state_name: string;
  minimum_rate: number;
  default: boolean;
  latest_work_week_date: string;
  year_work_week: string[];
  effective_timezone: string;
  timezone: string;
}

export interface LocationEmployeeData {
  pk: string;
  base_location: string;
  start: string;
  active: boolean;
  location: {
    pk: string;
    created: string;
    modified: string;
    name: string;
  };
}
/**
 * Location resource
 * @author Adonis SIMO <simo.adonis@workerly.io>
 */
export class LocationResource extends Resource<LocationRequestData, LocationResponseData> {
  constructor() {
    super('/locations');
  }
}
