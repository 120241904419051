import React from 'react';
import { ModalProps, Modal, Button } from '@/components';
import { Position, WorkWeek } from '@/data';
import { useTranslation } from '@/utils/hooks';
import { Form, Input, Select } from 'antd';

export interface WorkWeekFormProps extends ModalProps {
  workWeek?: WorkWeek;
}

export function WorkWeekForm(props: WorkWeekFormProps) {
  const _ = useTranslation();
  const { workWeek } = props;
  const [form] = Form.useForm();

  const isEdit = workWeek?.pk !== null || undefined;
  return (
    <Modal {...props} ok={false} cancel={false} title={isEdit ? 'Edit The Work Week' : 'A New Work Week'}>
      <Form form={form} layout={'vertical'} requiredMark>
        <Form.Item
          name="name"
          label={_('settings.workweek.form.name')}
          initialValue={workWeek?.name}
          required
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="weekWork"
          label={_('settings.workweek.form.nbrHours')}
          initialValue={workWeek?.weekWork}
          required
          rules={[{ required: true }]}
        >
          <Input type="number" />
        </Form.Item>

        <Form.Item
          name="attendanceComputeDelay"
          label={_('settings.workweek.form.computeDelay')}
          initialValue={workWeek?.attendanceComputeDelay}
          required
          rules={[{ required: true }]}
        >
          <Input type={'number'} />
        </Form.Item>

        <Form.Item
          name="workWeekStart"
          label={_('settings.workweek.form.workWeekStart')}
          initialValue={workWeek?.workWeekStart}
          required
          rules={[{ required: true }]}
        >
          <Select>
            <Select.Option value={0}>Monday</Select.Option>
            <Select.Option value={1}>Tuesday</Select.Option>
            <Select.Option value={2}>Wednesday</Select.Option>
            <Select.Option value={3}>Thursday</Select.Option>
            <Select.Option value={4}>Friday</Select.Option>
            <Select.Option value={5}>Saturday</Select.Option>
            <Select.Option value={6}>Sunday</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <Button disabled={true} type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
