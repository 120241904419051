import React from 'react';
import { WLyPageContainer } from '@/components/Layout';
import { DataTable } from '@/components/DataTable';
import { Row, Col, Card, Select, Space } from 'antd';
import { TimesheeResource } from '@/api/timesheet';
import { ProColumns } from '@ant-design/pro-table';
import { Timesheet } from '@/data/Timesheet';
import { useResources } from '@/api';
import { IconFont, TextDescription } from '@/components';
import { DateTime } from 'luxon';
import { usePersistFn } from '@umijs/hooks';
import Text from 'antd/es/typography/Text';
import Title from 'antd/es/typography/Title';
import SubPeriod from '@/components/SubPeriod';

/**
 * @author Adonis SIMO <simo.adonis@workerly.io>
 */
export default function TimesheetUI() {
  //TODO: handle the filter per date interval
  // TODO: display current work week's data by default
  const [dates, selectDates] = React.useState<Array<{ start: DateTime; end: DateTime }> | undefined>([]);
  const locations = useResources('location');

  const updatePayrollDates = React.useCallback(
    (e: any) => {
      let location = locations.data?.find((loc, index) => loc.pk === e);
      selectDates(location?.yearWorkWeek);
    },
    [selectDates],
  );

  /**
   * Timesheet detais viewer in the drawer
   * @author Adonis SIMO <simo.adonis@workerly.io>
   */
  const TimesheetDetails = usePersistFn((entity: Timesheet) => {
    return (
      <Row gutter={[16, 32]}>
        <Col span={8}>
          <TextDescription title={'Employee Name'}>
            <Text>{entity.fullName}</Text>
          </TextDescription>
        </Col>
        <Col span={8}>
          <TextDescription title={'Week Start'}>
            <Text>{entity.weekStartTime.toLocaleString(DateTime.DATE_MED)}</Text>
          </TextDescription>
        </Col>
        <Col span={8}>
          <TextDescription title={'Week End'}>
            <Text>{entity.weekEndTime.toLocaleString(DateTime.DATE_MED)}</Text>
          </TextDescription>
        </Col>
        <Col span={24}>
          <TextDescription title={'Total Earnings'}>
            <Title level={4}>$ {entity.totalEarnings}</Title>
          </TextDescription>
        </Col>
        {entity.activity.sub_periods.map((s, i) => (
          <Col key={i} span={24}>
            <SubPeriod subPeriod={s} />
          </Col>
        ))}
      </Row>
    );
  });

  const timesheetColumns: ProColumns<Timesheet>[] = [
    {
      title: 'Employee name',
      dataIndex: 'fullName',
      copyable: true,
      ellipsis: true,
      tooltip: 'The Name Of The Employee',
    },
    {
      title: 'Total Earning',
      dataIndex: 'totalEarnings',
      tooltip: 'The Total Money Earned for this Timesheet By The Employee',
      renderText: (text) => `$ ${text}`,
    },
    {
      title: 'Week Start',
      dataIndex: 'weekStartTime',
      copyable: true,
      tooltip: 'The First Day Of This Timesheet',
    },
    {
      title: 'Week End',
      dataIndex: 'weekEndTime',
      copyable: true,
      tooltip: 'The Last Day Of This Timesheet',
    },
  ];

  return (
    <WLyPageContainer
      subTitle={'Company Wide Timesheets'}
      content={<Text>Choose a location and a work week to see specific data within that time range</Text>}
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card>
            <TextDescription block={true} title={<b>Filter</b>} icon={<IconFont type="icon-filter" />}>
              <Space>
                <Select style={{ width: 300 }} placeholder="Choose A Location" onChange={updatePayrollDates}>
                  {locations.data &&
                    locations.data.map((loc, idx) => (
                      <Select.Option key={idx} value={loc.pk}>
                        {loc.name}
                      </Select.Option>
                    ))}
                </Select>

                <Select style={{ width: 300 }} placeholder="Choose A Date Range">
                  {dates &&
                    dates.map((loc, idx) => (
                      <Select.Option key={idx} value={loc.start.toLocaleString(DateTime.DATE_MED)}>
                        From <b>{loc.start.toLocaleString(DateTime.DATE_MED)}</b> to{' '}
                        <b>{loc.end.toLocaleString(DateTime.DATE_MED)}</b>
                      </Select.Option>
                    ))}
                </Select>
              </Space>
            </TextDescription>
          </Card>
        </Col>
        <Col span={24}>
          <DataTable<Timesheet, TimesheeResource>
            model={Timesheet}
            resource={TimesheeResource}
            columns={timesheetColumns}
            drawerProps={{
              title: 'Consult A Timesheet',
              width: '50%',
              closable: true,
            }}
            renderDrawerContent={TimesheetDetails}
          />
        </Col>
      </Row>
    </WLyPageContainer>
  );
}
