import { Resource } from '@/api/resource';

/**
 * Role request data interface.
 * @author Adonis SIMO <simo.adonis@workerly.io>
 */
export interface RoleRequestData {
  pk?: string;
  name: string;
  permissions: number[];
  actions?: {
    [key: string]: string[];
  }[];
  company_wide: boolean;
  managed_employees?: string[];
  managed_locations?: string[];
  managed_departments?: string[];
}

/**
 * Role response data interface.
 * @author Adonis SIMO <simo.adonis@workerly.io>
 */
export interface RoleResponseData extends RoleRequestData {
  pk: string;
}

/**
 * Role resource manager.
 * @author Adonis SIMO <simo.adonis@workerly.io>
 */
export class RoleResource extends Resource<RoleRequestData, RoleResponseData> {
  constructor() {
    super('/role');
  }
}
