import { DateTime } from 'luxon';

import { DataModel } from '@/data/DataModel';
import { HolidayResponseData } from '@/api/holiday';
import { ConstrainedDataModel } from '@/data/ConstrainedDataModel';

/**
 * @author Adonis SIMO <simo.adonis@worerly.io>
 */
export class Holiday extends ConstrainedDataModel<HolidayResponseData> {
  public getRelated(key: keyof HolidayResponseData): Promise<DataModel | DataModel[] | null> {
    throw new Error('Method not implemented.');
  }

  public set pk(value: string) {
    this._rawData.pk = value;
  }

  public get pk(): string {
    return this._rawData.pk;
  }

  public set name(value: string) {
    this._rawData.name = value;
  }

  public get name(): string {
    return this._rawData.name;
  }

  public set date(value: DateTime) {
    this._rawData.date = value.toISO();
  }

  public get date(): DateTime {
    if (this._rawData.date) {
      return DateTime.fromISO(this._rawData.date, { setZone: true });
    }

    return DateTime.invalid('No date was defined');
  }

  public set active(value: boolean) {
    this._rawData.active = value;
  }

  public get active(): boolean {
    return this._rawData.active;
  }

  public set description(value: string) {
    this._rawData.description = value;
  }

  public get description(): string {
    return this._rawData.description;
  }

  public set nonExemptSalariedPayRate(value: number) {
    this._rawData.non_exempt_salaried_pay_rate = value;
  }

  public get nonExemptsSalariedPayRate(): number {
    return this._rawData.non_exempt_salaried_pay_rate;
  }

  public set nonExemptHourlyPayRate(value: number) {
    this._rawData.non_exempt_hourly_pay_rate = value;
  }

  public get nonExemptHourlyPayRate(): number {
    return this._rawData.non_exempt_hourly_pay_rate;
  }

  public set nonWorkingHourlyPaidTime(value: number) {
    this._rawData.non_working_hourly_paid_time = value;
  }

  public get nonWorkingHourlyPaidTime(): number {
    return this._rawData.non_working_hourly_paid_time;
  }
}
