import React from 'react';
import { Layout, Avatar, Dropdown, Menu } from 'antd';
import { UserOutlined, LogoutOutlined, SettingOutlined } from '@ant-design/icons';
import { IRouteComponentProps, useModel } from 'umi';

import { WLyWorkspaceSwitcherSider } from '@/components/Layout';
import { AppRoutes } from '@/utils/routes';
import { Link } from '@/components';
import { useTranslation } from '@/utils/hooks';

const { Header, Content } = Layout;

/**
 * Base Layout.
 * Displays the workspace switcher and the main app.
 * @author Axel Nana <axel.nana@workerly.io>
 * @param {IRouteComponentProps} param0
 */
export default ({ children }: IRouteComponentProps) => {
  const _ = useTranslation();
  const { user } = useModel('user');

  const menu = (
    <Menu className="wl-app-workspace-manager-header-account-menu">
      <Menu.Item>
        <Link leftIcon={<UserOutlined />} to={AppRoutes.accountWorkspaces.path}>
          {_('workspaces.menu.manager')}
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link leftIcon={<SettingOutlined />} to={AppRoutes.accountSettings.path}>
          {_('workspaces.menu.settings')}
        </Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <Link leftIcon={<LogoutOutlined />} to={AppRoutes.accountLogout.path}>
          {_('workspaces.menu.logout')}
        </Link>
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout hasSider>
      <WLyWorkspaceSwitcherSider />
      <Content>
        <Layout className="wl-app-workspace">
          <Header className="wl-app-workspace-header">
            <span>{_('workspaces.welcome', { name: <b>{user.name}</b> })}</span>
            <Dropdown overlay={menu} placement="bottomRight">
              <span className="wl-app-workspace-header-account-menu-avatar">
                <Avatar size={32} icon={<UserOutlined />} />
              </span>
            </Dropdown>
          </Header>
          <Content className="wl-app-container">{children}</Content>
        </Layout>
      </Content>
    </Layout>
  );
};
