import { Layout } from 'antd';
import { DateTime } from 'luxon';

const { Footer } = Layout;

/**
 * Renders the application default footer for all pages.
 * @author Axel Nana <axel.nana@workerly.io>
 */
export function WLyFooter() {
  return (
    <Footer className="wl-app-footer">
      &copy; {`${DateTime.local().toFormat('y')} Workerly. All Rights Reserved.`}
    </Footer>
  );
}
