import { Resource } from '@/api/resource';
import { PositionResponseData } from '@/api/position';

/**
 * The parent of a department, can be chosen within a list with creating the
 * department
 * @author Adonis SIMO <simo.adonis@workerly.io>
 */
export interface DepartmentParentData {
  pk?: string;
  name: string;
}

/**
 * Department head data
 * @author Adonis SIMO <simo.adonis@workerly.io>
 */
export interface DepartmentHeadData {
  pk?: string;
  user: {
    first_name: string;
    last_name: string;
    email: string;
  };
}

/**
 * Request data sent to the API
 * @author Adonis SIMO <simo.adonis@workerly.io>
 */
export interface DepartmentRequestData {
  pk?: string;
  name: string;
  description: string;
  head?: string; // TODO: remove this from the FORM UI
  code: string;
  positions?: string[];
  parent?: string;
}

/**
 * Response data received from the API
 * @author Adonis SIMO <simo.adonis@workerly.io>
 */
export interface DepartmentResponseData {
  pk: string;
  name: string;
  description: string;
  code: string;

  positions: PositionResponseData[];
  parent: DepartmentParentData | null;
  head: DepartmentHeadData | null;
}

/**
 * Just to read relation between an employee and his file.
 * @author Adonis SIMO <simo.adonis@workerly.io>
 */
export interface FileDepartment {
  id: string;
  active: boolean;
  start: string;
  end?: string;
  mission?: string;
  department: string;
  name: string;
  file: string;
}
export class DepartmentResource extends Resource<DepartmentRequestData, DepartmentResponseData> {
  constructor() {
    super('/departments');
  }
}
