import React from 'react';
import { WLyPageContainer } from '@/components/Layout';
import { RoleResource } from '@/api/role';
import { Role } from '@/data/Role';
import { ProColumns } from '@ant-design/pro-table';
import { Button } from '@/components';
import { CheckCircleOutlined, StopOutlined } from '@ant-design/icons';

import { DataTable } from '@/components';
import { usePersistFn } from '@umijs/hooks';

import RoleDetail from '@/pages/company/settings/components/roles/view-role-details';

const dataTableColumns: ProColumns<Role>[] = [
  {
    title: 'Name',
    dataIndex: 'name',
    copyable: true,
    ellipsis: true,
  },
  {
    title: 'Number Of Permissions',
    dataIndex: 'permissions',
    renderText: (permissions: any) => permissions.length,
  },
  {
    title: 'Number Of Actions',
    dataIndex: 'actions',
    renderText: (permissions: any) => permissions?.length,
  },
  {
    title: 'General Role ?',
    dataIndex: 'company_wide',
    tip: 'Does This Role Apply To The Whole Company Regardless To Departments etc',
    render: (dom, role: any) => (role.company_wide ? <CheckCircleOutlined /> : <StopOutlined />),
  },
];

/**
 * @author Adonis SIMO <simo.adonis@workerly.io>
 */
export default () => {
  const drawerContentRenderer = usePersistFn((entity: Role) => <RoleDetail role={entity} />);

  return (
    <WLyPageContainer subTitle="Define Access Level Across The Company" extra={[<Button>New Role...</Button>]}>
      <DataTable<Role, RoleResource>
        resource={RoleResource}
        model={Role}
        columns={dataTableColumns}
        drawerProps={{
          title: 'Consult Role',
          width: '40%',
        }}
        renderDrawerContent={drawerContentRenderer}
      />
    </WLyPageContainer>
  );
};
