import React from 'react';
import ProLayout, { MenuDataItem } from '@ant-design/pro-layout';
import { Layout, Spin } from 'antd';
import { FormattedMessage, getLocale, IRouteComponentProps, Link, useModel } from 'umi';
import { useBoolean, useDebounce, usePersistFn } from '@umijs/hooks';
import { isNull } from 'lodash';

import WorkerLyIcon from '@/assets/images/brand/icon.png';
import { WLyRightPanel, WLyWorkspaceSwitcherSider } from '@/components/Layout';
import { IconFont } from '@/components';
import { AppRoutes } from '@/utils/routes';
import { useTranslation } from '@/utils/hooks';

/**
 * Maps some names to icons, for the use in the app menu.
 * @author Axel Nana <axel.nana@workerly.io>
 */
const IconMap = ({ name }: { name: string }) => {
  return <IconFont type={`icon-${name}`} />;
};

/**
 * Base Layout.
 * Displays the workspace switcher and the main app.
 * @author Axel Nana <axel.nana@workerly.io>
 * @param {IRouteComponentProps} param0
 */
export default ({ children, location, route }: IRouteComponentProps) => {
  const _ = useTranslation();
  const { activeWorkspace, loadingQueryWorkspaces, loadingSetCurrentWorkspace } = useModel('workspaces');
  const { initialState } = useModel('@@initialState');

  /**
   * Manually handle the collapsed mode of the side menu.
   */
  const { state: _collapsed, toggle: _onCollapsedChanged } = useBoolean(true);

  /**
   * Define if the workspace system is loading. If so hide the main UI by a spinner.
   */
  const _workspacesLoadingHard = React.useMemo(() => loadingQueryWorkspaces || loadingSetCurrentWorkspace, [
    loadingQueryWorkspaces,
    loadingSetCurrentWorkspace,
  ]);

  /**
   * A slightly delayed value used by the loader, to avoid glitches when switching the loading state.
   */
  const _workspacesLoading = useDebounce(_workspacesLoadingHard, 1500);

  /**
   * The application title to show in the navigation bar.
   */
  const _appTitle = React.useMemo(() => (!isNull(activeWorkspace) ? activeWorkspace.name : 'Workerly'), [
    activeWorkspace,
  ]);

  /**
   * Loop over the menu items and format them.
   * @param {MenuDataItem[]} menus The array of menu items
   */
  const _loopMenuItem = usePersistFn((menus: MenuDataItem[]): MenuDataItem[] =>
    menus.map(({ icon, children, ...item }) => ({
      ...item,
      icon: icon && <IconMap name={icon as string} />,
      children: children && _loopMenuItem(children),
    })),
  );

  /**
   * Renders the menu and sub menu items of the navigation bar.
   */
  const _menuItemRenderer = usePersistFn((menuItemProps, defaultDom) => {
    const maybeLocalizedMenu = menuItemProps.locale ? (
      <React.Fragment>
        {menuItemProps.icon}
        <FormattedMessage id={menuItemProps.locale} tagName="span" />
      </React.Fragment>
    ) : (
      defaultDom
    );

    if (menuItemProps.isUrl || menuItemProps.children) {
      return maybeLocalizedMenu;
    }

    if (menuItemProps.path) {
      return <Link to={menuItemProps.path}>{maybeLocalizedMenu}</Link>;
    }

    return maybeLocalizedMenu;
  });

  /**
   * Renders the right content of the application top bar.
   */
  const _rightContentRenderer = usePersistFn(() => <WLyRightPanel />);

  /**
   * Renders the header of the side menu.
   */
  const _menuHeaderRender = usePersistFn((logo: React.ReactNode, title: React.ReactNode) => (
    <Link to={AppRoutes.dashboard.path}>
      {logo}
      <span title={_appTitle} className="wl-app-title">
        {title}
      </span>
    </Link>
  ));

  return (
    <Spin
      spinning={_workspacesLoadingHard || _workspacesLoading}
      size="large"
      tip={_('app.loading_workspace') as string}
      className="wl-component-spinner wl-component-spinner-fullscreen wl-component-spinner-workspaces-loader"
    >
      <Layout hasSider>
        <WLyWorkspaceSwitcherSider />
        <ProLayout
          {...initialState?.settings}
          className="wl-app-layout"
          collapsed={_collapsed}
          onCollapse={_onCollapsedChanged}
          defaultCollapsed
          logo={<img src={WorkerLyIcon} style={{ maxWidth: 24, maxHeight: 24, objectFit: 'contain' }} />}
          location={location}
          locale={getLocale()}
          menuDataRender={_loopMenuItem}
          translate="yes"
          route={route}
          layout="sidemenu"
          menu={{ locale: true }}
          navTheme="light"
          contentWidth="Fluid"
          title={_appTitle}
          footerRender={false}
          menuHeaderRender={_menuHeaderRender}
          style={{ height: '100vh' }}
          disableContentMargin
          subMenuItemRender={_menuItemRenderer}
          menuItemRender={_menuItemRenderer}
          rightContentRender={_rightContentRenderer}
        >
          {children}
        </ProLayout>
      </Layout>
    </Spin>
  );
};
