import React from 'react';
import { PageContainer, RouteContext, PageContainerProps } from '@ant-design/pro-layout';
import classNames from 'classnames';

import { useTranslation } from '@/utils/hooks';

/**
 * Allowed properties for a WLyPageContainer component.
 * @author Axel Nana <axel.nana@workerly.io>
 */
export interface WLyPageContainerProps extends PageContainerProps {}

/**
 * Company settings layout page
 * @author Axel Nana <axel.nana@workerly.io>
 */
export function WLyPageContainer({ children, ...props }: React.PropsWithChildren<WLyPageContainerProps>) {
  const _ = useTranslation();
  const routeCtx = React.useContext(RouteContext);

  return (
    <PageContainer
      className={classNames('wl-app-page-container', {
        'wl-app-page-container-collapsed': routeCtx.collapsed,
      })}
      breadcrumb={{
        ...routeCtx.breadcrumbProps,
        routes: routeCtx.breadcrumb?.routes?.map(route => ({
          ...route,
          breadcrumbName: _(route.breadcrumbName) as string,
        })),
      }}
      {...props}
      title={_(routeCtx.currentMenu?.locale as string)}
    >
      {children}
    </PageContainer>
  );
}
