import { DataModel } from '@/data/DataModel';
import { PayResource, PayResponseData } from '@/api/pay';
/**
 * Actual Pay related to many earned mode
 * @author Adonis SIMO <simo.adonis@workerly.io>
 */
export class Pay extends DataModel<PayResponseData> {
  public getRelated(
    key: string | number,
  ): Promise<DataModel<{ [key: string]: any }> | DataModel<{ [key: string]: any }>[] | null> {
    throw new Error('Method not implemented.');
  }

  /**
   * file, worker  should be related only
   */

  public get employeeType(): number {
    return this._rawData.employee_type;
  }

  public get fullName(): string {
    return this._rawData.full_name;
  }

  public get payrollStart(): string {
    return this._rawData.payroll_start;
  }

  public get payrollEnd(): string {
    return this._rawData.payroll_end;
  }

  public get payrollType(): string {
    return this._rawData.payroll_type;
  }

  public get basicAmount(): number {
    return this._rawData.basic_amount;
  }

  public get additionnalAmounts(): number {
    return this._rawData.additional_amounts;
  }

  public get totalAmount(): number {
    return this._rawData.total_amount;
  }

  public get totalAmountPaid(): number {
    return this._rawData.total_amount_paid;
  }

  public get totalPaid(): boolean {
    return this._rawData.total_paid;
  }
}
