import { BreakResponseData } from '@/api/break';
import { NeverUndefinedResponse, Resource, ResponseDataList } from '@/api/resource';

/**
 * The Shift raw data interpreted by the API.
 * @author Axel Nana <axel.nana@workerly.io>
 */
export interface ShiftRequestData {
  pk?: string;
  start: string;
  location: string;
  timezone?: string;
  end: string;
  capacity?: number;
  early_start_gap_minutes?: number;
  late_start_gap_minutes?: number;
  early_end_gap_minutes?: number;
  late_end_gap_minutes?: number;
  start_timeframe: string;
  end_timeframe?: string;
  name: string;
  description?: string;
  enable?: boolean;
  days?: Array<number>;
  locked?: string;
  breaks: Array<BreakResponseData>;

  mid_night_cross?: boolean;
  start_gap_minutes?: number;
  end_gap_minutes?: number;
}

/**
 * The Shift raw data sent by the API.
 * @author Axel Nana <axel.nana@workerly.io>
 */
export interface ShiftResponseData extends ShiftRequestData {
  pk: string;
  timezone: string;
  capacity: number;
  primary_crossing_employees: Array<string>;
  secondary_crossing_employees: Array<string>;
  shift_population: number;
  early_start_gap_minutes: number;
  late_start_gap_minutes: number;

  early_end_gap_minutes: number;
  late_end_gap_minutes: number;
  end_timeframe: string;
  description: string;
  enable: boolean;
  days: Array<number>;
  locked: string; // TODO: check this type

  employees_assigned: {
    pk: string;
    employee: {
      first_name: string;
      last_name: string;
      email: string;
      pk: string;
    };
    worker: string;
    user_accepted: boolean;
    user_accept_date: string;
    user_declined: boolean;
    user_declined_date: string;
    is_active: boolean;
  }[];
  mid_night_cross: boolean;
  start_gap_minutes: number;
  end_gap_minutes: number;
}

/**
 * To use when assigning a shift to an employee, we need
 * to provide the employee file id (pto_emp_comp) and the
 * employee id (emp_comp_id)
 * @author Adonis SIMO <simo.adonis@workerly.io>
 */
export interface ShiftAssignmentRequestData {
  shift_id: string;
  overtime: boolean;
  start_timeframe: string;
  end_timeframe: string;
  emp_comp_id: string;
  pto_emp_comp: string;
  remote: boolean;
}

/**
 * Result after assigning an employee to a shift
 * @author Adonis SIMo <simo.adonis@workerly.io>
 */
export interface ShiftAssignmentResponseData {
  pk: string;
  user_declined: boolean | null;
  user_decline_date: string | null;
  user_accepted: boolean | null;
  user_accept_date: string | null;
  shift_id: string;
  shift: ShiftRequestData;
  overtime: boolean;
  start_timeframe: string;
  end_timeframe: string;
  time_range: null;
  overtime_is_active: boolean | null;
  emp_comp_id: string;
  pto_emp_comp: string;
}

/**
 * Shift assignment object from file.
 * @author Adonis SIMO <simo.adonis@workerly.io>
 */
export interface ShiftAssignmentPerFileData {
  pk: string;
  user_declined: boolean | null;
  user_decline_date: string | null;
  user_accepted: boolean | null;
  user_accept_date: string | null;
  // shift_id: string;
  shift_id: ShiftRequestData;
  overtime: boolean;
  start_timeframe: string;
  end_timeframe: string;
  time_range: null;
  overtime_is_active: boolean | null;
  emp_comp_id: string;
  pto_emp_comp: string;
}

/**
 * The shift resource manager.
 * @author Axel Nana <axel.nana@workerly.io>
 */
export class ShiftResource extends Resource<ShiftRequestData, ShiftResponseData> {
  constructor() {
    super('/shift');
  }

  async default(
    current: number,
    pageSize: number,
  ): Promise<NeverUndefinedResponse<ResponseDataList<ShiftResponseData>>> {
    return await this.request('/shift/default', { method: 'get', params: { current, pageSize } });
  }
}
