import { Resource, NeverUndefinedResponse } from '@/api/resource';

/**
 * Data to send when creating a Device
 * @author Adonis SIMO <simo.adonis@wokerly.io>
 */
export interface DeviceRequestData {
  unique_id: string;
  location: string;
  name: string;
  pk?: string;
}

/**
 * Data to receive  Device
 * @author Adonis SIMO <simo.adonis@wokerly.io>
 */
export interface DeviceResponseData extends DeviceRequestData {
  pk: string;
  location_name: string;
}

export interface ObtainTokenResponseData {
  apikey: string;
}

/**
 * Device's resource to handle device CRUD
 * @author Adonis SIMO <simo.adonis@workerly.io>
 */
export class DeviceResource extends Resource<DeviceRequestData, DeviceResponseData> {
  constructor() {
    super('/device');

    this.fetchToken = this.fetchToken.bind(this);
    this.obtainToken = this.obtainToken.bind(this);
  }

  /**
   * Gets the currently existing token.
   * @author Adonis SIMO <simo.adonis@workerly.io>
   */
  async fetchToken(): Promise<NeverUndefinedResponse<ObtainTokenResponseData>> {
    return await this.request('/getapikey');
  }
  /**
   * Obtains a new token, this will delete the existing one.
   * @author Adonis SIMO <simo.adonis@workerly.io>
   */
  async obtainToken(): Promise<NeverUndefinedResponse<ObtainTokenResponseData>> {
    return await this.request('/apikey');
  }
}
