import React from 'react';
import { IRouteComponentProps, Redirect, useModel } from 'umi';

import { AppRoutes } from '@/utils/routes';

/**
 * Ensure that the user is logged in to view a page.
 * @author Axel Nana <axel.nana@workerly.io>
 */
export default ({ children }: IRouteComponentProps) => {
  const { isValidState } = useModel('user');

  if (isValidState) {
    return <React.Fragment>{children}</React.Fragment>;
  } else {
    return <Redirect to={AppRoutes.accountLogin.path} />;
  }
};
