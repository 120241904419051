import { DateTime } from 'luxon';

import { BreakRequestData } from '@/api/break';
import { ShiftResource } from '@/api/shift';
import { DataModel } from '@/data/DataModel';

/**
 * The Break data model interpreted by the app.
 * @author Axel Nana <axel.nana@workerly.io>
 */
export class Break extends DataModel<BreakRequestData> {
  public get pk(): string | undefined {
    return this._rawData.pk;
  }

  public set pk(value: string | undefined) {
    this._rawData.pk = value;
  }

  public get name(): string {
    return this._rawData.name;
  }

  public set name(value: string) {
    this._rawData.name = value;
  }

  public get start(): DateTime {
    return DateTime.fromISO(this._rawData.start, { setZone: true });
  }

  public set start(value: DateTime) {
    this._rawData.start = value.toISO();
  }

  public get end(): DateTime {
    return DateTime.fromISO(this._rawData.end, { setZone: true });
  }

  public set end(value: DateTime) {
    this._rawData.end = value.toISO();
  }

  public get shift(): string {
    return this._rawData.shift;
  }

  public set shift(value: string) {
    this._rawData.shift = value;
  }

  public get earlyStart(): number | undefined {
    return this._rawData.early_start;
  }

  public set earlyStart(value: number | undefined) {
    this._rawData.early_start = value;
  }

  public get earlyEnd(): number | undefined {
    return this._rawData.early_end;
  }

  public set earlyEnd(value: number | undefined) {
    this._rawData.early_end = value;
  }

  public get lateStart(): number | undefined {
    return this._rawData.late_start;
  }

  public set lateStart(value: number | undefined) {
    this._rawData.late_start = value;
  }

  public get lateEnd(): number | undefined {
    return this._rawData.late_end;
  }

  public set lateEnd(value: number | undefined) {
    this._rawData.late_end = value;
  }

  public async getRelated(key: 'shift'): Promise<null>; // TODO: SHift data model
  public async getRelated(key: keyof BreakRequestData): Promise<DataModel | null> {
    switch (key) {
      case 'shift':
        const shiftResource = new ShiftResource();
        const { data } = await shiftResource.get(this.shift);
        // TODO: Shift data model parsing
        return data.status ? null : null;

      default:
        throw new Error(`The given key "${key}" is not for an entity related to this one.`);
    }
  }
}
