import { ReadOnlyResource } from '@/api/resource';
import { DepartmentResponseData } from '@/api/department';

export interface SimpleAttendance {
  pk: string;
  fake: boolean;
  shift: '9814722f-1542-43f9-898b-eacf695daed7';
  valid: true;
  device: 'e70f5d90-02c6-4d1c-b374-6be24cb90306';
  remote: false;
  worker: '92e11f29-2b85-45e3-a033-0c3e62bb0c53';
  holiday: false;
  remarks: null;
  clock_in: '2021-01-04T08:00:00+0000';
  exported: null;
  overtime: false;
  clock_out: '2021-01-04T12:00:00+0000';
  is_active: false;
  temperature: 0.0;
  emp_clock_in: null;
  old_versions: {};
  emp_clock_out: null;
  late_clock_in: false;
  early_clock_in: false;
  late_clock_out: false;
  early_clock_out: false;
  temperature_out: 0.0;
  employee_dispute: null;
  manually_created: false;
  rejected_by_admin: null;
  valid_justification: '.';
  manually_created_reason: null;
  temperature_record_hour: '2021-01-04T08:00:00+0000';
  employee_dispute_message: null;
  rejected_by_admin_message: null;
  temperature_record_hour_out: '2021-01-04T12:00:00+0000';
}
/**
 * WeeklyActivityHistoy  structure, the actual data produced for one week,
 * mostly represent the timesheet itself
 * @author Adonis SIMO <simo.adonis@workery.io>
 */
export interface WeeklyActivityHistoryData {
  start: string;
  end: string;
  number_of_days: number;
  effective_work_days: number;
  regular_work: number;
  weekly_overtime_work: number;
  pay_factor: number;
  weekly_pay: number;
  hourly_pay: number;
  regular_salary_earned: number;
  overtime_salary_earned: number;
  week_salary_earned: number;
}

/**
 * Subperiod structure
 * @author Adonis SIMO <simo.adonis@workery.io>
 */
export interface SubPeriodData {
  start: string;
  end: string;
  period_work_done: {
    start: string;
    end: string;
    effective_work_days: number;
    pay: number;
    number_of_work_days: number;
    weekly_activity_history: WeeklyActivityHistoryData[];
  };
}

/**
 * The activity itself
 * @author Adonis SIMO <simo.adonis@workerly.oio>
 */
export interface ActivityDataTimesheet {
  pk: string;
  file_start: string;
  file_end?: string;
  week_processed: {
    start: string;
    end: string;
    total_earned: number;
  };
  sub_periods: SubPeriodData[];
}

/**
 * Reponse when we retrieve actual timesheet with money informations
 * @author Adonis SIMO <simo.adonis@workerly.io>
 */
export interface TimesheetResponseData {
  pk: string;
  worker: string;
  file: string;
  week_start_time: string;
  week_end_time: string;
  attendances: SimpleAttendance[];
  activity: ActivityDataTimesheet;
  former_disputed_attendances: any;
  former_disputed_activity: any;
  exported: boolean;
  departments: DepartmentResponseData[];
}

/**
 * handle retieval of the pay for one or many employee, ReadOnly
 * @author Adonis SIMO <simo.adonis@workerly.io>
 */
export class TimesheeResource extends ReadOnlyResource<TimesheetResponseData> {
  constructor() {
    super('/global-timesheet');
  }
}
