import React from 'react';
import { Statistic, PageHeader, Empty, Space, Row, Col, Typography } from 'antd';
import { LoginOutlined, EditOutlined, PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import { useHistory, useIntl, useModel } from 'umi';
import { isEmpty } from 'lodash';
import { useBoolean } from '@umijs/hooks';

import { Button, CompanyCard, Link } from '@/components';
import { CreateCompanyModal } from './components/create-company-modal';
import { Company } from '@/data';
import { AppRoutes } from '@/utils/routes';

export default () => {
  const intl = useIntl();
  const history = useHistory();
  const { user } = useModel('user');
  const { workspaces, loadingQueryWorkspaces, activeWorkspace, setActiveWorkspace } = useModel('workspaces');

  const { state: createCompanyModalVisible, toggle: setCreateCompanyModalVisible } = useBoolean();

  const _toggleCreateCompanyModal = React.useCallback(() => setCreateCompanyModalVisible(), [
    setCreateCompanyModalVisible,
  ]);

  const _onGoToWorkspace = React.useCallback(
    (c: Company) => {
      if (activeWorkspace?.pk !== c.pk) {
        setActiveWorkspace(c);
      }
      history.push(AppRoutes.dashboard.path);
    },
    [activeWorkspace, setActiveWorkspace, history],
  );

  return (
    <React.Fragment>
      {loadingQueryWorkspaces && (
        <div className="wl-app-workspace-manager-loader">
          <Empty
            className="wl-app-workspace-manager-loader-spinner"
            image={<LoadingOutlined style={{ width: 64, height: 64 }} />}
            imageStyle={{ height: 96 }}
            description={intl.formatMessage({ id: 'workspaces.message.loading_companies' })}
          />
        </div>
      )}
      {!loadingQueryWorkspaces && isEmpty(workspaces) && (
        <div className="wl-app-workspace-manager-empty">
          <Empty imageStyle={{ height: 192 }} description={intl.formatMessage({ id: 'workspaces.message.empty' })} />
        </div>
      )}
      {/* <Row className="wl-app-workspace-manager-profile-details-wrapper">
        <Col span={24}>
          <div className="wl-app-workspace-manager-profile-details">
            <Statistic
              className="wl-app-workspace-manager-profile-details-item"
              title="Primary Email"
              value={user.email}
            />
            <Statistic
              className="wl-app-workspace-manager-profile-details-item"
              title="Companies"
              value={workspaces?.length || 0}
            />
          </div>
        </Col>
      </Row> */}
      <Row gutter={[16, 32]}>
        <Col span={24}>
          <PageHeader
            title={intl.formatMessage({ id: 'workspaces.manager.title' })}
            subTitle={intl.formatMessage({ id: 'workspaces.manager.subtitle' })}
            ghost
            extra={
              <Button onClick={_toggleCreateCompanyModal} leftIcon={<PlusOutlined />}>
                {intl.formatMessage({ id: 'workspaces.action.new_company' })}
              </Button>
            }
          />
        </Col>
        {!isEmpty(workspaces) && (
          <Col span={24}>
            <Space direction="horizontal" wrap align="center" size={16} className="wl-app-workspace-manager-list">
              {workspaces?.map(c => (
                <CompanyCard
                  key={`wl-app-workspace-manager-list-item-${c.pk}`}
                  hoverable
                  company={c}
                  actions={[
                    <Button
                      key={`open-company-${c.pk}`}
                      type="text"
                      leftIcon={<LoginOutlined />}
                      onClick={() => _onGoToWorkspace(c)}
                      style={{ width: '100%' }}
                    >
                      Open
                    </Button>,
                    <Button
                      key={`edit-company-${c.pk}`}
                      type="text"
                      leftIcon={<EditOutlined />}
                      style={{ width: '100%' }}
                    >
                      Edit
                    </Button>,
                  ]}
                />
              ))}
            </Space>
          </Col>
        )}
      </Row>
      <CreateCompanyModal visible={createCompanyModalVisible} onCancel={_toggleCreateCompanyModal} />
    </React.Fragment>
  );
};
