import { useCreation } from '@umijs/hooks';
import { isUndefined } from 'lodash';
import { useModel, useRequest } from 'umi';

import { CustomResource, NeverUndefinedResponse, ResponseDataList } from '@/api/resource';
import { Invitation } from '@/data/Invitation';

/**
 * Specifies the data needed to perform an login operation.
 * @author Axel Nana <axel.nana@workerly.io>
 */
export interface AuthLoginRequestData {
  /**
   * The email of the user.
   * @type {string}
   */
  username: string;

  /**
   * The user's password.
   * @type {string}
   */
  password: string;

  /**
   * Defines if we need to create login cookies
   * on the current machine.
   * @type {boolean}
   */
  remember: boolean;
}

/**
 * Defines the data structure of an email address linked to an user account.
 * @author Axel Nana <axel.nana@workerly.io>
 */
export interface AuthResponseUserDataEmailAddress {
  pk: string;
  email: string;
  verified: boolean;
  primary: boolean;
}

/**
 * Specifies the user data returned by the server after a successful auth operation.
 * @author Axel Nana <axel.nana@workerly.io>
 */
export interface AuthResponseUserData {
  id?: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  is_active?: boolean;
  is_staff?: boolean;
  is_superuser?: boolean;
  companies?: any[];
  permissions?: any;
  emailaddress_set?: Array<AuthResponseUserDataEmailAddress>;
}

/**
 * Specifies the response sent after starting an account merge operation.
 * @author Axel Nana <axel.nana@workerly.io>
 */
export interface AuthMergeOperationResponse {
  details: string;
}

/**
 * Specifies the response sent after querying the list of account merge operations.
 * @author Axel Nana <axel.nana@workerly.io>
 */
export interface AuthMergeOperationItemResponse {
  key: string;
  effective_merge_date: string;
  ended: boolean;
  status: 'PENDING' | 'CANCELLED' | 'CLOSED';
}

/**
 * Specifies the data returned by the server after a successful auth operation.
 * @author Axel Nana <axel.nana@workerly.io>
 */
export interface AuthResponseData {
  /**
   * The access token.
   * @type {string}
   */
  access: string;

  /**
   * The refresh token.
   * @type {string}
   */
  refresh: string;

  /**
   * The logged-in user.
   * @type {any}
   */
  user: AuthResponseUserData;
}

/**
 * Specifies the data needed to send a signup request.
 * @author Axel Nana <axel.nana@workerly.io>
 */
export interface AuthSignupRequestData {
  /**
   * The user's first name.
   * @type {string}
   */
  first_name: string;

  /**
   * The user's last name.
   * @type {string}
   */
  last_name: string;

  /**
   * The user's email.
   * @type {string}
   */
  email: string;
}

/**
 * Specify the data needed to send a logout request.
 * @author Axel Nana <axel.nana@workerly.io>
 */
export interface AuthLogoutRequestData {
  refresh_token?: string;
}

/**
 * Specify the data returned by the server after a logout request.
 * @author Axel Nana <axel.nana@workerly.io>
 */
export interface AuthLogoutResponseData {}

/**
 * Specify the data returned by the API after querying for the invitations
 * list of an account.
 * @author Axel Nana <axel.nana@workerly.io>
 */
export interface AuthInvitationResponseData {
  email: string;
  company: string;
  key: string;
  sent: string;
  accepted: boolean;
  company_name: string;
  pk: string;
}

/**
 * Defines data needed when resetting the user's password.
 * @author Axel Nana <axel.nana@workerly.io>
 */
export interface AuthResetPasswordRequestData {
  password: string;
  user_id: string;
  timestamp: number;
  signature: string;
}

/**
 * Resource class used to handle authentication operations.
 * @author Axel Nana <axel.nana@workerly.io>
 */
export class AuthResource extends CustomResource {
  constructor() {
    super();

    this.login = this.login.bind(this);
    this.signup = this.signup.bind(this);
    this.logout = this.logout.bind(this);
    this.refresh = this.refresh.bind(this);
    this.initMergeOperation = this.initMergeOperation.bind(this);
    this.cancelMergeOperation = this.cancelMergeOperation.bind(this);
    this.addSlaveAccountInMergeOperation = this.addSlaveAccountInMergeOperation.bind(this);
    this.launchMergeOperation = this.launchMergeOperation.bind(this);
    this.mergeOperations = this.mergeOperations.bind(this);
    this.invitations = this.invitations.bind(this);
    this.acceptInvitation = this.acceptInvitation.bind(this);
    this.sendResetPasswordLink = this.sendResetPasswordLink.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
  }

  /**
   * Login the user with the given data.
   * @author Axel Nana <axel.nana@workerly.io>
   * @param {AuthLoginRequestData} param0 The login form data.
   */
  async login({ username, password }: AuthLoginRequestData): Promise<NeverUndefinedResponse<AuthResponseData>> {
    return await this.request('/auth/login', {
      method: 'post',
      data: {
        email: username,
        password: password,
      },
    });
  }

  /**
   * Creates a new user account with the given data.
   * @author Axel Nana <axel.nana@workerly.io>
   * @param {AuthSignupRequestData} data The signup form data.
   */
  async signup(data: AuthSignupRequestData): Promise<NeverUndefinedResponse<AuthResponseData>> {
    return await this.request('/auth/signup', {
      method: 'post',
      data,
    });
  }

  /**
   * Logs out an user and cancel his token.
   * @author Axel Nana <axel.nana@workerly.io>
   * @param {AuthLogoutRequestData} data The required logout data.
   */
  async logout(data: AuthLogoutRequestData): Promise<NeverUndefinedResponse<AuthLogoutResponseData>> {
    return await this.request('/auth/logout', {
      method: 'post',
      data,
    });
  }

  /**
   * Refreshes the current access token.
   * @author Axel Nana <axel.nana@workerly.io>
   * @param {string} refresh The refresh token.
   */
  async refresh(refresh: string): Promise<NeverUndefinedResponse<{ access: string }>> {
    return await this.request('/auth/refresh', { method: 'post', data: { refresh } });
  }

  /**
   * Starts an account merge operation.
   * @author Axel Nana <axel.nana@workerly.io>
   */
  async initMergeOperation(): Promise<NeverUndefinedResponse<AuthMergeOperationResponse>> {
    return await this.request('/auth/init-merge-operation', { method: 'get' });
  }

  /**
   * Cancels an account merge operation.
   * @author Axel Nana <axel.nana@workerly.io>
   * @param {string} key The merge operation unique key, obtained after initializing the merge operation.
   */
  async cancelMergeOperation(key: string): Promise<NeverUndefinedResponse<AuthMergeOperationResponse>> {
    return await this.request(`/auth/cancel-merge-operation/${key}`, { method: 'patch' });
  }

  /**
   * Adds a slave account in the merge operation with the given key.
   * @author Axel Nana <axel.nana@workerly.io>
   * @param {string} key The merge operation unique key, obtained after initializing the merge operation.
   */
  async addSlaveAccountInMergeOperation(key: string): Promise<NeverUndefinedResponse<AuthMergeOperationResponse>> {
    return await this.request(`/auth/add-user-to-merge-operation/${key}`, { method: 'patch' });
  }

  /**
   * Launches the merge operation with the given key.
   * @author Axel Nana <axel.nana@workerly.io>
   * @param {string} key The merge operation unique key, obtained after initializing the merge operation.
   */
  async launchMergeOperation(key: string): Promise<NeverUndefinedResponse<AuthMergeOperationResponse>> {
    return await this.request(`/auth/launch-merge-operation/${key}`, { method: 'patch' });
  }

  /**
   * Query the list of account merge operations.
   * @author Axel Nana <axel.nana@workerly.io>
   */
  async mergeOperations(): Promise<NeverUndefinedResponse<ResponseDataList<AuthMergeOperationItemResponse>>> {
    return await this.request('/auth/merge-operations', { method: 'get' });
  }

  /**
   * Query the list of account invitations.
   * @author Axel Nana <axel.nana@workerly.io>
   * @param {number | undefined} offset The offset from which starts the slice.
   * @param {number | undefined} limit The number of items in the slice.
   */
  async invitations(
    offset?: number,
    limit?: number,
  ): Promise<NeverUndefinedResponse<ResponseDataList<AuthInvitationResponseData>>> {
    return isUndefined(offset) && isUndefined(limit)
      ? await this.request('/auth/invitations', { method: 'get', params: { 'no-paginate': true } })
      : await this.request('/auth/invitations', { method: 'get', params: { offset, limit } });
  }

  /**
   * Accepts the given invitation.
   * @author Axel Nana <axel.nana@workerly.io>
   * @param {Invitation} invitation The invitation to accept.
   */
  // TODO: Fix the return type when #6 will be merged
  async acceptInvitation(invitation: Invitation): Promise<NeverUndefinedResponse<AuthMergeOperationResponse>> {
    return this.request(`/auth/accept-invitation/${invitation.key}`, { method: 'patch' });
  }

  /**
   * Sends a link to the user for a password reset operation.
   * @author Axel Nana <axel.nana@workerly.io>
   * @param {string} login The user login to use for password reset.
   */
  async sendResetPasswordLink(login: string): Promise<NeverUndefinedResponse> {
    return this.request(`/auth/send-reset-password-link`, { method: 'post', data: { login } });
  }

  /**
   * Resets the user's password with the new provided one.
   * @author Axel Nana <axel.nana@workerly.io>
   * @param {AuthResetPasswordRequestData} data The data sent for the reset password process.
   */
  async resetPassword(data: AuthResetPasswordRequestData) {
    return this.request('/auth/reset-password', { method: 'post', data });
  }
}

/**
 * React hook to get the list of invitations in an unified manner across the app.
 * @author Axel Nana <axel.nana@workerly.io>
 * @param {{offset: number, limit: number}} params The parameters to pass to the request.
 */
export function useInvitations(params?: { offset: number; limit: number }) {
  const authResource = useCreation(() => new AuthResource(), []);
  const { user } = useModel('user');

  const { data, error, loading, cancel, reset } = useRequest(
    () => authResource.invitations(params?.offset, params?.limit),
    {
      cacheKey:
        params !== undefined
          ? `wl-request-invitations-${user.pk}-${params.offset}-${params.limit}`
          : `wl-request-invitations-${user.pk}-all`,
      formatResult: ({ data }) => {
        return data.status ? data.data?.data.map((i) => new Invitation(i)) || [] : [];
      },
    },
  );

  return {
    data,
    error,
    loading,
    cancel,
    reset,
  };
}
