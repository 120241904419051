import { IndustryResource, IndustryResponseData } from '@/api/industry';
import { DataModel } from '@/data/DataModel';

/**
 * The Industry data model interpreted by the app.
 * @author Axel Nana <axel.nana@workerly.io>
 */
export class Industry extends DataModel<IndustryResponseData> {
  public get pk(): string {
    return this._rawData.pk;
  }

  public get name(): string {
    return this._rawData.name;
  }

  public get parent(): string {
    return this._rawData.parent;
  }

  public get description(): string {
    return this._rawData.description;
  }

  public get sub_industries(): string[] {
    return this._rawData.sub_industries;
  }

  public async getRelated(key: 'parent'): Promise<Industry | null>;
  public async getRelated(key: keyof IndustryResponseData): Promise<DataModel | null> {
    switch (key) {
      case 'parent':
        const industryResource = new IndustryResource();
        const { data: response } = await industryResource.get(this.parent);
        return response.status ? new Industry(response.data) : null;

      default:
        throw new Error(`The given key "${key}" is not for an entity related to this one.`);
    }
  }
}
