import { Resource } from '@/api/resource';

/**
 * Enum for unit
 * @author Adonis SIMO <simo.adonis@workerly.io>
 */
export enum PayrollSchedule {
  Monthly = 0,
  SemiMonthly = 1,
  BiWeekly = 2,
  Weekly = 3,
  Daily = 4,
}

/**
 * Payroll request data sent to the api.
 * @author Adonis SIMO <simo.adonis@workerly.io>
 */
export interface PayrollRequestData {
  pk?: string;
  name: string;
  payroll_schedule: PayrollSchedule;
  start?: string;
}

/**
 * Payroll request data retrieved from the api.
 * @author Adonis SIMO <simo.adonis@workerly.io>
 */
export interface PayrollResponseData extends PayrollRequestData {
  pk: string;
  default?: boolean;
  next_deadline: string;
  previous_deadline: string;
  year_payrolls: string[];
}

/**
 * Payroll resource manager.
 * @author Adonis SIMO <simo.adonis@workerly.io>
 */
export class PayrollResource extends Resource<PayrollRequestData, PayrollResponseData> {
  constructor() {
    super('/payroll');
  }
}
