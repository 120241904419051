import { NeverUndefinedResponse, CustomResource, ResponseDataList } from '@/api/resource';
import { CompanyResponseData } from '@/api/company';
import { Company } from '@/data';

/**
 * Defines additional content returned by the server when setting the current
 * workspace.
 * @author Axel Nana <axel.nana@workerly.io>
 */
export interface WorkspaceSetCurrentResponseData extends CompanyResponseData {
  permissions: any;
  details: string;
  email: string;
}

/**
 * Specify the data returned by the server after querying the
 * list of workspaces for the current logged in user.
 * @author Axel Nana <axel.nana@workerly.io>
 */
export interface WorkspaceCompaniesResponseData {
  company__company: string;
  company__company__domains__domain: string;
  company__company__address_line_1: string;
  company__company__name: string;
  company__company__public_cover_image: string;
  company__company__public_description: string;
  company__company__public_logo: string;
  company__company__public_phone: string;
  is_active: boolean;
  is_owner: boolean;
}

/**
 * Resource class used to handle workspace operations.
 * @author Axel Nana <axel.nana@workerly.io>
 */
export class WorkspaceResource extends CustomResource {
  constructor() {
    super();

    this.workspaces = this.workspaces.bind(this);
    this.setCurrentWorkspace = this.setCurrentWorkspace.bind(this);
  }

  /**
   * Gets the list of workspaces (companies) for the current logged in user.
   * @author Axel Nana <axel.nana@workerly.io>
   */
  async workspaces(): Promise<NeverUndefinedResponse<Company[]>> {
    return await this.request<ResponseDataList<WorkspaceCompaniesResponseData>>('/workspace/my-companies', {
      method: 'get',
      params: { 'no-paginate': true },
    }).then(response => {
      return { data: { ...response.data, data: response.data.data?.data?.map(w => Company.fromWorkspace(w)) || [] } };
    });
  }

  /**
   * Sets the given workspace as current, and return necessary data to initialize the workspace
   * on the front-end.
   * @param company The workspace to set as current.
   */
  async setCurrentWorkspace(company: Company): Promise<NeverUndefinedResponse<WorkspaceSetCurrentResponseData>> {
    return await this.request(`/workspace/set-active-company/${company.pk}`, {
      method: 'get',
    });
  }
}
