import React from 'react';
import { message } from 'antd';
import { getIntl, history, useModel, useRequest } from 'umi';
import { useCookies } from 'react-cookie';
import { includes, isEmpty } from 'lodash';
import { useMount, useCreation, useUpdateEffect } from '@umijs/hooks';

import { Company } from '@/data';
import { AppRoutes } from '@/utils/routes';
import { WorkspaceResource, WorkspaceSetCurrentResponseData } from '@/api/workspace';
import { ResponseData } from '@/api/resource';

export interface WorkspacesModel {
  workspaces: Company[];
  loadingQueryWorkspaces: boolean;
  setWorkspaces(companies: Company[]): void;
  refreshWorkspaces(): Promise<void>;
  activeWorkspace: Company | null;
  setActiveWorkspace(workspace: Company | null): Promise<void>;
  loadingSetCurrentWorkspace: boolean;
  isCurrentWorkspace(workspace: Company): boolean;
}

export default function useWorkspaces(): WorkspacesModel {
  const intl = getIntl();

  const [cookies, setCookie, removeCookie] = useCookies(['wl-workspace-tenant']);
  const { isValidState } = useModel('user');

  const [workspaces, _setWorkspaces] = React.useState<Company[]>([]);
  const [activeWorkspace, _setActiveWorkspace] = React.useState<Company | null>(null);
  const [_initialized, _setInitialized] = React.useState<boolean>(false);

  const workspaceResource = useCreation(() => new WorkspaceResource(), []);
  const { run: _runQueryWorkspaces, loading: loadingQueryWorkspaces } = useRequest(workspaceResource.workspaces, {
    manual: true,
    onSuccess: result => {
      if (result.status) {
        setWorkspaces(result.data || []);
      }
    },
  });
  const { run: _runSetCurrentWorkspace, loading: loadingSetCurrentWorkspace } = useRequest(
    workspaceResource.setCurrentWorkspace,
    {
      manual: true,
      onSuccess: result => {
        if (result.status) {
          // TODO: Set permissions
          // TODO: Update user

          message.success({
            content: intl.formatMessage(
              { id: 'workspaces.message.workspace_switch_success' },
              { name: React.createElement('b', null, result.data?.name) },
            ),
          });
        }
      },
      onError: e => {
        console.error(e);

        message.error({
          content: intl.formatMessage({ id: 'workspaces.message.workspace_switch_error' }),
        });
      },
    },
  );

  const refreshWorkspaces = React.useCallback(async () => {
    await _runQueryWorkspaces();
  }, [_runQueryWorkspaces]);

  const setWorkspaces = React.useCallback(_setWorkspaces, []);

  const setActiveWorkspace = React.useCallback(
    async (c: Company | null) => {
      let response: ResponseData<WorkspaceSetCurrentResponseData> | undefined = undefined;

      if (c !== null) {
        response = await _runSetCurrentWorkspace(c);
      }

      _setActiveWorkspace(old => {
        if (old === null && c !== null) {
          history.push(AppRoutes.dashboard.path);
        } else if (old !== null && c !== null) {
          window.location.reload();
        }

        if (c !== null && response !== undefined && response.data !== undefined) {
          const company = Object.assign({}, c?.toJson() || {}, response.data);
          c = new Company(company);
        }

        return c;
      });
    },
    [_runSetCurrentWorkspace],
  );

  const isCurrentWorkspace = React.useCallback((c: Company) => c.pk === activeWorkspace?.pk, [activeWorkspace]);

  React.useEffect(() => {
    const _initialize = async () => {
      if (!_initialized && isValidState && workspaces.length === 0) {
        await refreshWorkspaces();
        _setInitialized(true);
      }

      if (_initialized) {
        if (
          activeWorkspace === null &&
          isEmpty(cookies['wl-workspace-tenant']) &&
          !includes(
            [AppRoutes.accountWorkspaces.path, AppRoutes.accountSettings.path, AppRoutes.accountPasswordReset.path],
            history.location.pathname,
          )
        ) {
          history.push(AppRoutes.accountWorkspaces.path);
        } else if (activeWorkspace === null && !isEmpty(cookies['wl-workspace-tenant'])) {
          const ws = workspaces.find(c => c.tenant === cookies['wl-workspace-tenant']) || null;
          if (!isEmpty(ws) && ws !== null) {
            _setActiveWorkspace(ws);
          } else {
            removeCookie('wl-workspace-tenant', { path: '/' });
          }
        } else if (activeWorkspace !== null) {
          if (
            !includes(
              [AppRoutes.accountWorkspaces.path, AppRoutes.accountSettings.path, AppRoutes.accountPasswordReset.path],
              history.location.pathname,
            )
          ) {
            setCookie('wl-workspace-tenant', activeWorkspace.tenant, { path: '/' });
          } else {
            removeCookie('wl-workspace-tenant', { path: '/' });
            _setActiveWorkspace(null);
          }
        }
      }
    };

    _initialize();
  }, [_initialized, isValidState, refreshWorkspaces, activeWorkspace, workspaces, cookies, removeCookie, setCookie]);

  return {
    workspaces,
    setWorkspaces,
    loadingQueryWorkspaces,
    refreshWorkspaces,
    activeWorkspace,
    setActiveWorkspace,
    loadingSetCurrentWorkspace,
    isCurrentWorkspace,
  };
}
