import { DataModel } from '@/data/DataModel';
import { OvertimeResponseData } from '@/api/overtime';
import { RateResponseData } from '@/api/rate';
import { ConstrainedDataModel } from './ConstrainedDataModel';

/**
 * @author Adonis SIMO <simo.adonis@worerly.io>
 */
export class Overtime extends ConstrainedDataModel<OvertimeResponseData> {
  public get pk(): string {
    return this._rawData.pk;
  }

  public set pk(value: string) {
    this._rawData.pk = value;
  }

  public set payFactor(value: number) {
    this._rawData.pay_factor = value;
  }

  public get payFactor(): number {
    return this._rawData.pay_factor;
  }

  public set name(value: string) {
    this._rawData.name = value;
  }

  public get name(): string {
    return this._rawData.name;
  }

  public set description(value: string) {
    this._rawData.description = value;
  }

  public get description(): string {
    return this._rawData.description;
  }

  public set hourFirstMilestone(value: number) {
    this._rawData.hour_first_milestone = value;
  }

  public get hourFirstMilestone(): number {
    return this._rawData.hour_first_milestone;
  }

  public get hourFirstMilestonePayFactor(): number {
    return this._rawData.hour_first_milestone_pay_factor;
  }

  public set hourFirstMilestonePayFactor(value: number) {
    this._rawData.hour_first_milestone_pay_factor = value;
  }

  public set hourSecondMilestone(value: number) {
    this._rawData.hour_second_milestone = value;
  }

  public get hourSecondMilestone(): number {
    return this._rawData.hour_second_milestone;
  }

  public get hourSecondMilestonePayFactor(): number {
    return this._rawData.hour_second_milestone_pay_factor;
  }

  public set hourSecondMilestonePayFactor(value: number) {
    this._rawData.hour_second_milestone_pay_factor = value;
  }

  public set dailyOvertime(value: boolean) {
    this._rawData.daily_overtime = value;
  }

  public get dailyOvertime(): boolean {
    return this._rawData.daily_overtime;
  }

  public set state(value: string) {
    this._rawData.state = value;
  }

  public get state(): string {
    return this._rawData.state;
  }

  public get stateObject(): RateResponseData {
    return this._rawData.state_object;
  }

  public async getRelated(key: keyof OvertimeResponseData): Promise<DataModel | null> {
    return null;
  }
}
