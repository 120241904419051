import React from 'react';
import { message, Col, Form, Input, Modal as DefaultModal, Result, Row, Steps, Typography } from 'antd';
import {
  CheckCircleOutlined,
  LoadingOutlined,
  PullRequestOutlined,
  TeamOutlined,
  UserOutlined,
  UserAddOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons';
import { useIntl, FormattedMessage, useRequest } from 'umi';
import { isEmpty, isUndefined } from 'lodash';
import { useCreation } from '@umijs/hooks';

import { Button, Modal, ModalProps } from '@/components';
import { AuthMergeOperationItemResponse, AuthResource } from '@/api/auth';

const { Paragraph, Text } = Typography;
const { Step } = Steps;

/**
 * Additional props for a MergeAccountsModal component.
 * @author Axel Nana <axel.nana@workerly.io>
 */
export interface MergeAccountsModalProps extends ModalProps {
  /**
   * Run a function when this modal has finished his job.
   */
  callback?(): void;

  /**
   * The pending merge operation, if any.
   * @type {AuthMergeOperationItemResponse | undefined}
   */
  mergeOperation?: AuthMergeOperationItemResponse;
}

/**
 * Renders a modal used to do a merge accounts operation.
 * @author Axel Nana <axel.nana@workerly.io>
 * @param {MergeAccountsModalProps} param0 The list on modal properties.
 */
export function MergeAccountsModal({ callback, onCancel, mergeOperation, ...props }: MergeAccountsModalProps) {
  const intl = useIntl();

  const hasMergeOperation = React.useMemo(() => !isUndefined(mergeOperation), [mergeOperation]);

  const [current, setCurrent] = React.useState(0);
  const [isMasterAccount, setIsMasterAccount] = React.useState(false);
  const [mergeKey, setMergeKey] = React.useState<string | undefined>();

  /**
   * Resets the modal state.
   */
  const _resetState = React.useCallback(() => {
    setCurrent(hasMergeOperation ? 1 : 0);
    setIsMasterAccount(hasMergeOperation);
    setMergeKey(mergeOperation?.key);
  }, [hasMergeOperation, mergeOperation]);

  React.useEffect(() => _resetState(), [_resetState]);

  const authResource = useCreation(() => new AuthResource(), []);
  const {
    run: _runInitMergeOperation,
    loading: _initMergeOperationLoading,
  } = useRequest(authResource.initMergeOperation, { manual: true });
  const {
    run: _runCancelMergeOperation,
    loading: _cancelMergeOperationLoading,
  } = useRequest(authResource.cancelMergeOperation, { manual: true });
  const {
    run: _runLaunchMergeOperation,
    loading: _launchMergeOperationLoading,
  } = useRequest(authResource.launchMergeOperation, { manual: true });
  const {
    run: _runAddSlaveAccountInMergeOperation,
    loading: _addSlaveAccountInMergeOperationLoading,
  } = useRequest(authResource.addSlaveAccountInMergeOperation, { manual: true });

  /**
   * Go to the next step.
   */
  const next = () => {
    setCurrent(current + 1);
  };

  /**
   * Go to the previous step.
   */
  const prev = () => {
    setCurrent(current - 1);
  };

  /**
   * Go to the given step.
   */
  const goto = (step: number) => {
    setCurrent(step);
  };

  /**
   * Gets the correct status of a step.
   * @param {number} step The step to get the status.
   * @param {number} current The current step.
   */
  const getStepStatus = (step: number, current: number): 'process' | 'finish' | 'wait' | 'error' => {
    switch (step) {
      case 0:
        if (current === 0) {
          // TODO: if (error) return 'error';
          return 'process';
        } else if (current > 0) {
          return 'finish';
        }
        break;

      case 1:
        if (current < 1) {
          return 'wait';
        } else if (current === 1) {
          // TODO: if (error) return 'error';
          return 'process';
        } else if (current > 1) {
          return 'finish';
        }
        break;

      case 2:
        if (current < 2) {
          return 'wait';
        } else if (current === 2) {
          // TODO: if (error) return 'error';
          return 'process';
        }
        break;
    }

    return 'error';
  };

  /**
   * Set this account as the master account and advance to the next step.
   */
  const _onMasterAccountChosen = React.useCallback(async () => {
    const response = await _runInitMergeOperation();
    if (response.status) {
      setMergeKey(response.data?.details);
      setIsMasterAccount(true);
      next();
    }
  }, [next, _runInitMergeOperation]);

  /**
   * Set this account as the master account and advance to the next step.
   */
  const _onSlaveAccountChosen = React.useCallback(() => {
    setIsMasterAccount(false);
    next();
  }, [next]);

  /**
   * Launch the merge operation on the master account.
   */
  const _onLaunchMergeOperation = React.useCallback(async () => {
    if (!isUndefined(mergeKey)) {
      const response = await _runLaunchMergeOperation(mergeKey);
      if (response.status) {
        next();
      }
    }
  }, [next, _runLaunchMergeOperation]);

  /**
   * Add a slave account to the current merge operation.
   */
  const _onAddSlaveAccount = React.useCallback(async () => {
    if (!isUndefined(mergeKey)) {
      const response = await _runAddSlaveAccountInMergeOperation(mergeKey);
      if (response.status) {
        next();
      }
    }
  }, [next, _runAddSlaveAccountInMergeOperation]);

  /**
   * Properly closes this modal.
   */
  const _onCloseModal = React.useCallback(async () => {
    /**
     * Effectively closes this merge account operation modal.
     * @param {boolean} cancel Defines the need to cancel the merge operation or not.
     */
    const _close = async (cancel: boolean) => {
      if (cancel && isMasterAccount && !isUndefined(mergeKey)) {
        const response = await _runCancelMergeOperation(mergeKey);
        if (response.status) {
          message.success({
            content: intl.formatMessage({ id: 'workspaces.settings.advanced.merge_accounts.message.merge_cancelled' }),
          });
        }
      }

      _resetState();

      if (onCancel !== undefined) {
        onCancel();
      }

      if (callback !== undefined) {
        callback();
      }
    };

    if (current > 0) {
      DefaultModal.confirm({
        title: intl.formatMessage({
          id: 'workspaces.settings.advanced.merge_accounts.message.confirm_cancel_merge.title',
        }),
        centered: true,
        content: intl.formatMessage({
          id: 'workspaces.settings.advanced.merge_accounts.message.confirm_cancel_merge.content',
        }),
        okText: intl.formatMessage({ id: 'action.quit' }),
        okType: 'danger',
        onOk: async () => await _close(true),
        cancelText: intl.formatMessage({
          id: 'workspaces.settings.advanced.merge_accounts.action.continue_merge_later',
        }),
        onCancel: async () => await _close(false),
      });
    } else {
      await _close(false);
    }
  }, [intl, current, onCancel, callback, _runCancelMergeOperation]);

  /**
   * Effectively finishes the merge accounts operation by reloading the app.
   */
  const _onFinishOperation = React.useCallback(() => {
    window.location.reload();

    if (onCancel !== undefined) {
      onCancel();
    }
  }, [onCancel]);

  return (
    <Modal
      {...props}
      title={intl.formatMessage({ id: 'workspaces.settings.advanced.merge_accounts.title' })}
      ok={current === 2}
      onOk={isMasterAccount ? _onFinishOperation : onCancel}
      okText={intl.formatMessage({ id: 'action.finish' })}
      cancel={current < 2}
      onCancel={current < 2 ? _onCloseModal : undefined}
      cancelText={intl.formatMessage({ id: 'action.cancel' })}
    >
      <Steps current={current} size="small">
        <Step
          title={intl.formatMessage({ id: 'workspaces.settings.advanced.merge_accounts.steps.setup.title' })}
          status={getStepStatus(0, current)}
          icon={_initMergeOperationLoading ? <LoadingOutlined /> : <UserOutlined />}
        />
        <Step
          title={intl.formatMessage({ id: 'workspaces.settings.advanced.merge_accounts.steps.merge.title' })}
          status={getStepStatus(1, current)}
          icon={_launchMergeOperationLoading ? <LoadingOutlined /> : <UsergroupAddOutlined />}
        />
        <Step
          title={intl.formatMessage({ id: 'workspaces.settings.advanced.merge_accounts.steps.finish.title' })}
          status={getStepStatus(2, current)}
          icon={<CheckCircleOutlined />}
        />
      </Steps>
      <div className="wl-app-account-settings-steps-content">
        {current === 0 && (
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Paragraph style={{ marginBottom: '2rem' }}>
                <FormattedMessage id="workspaces.settings.advanced.merge_accounts.steps.setup.intro" />
              </Paragraph>
              <Text strong>
                <FormattedMessage id="workspaces.settings.advanced.merge_accounts.steps.setup.guide_intro" />
              </Text>
            </Col>
            <Col span={12}>
              <Paragraph>
                <FormattedMessage id="workspaces.settings.advanced.merge_accounts.steps.setup.guide_master_account" />
              </Paragraph>
            </Col>
            <Col span={12}>
              <Paragraph>
                <FormattedMessage id="workspaces.settings.advanced.merge_accounts.steps.setup.guide_slave_account" />
              </Paragraph>
            </Col>
            <Col span={12}>
              <Button
                disabled={_initMergeOperationLoading}
                onClick={_onMasterAccountChosen}
                type="success"
                block
                leftIcon={<UserOutlined />}
              >
                <FormattedMessage id="workspaces.settings.advanced.merge_accounts.steps.setup.master_account_button" />
              </Button>
            </Col>
            <Col span={12}>
              <Button
                disabled={_initMergeOperationLoading}
                onClick={_onSlaveAccountChosen}
                block
                leftIcon={<TeamOutlined />}
              >
                <FormattedMessage id="workspaces.settings.advanced.merge_accounts.steps.setup.slave_account_button" />
              </Button>
            </Col>
          </Row>
        )}
        {current === 1 && isMasterAccount && (
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Paragraph style={{ marginBottom: '2rem' }}>
                <FormattedMessage id="workspaces.settings.advanced.merge_accounts.steps.merge.master_account_intro" />
              </Paragraph>
              <Paragraph strong copyable style={{ marginBottom: '2rem' }}>
                {mergeKey}
              </Paragraph>
              <Button
                type="warning"
                disabled={_launchMergeOperationLoading}
                onClick={_onLaunchMergeOperation}
                block
                leftIcon={<PullRequestOutlined />}
              >
                <FormattedMessage id="workspaces.settings.advanced.merge_accounts.steps.merge.merge_account_button" />
              </Button>
            </Col>
          </Row>
        )}
        {current === 1 && !isMasterAccount && (
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Paragraph style={{ marginBottom: '2rem' }}>
                <FormattedMessage id="workspaces.settings.advanced.merge_accounts.steps.merge.slave_account_intro" />
              </Paragraph>
              <Form.Item
                labelCol={{ span: 24 }}
                required
                label={intl.formatMessage({
                  id: 'workspaces.settings.advanced.merge_accounts.steps.merge.input_label',
                })}
                status={isEmpty(mergeKey) ? 'error' : 'success'}
              >
                <Input value={mergeKey} onChange={e => setMergeKey(e.target.value)} style={{ marginBottom: '2rem' }} />
              </Form.Item>
              <Button
                type="warning"
                disabled={_addSlaveAccountInMergeOperationLoading}
                onClick={_onAddSlaveAccount}
                block
                leftIcon={<UserAddOutlined />}
              >
                <FormattedMessage id="workspaces.settings.advanced.merge_accounts.steps.merge.add_account_button" />
              </Button>
            </Col>
          </Row>
        )}
        {current === 2 && (
          <Result
            status="success"
            title={intl.formatMessage({ id: 'workspaces.settings.advanced.merge_accounts.steps.finish.result_title' })}
            subTitle={intl.formatMessage({
              id: isMasterAccount
                ? 'workspaces.settings.advanced.merge_accounts.steps.finish.result_message_master'
                : 'workspaces.settings.advanced.merge_accounts.steps.finish.result_message_slave',
            })}
          />
        )}
      </div>
    </Modal>
  );
}
