import React from 'react';
import { CheckOutlined, PlusOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import classNames from 'classnames';

/**
 * The properties required for the Chip component.
 * @author Axel Nana <axel.nana@workerly.io>
 */
export interface ChipProps {
  /**
   * The URL/metadata of the avatar to use at the left side of the chip, if any.
   * This value will be passed to the `src` attribute of the `Avatar` component.
   */
  avatar?: string;

  /**
   * Defines if the chip is selectable or not.
   * Selectable chips will render with an icon
   * at the right, indicating selection state.
   */
  selectable?: boolean;

  /**
   * Defines if the chip is hoverable or not.
   * Hoverable chips will render a shadow box
   * on mouse hover.
   */
  hoverable?: boolean;

  /**
   * Callback triggered when the chip is selectable, and
   * its selected state change.
   */
  onSelectionChange?: (selected: boolean) => void;

  /**
   * Specifies additional styles to add to this element.
   * Uses this with caution since it can break the UI.
   */
  style?: React.CSSProperties;
}

/**
 * Renders a single chip.
 * @author Axel Nana <axel.nana@workerly.io>
 * @param {React.PropsWithChildren<ChipProps>} props The chip properties.
 */
export function Chip({
  avatar,
  hoverable,
  selectable,
  style,
  children,
  onSelectionChange,
}: React.PropsWithChildren<ChipProps>) {
  const [selected, setSelected] = React.useState(false);

  React.useEffect(() => {
    if (onSelectionChange) {
      onSelectionChange(selected);
    }
  }, [selected]);

  /**
   * Toggles the selected state of this chip.
   */
  const _toggleSelected = () => setSelected(!selected);

  /**
   * Event callback triggered when the user clicks on the chip.
   */
  const _onChipClick = React.useCallback(() => {
    if (selectable) {
      _toggleSelected();
    }
  }, [selectable, selected]);

  return (
    <div
      style={style}
      className={classNames('wl-component-chip', {
        'wl-component-chip-selectable': selectable,
        'wl-component-chip-hoverable': hoverable,
        'wl-component-chip-state-selected': selected,
        'wl-component-chip-has-avatar': avatar !== undefined,
      })}
      onClick={_onChipClick}
    >
      {avatar && <Avatar className="wl-component-chip-avatar" src={avatar} size={38} />}
      <span className="wl-component-chip-content">{children}</span>
      {selectable && <span className="wl-component-chip-icon">{selected ? <CheckOutlined /> : <PlusOutlined />}</span>}
    </div>
  );
}
