export default {
  'action.ok': 'Ok',
  'action.cancel': 'Cancel',
  'action.yes': 'Yes',
  'action.no': 'No',
  'action.create': 'Create',
  'action.submit': 'Submit',
  'action.save': 'Save',
  'action.reset': 'Reset',
  'action.add': 'Add',
  'action.upload': 'Upload',
  'action.delete': 'Delete',
  'action.remove': 'Remove',
  'action.verify': 'Verify',
  'action.start': 'Start',
  'action.stop': 'Stop',
  'action.quit': 'Quit',
  'action.finish': 'Finish',
  'action.previous': 'Previous',
  'action.next': 'Next',
  'action.continue': 'Continue',
  'action.accept': 'Accept',
  'action.reject': 'Reject',
  'action.confirm': 'Confirm',
  'action.consult': 'Consult',
  'action.edit': 'Edit',
};
