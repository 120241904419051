import { PositionResponseData } from '@/api/position';
import { DepartmentResource } from '@/api/department';
import { DataModel } from '@/data/DataModel';
import { Department } from '@/data/Department';
import { ConstrainedDataModel } from '@/data/ConstrainedDataModel';

/**
 * The position data model by the app
 * @author Adonis SIMO <simo.adonis@workerly.io>
 */
export class Position extends ConstrainedDataModel<PositionResponseData> {
  public get pk(): string {
    return this._rawData.pk;
  }

  // TODO: check this setter
  public set pk(value: string) {
    this._rawData.pk = value;
  }

  public get name(): string {
    return this._rawData.name;
  }

  public set name(value: string) {
    this._rawData.name = value;
  }

  public get department(): string {
    return this._rawData.department;
  }

  public set department(value: string) {
    this._rawData.department = value;
  }

  public get code(): string {
    return this._rawData.code;
  }

  public set code(value: string) {
    this._rawData.code = value;
  }

  public get description(): string {
    return this._rawData.description;
  }

  public set description(value: string) {
    this._rawData.description = value;
  }

  public get departmentName(): string {
    return this._rawData.department_name;
  }

  public async getRelated(key: 'department'): Promise<Department | null>;
  public async getRelated(key: keyof PositionResponseData): Promise<DataModel | null> {
    switch (key) {
      case 'department':
        // request to get department
        const departmentResource = new DepartmentResource();
        const { data } = await departmentResource.get(this.department);
        return data.status ? new Department(data.data) : null;

      default:
        return null;
    }
  }
}
