// @ts-nocheck
import React from 'react';
import { ApplyPluginsType } from '/codebuild/output/src992797377/src/web-v2/node_modules/umi/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';

export function getRoutes() {
  const routes = [
  {
    "name": "accountLogin",
    "path": "/login",
    "component": require('@/pages/auth/login').default,
    "layout": false,
    "hideInMenu": true,
    "exact": true
  },
  {
    "name": "accountLogout",
    "path": "/logout",
    "component": require('@/pages/auth/logout').default,
    "layout": false,
    "hideInMenu": true,
    "exact": true
  },
  {
    "name": "accountSignup",
    "locale": "auth.signup",
    "title": "title.signup",
    "path": "/signup",
    "component": require('@/pages/auth/signup').default,
    "layout": false,
    "hideInMenu": true,
    "exact": true
  },
  {
    "name": "accountPasswordReset",
    "path": "/password-reset",
    "component": require('@/pages/auth/password-reset').default,
    "layout": false,
    "hideInMenu": true,
    "exact": true
  },
  {
    "path": "/account",
    "component": require('@/layouts/workspace').default,
    "flatMenu": true,
    "wrappers": [require('@/wrappers/auth').default],
    "routes": [
      {
        "name": "accountWorkspaces",
        "path": "/account/workspaces",
        "component": require('@/pages/account/workspaces').default,
        "exact": true
      },
      {
        "name": "accountSettings",
        "path": "/account/settings",
        "component": require('@/pages/account/settings').default,
        "exact": true
      }
    ]
  },
  {
    "path": "/",
    "component": require('@/layouts/index').default,
    "flatMenu": true,
    "wrappers": [require('@/wrappers/auth').default],
    "routes": [
      {
        "path": "/",
        "redirect": "/dashboard",
        "exact": true
      },
      {
        "icon": "dashboard",
        "name": "dashboard",
        "locale": "menu.dashboard",
        "title": "title.dashboard",
        "path": "/dashboard",
        "component": require('@/pages/dashboard').default,
        "exact": true
      },
      {
        "icon": "employees",
        "name": "employees",
        "locale": "menu.employees",
        "title": "title.employees",
        "path": "/employees",
        "component": require('@/pages/employees').default,
        "exact": true
      },
      {
        "icon": "attendances",
        "name": "attendances",
        "locale": "menu.attendances",
        "breadcrumbName": "menu.attendances",
        "title": "title.attendances",
        "path": "/attendances",
        "component": require('@/pages/attendances').default,
        "exact": true
      },
      {
        "icon": "timesheet",
        "name": "timesheets",
        "locale": "menu.timesheets",
        "breadcrumbName": "menu.timesheets",
        "title": "title.timesheets",
        "path": "/global-timesheets",
        "component": require('@/pages/timesheets').default,
        "exact": true
      },
      {
        "icon": "payment",
        "name": "payrolls",
        "locale": "menu.payrolls",
        "breadcrumbName": "menu.payrolls",
        "title": "title.payrolls",
        "path": "/global-payrolls",
        "component": require('@/pages/payrolls').default,
        "exact": true
      },
      {
        "icon": "shifts",
        "name": "shifts",
        "locale": "menu.shifts",
        "breadcrumbName": "menu.shifts",
        "title": "title.shifts",
        "path": "/work-shifts",
        "component": require('@/pages/shifts').default,
        "exact": true
      },
      {
        "icon": "time-off",
        "name": "timeOffApproval",
        "locale": "menu.timeOffApproval",
        "breadcrumbName": "menu.timeOffApproval",
        "title": "title.timeOffApproval",
        "path": "/time-off-approvals",
        "component": require('@/pages/time-off-approval').default,
        "exact": true
      },
      {
        "icon": "settings",
        "name": "companySettings",
        "locale": "menu.companySettings",
        "breadcrumbName": "menu.companySettings",
        "title": "title.companySettings",
        "path": "/company/settings",
        "routes": [
          {
            "icon": "company",
            "name": "companySettingsProfile",
            "locale": "menu.company.settings.profile",
            "breadcrumbName": "menu.company.settings.profile",
            "title": "title.company.settings.profile",
            "path": "/company/settings/profile",
            "component": require('@/pages/company/settings/profile').default,
            "exact": true
          },
          {
            "icon": "location",
            "name": "companySettingsLocations",
            "locale": "menu.company.settings.locations",
            "breadcrumbName": "menu.company.settings.locations",
            "title": "title.company.settings.locations",
            "path": "/company/settings/locations",
            "component": require('@/pages/company/settings/locations').default,
            "exact": true
          },
          {
            "icon": "department",
            "name": "companySettingsDepartments",
            "locale": "menu.company.settings.departments",
            "breadcrumbName": "menu.company.settings.departments",
            "title": "title.company.settings.departments",
            "path": "/company/settings/departments",
            "component": require('@/pages/company/settings/departments').default,
            "exact": true
          },
          {
            "icon": "job-title",
            "name": "companySettingsPositions",
            "locale": "menu.company.settings.positions",
            "breadcrumbName": "menu.company.settings.positions",
            "title": "title.company.settings.positions",
            "path": "/company/settings/positions",
            "component": require('@/pages/company/settings/positions').default,
            "exact": true
          },
          {
            "icon": "payment",
            "name": "companySettingsPayrolls",
            "locale": "menu.company.settings.payrolls",
            "breadcrumbName": "menu.company.settings.payrolls",
            "title": "title.company.settings.payrolls",
            "path": "/company/settings/payrolls",
            "component": require('@/pages/company/settings/payroll').default,
            "exact": true
          },
          {
            "icon": "payment",
            "name": "companySettingsWorkWeeks",
            "locale": "menu.company.settings.workweeks",
            "breadcrumbName": "menu.company.settings.workweeks",
            "title": "title.company.settings.workweeks",
            "path": "/company/settings/workweeks",
            "component": require('@/pages/company/settings/workweek').default,
            "exact": true
          },
          {
            "icon": "overtime",
            "name": "companySettingsOvertime",
            "locale": "menu.company.settings.overtimes",
            "breadcrumbName": "menu.company.settings.overtimes",
            "title": "title.company.settings.overtimes",
            "path": "/company/settings/overtimes-policies",
            "component": require('@/pages/company/settings/overtimes').default,
            "exact": true
          },
          {
            "icon": "holiday",
            "name": "companySettingsHolidays",
            "locale": "menu.company.settings.holidays",
            "breadcrumbName": "menu.company.settings.holidays",
            "title": "title.company.settings.holidays",
            "path": "/company/settings/holidays",
            "component": require('@/pages/company/settings/holidays').default,
            "exact": true
          },
          {
            "icon": "time-off-policy",
            "name": "companySettingsPTOPolicy",
            "locale": "menu.company.settings.timeoffpolicys",
            "breadcrumbName": "menu.company.settings.timeoffpolicys",
            "title": "title.company.settings.timeoffpolicys",
            "path": "/company/settings/timeoffs",
            "component": require('@/pages/company/settings/time-off').default,
            "exact": true
          },
          {
            "icon": "device",
            "name": "companySettingsDevices",
            "locale": "menu.company.settings.devices",
            "breadcrumbName": "menu.company.settings.devices",
            "title": "title.company.settings.devices",
            "path": "/company/settings/devices",
            "component": require('@/pages/company/settings/devices').default,
            "exact": true
          },
          {
            "icon": "access-level",
            "name": "companySettingsPermissions",
            "locale": "menu.company.settings.permissions",
            "breadcrumbName": "menu.company.settings.permissions",
            "title": "title.company.settings.permissions",
            "path": "/company/settings/access",
            "component": require('@/pages/company/settings/permissions').default,
            "exact": true
          },
          {
            "icon": "hr-advisory",
            "name": "companySettingsHRAdvisory",
            "locale": "menu.company.settings.hradvisory",
            "breadcrumbName": "menu.company.settings.hradvisory",
            "title": "title.company.settings.hradvisory",
            "path": "/company/settings/hr-advisory",
            "component": require('@/pages/company/settings/hradvisory').default,
            "exact": true
          }
        ]
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
