import { RoleResponseData } from '@/api/role';
import { DataModel } from '@/data/DataModel';

import { EmployeeCreationResource } from '@/api/employees';
import { Employee } from '@/data/Employee';

import { LocationResource } from '@/api/location';
import { Location } from '@/data/Location';

import { DepartmentResource } from '@/api/department';
import { Department } from '@/data/Department';
import { ConstrainedDataModel } from '@/data/ConstrainedDataModel';

/**
 * @author Adonis SIMO <simo.adonis@workerly.io>
 */
export class Role extends ConstrainedDataModel<RoleResponseData> {
  public async getRelated(key: 'managed_employees'): Promise<Employee[] | null>;
  public async getRelated(key: 'managed_locations'): Promise<Location[] | null>;
  public async getRelated(key: 'managed_departments'): Promise<Department[] | null>;
  public async getRelated(key: keyof RoleResponseData): Promise<DataModel[] | DataModel | null> {
    // TODO: optimze this make on api call instead of multiples like now
    switch (key) {
      case 'managed_employees':
        const empResource = new EmployeeCreationResource();
        const employees: Employee[] = [];
        if (this._rawData.managed_employees) {
          for (var i = 0; i++; i < this._rawData.managed_employees.length) {
            const { data } = await empResource.get(this._rawData.managed_employees[i]);
            if (data.status) {
              employees.push(new Employee(data.data));
            }
          }
        }
        return employees;

      case 'managed_locations':
        const locResource = new LocationResource();
        const locations: Location[] = [];
        if (this._rawData.managed_locations) {
          for (var i = 0; i++; i < this._rawData.managed_locations.length) {
            const { data } = await locResource.get(this._rawData.managed_locations[i]);
            if (data.status) {
              locations.push(new Location(data.data));
            }
          }
        }
        return locations;

      case 'managed_departments':
        const depResource = new DepartmentResource();
        const departments: Department[] = [];
        if (this._rawData.managed_departments) {
          for (var i = 0; i++; i < this._rawData.managed_departments.length) {
            const { data } = await depResource.get(this._rawData.managed_departments[i]);
            if (data.status) {
              departments.push(new Department(data.data));
            }
          }
        }
        return departments;

      default:
        throw new Error('nothing');
    }
  }

  public set pk(value: string) {
    this._rawData.pk = value;
  }

  public get pk(): string {
    return this._rawData.pk;
  }

  public set name(value: string) {
    this._rawData.name = value;
  }

  public get name(): string {
    return this._rawData.name;
  }

  public set permissions(value: number[]) {
    this._rawData.permissions = value;
  }

  public get permissions(): number[] {
    return this._rawData.permissions;
  }

  public set actions(value: { [key: string]: string[] }[]) {
    this._rawData.actions = value;
  }

  public get actions(): { [key: string]: string[] }[] {
    if (this._rawData.actions) {
      return this._rawData.actions;
    }
    return [];
  }

  public set companyWide(value: boolean) {
    this._rawData.company_wide = value;
  }

  public get companyWide(): boolean {
    return this._rawData.company_wide;
  }
}
