import { ReadOnlyResource, Resource } from '@/api/resource';
import { AttendanceResponseData } from '@/api/attendance';
import { FileDepartment } from '@/api/department';
import { HolidayResponseData } from '@/api/holiday';
import { LocationEmployeeData, LocationResponseData } from '@/api/location';
import { OvertimeResponseData } from '@/api/overtime';
import { PayrollResponseData } from '@/api/payroll';
import { FilePositionData } from '@/api/position';
import { Salary } from '@/api/salaries';
import { ShiftAssignmentPerFileData } from '@/api/shift';
import { ParentPTOPolicyResponseData } from '@/api/time-off';
import { WorkWeekResponseData } from '@/api/workweek';

/**
 * General object used for various assignation of employees
 * @author Adonis SIMO <simo.adonis@workerly.io>
 */
export interface EmployeeLocationAssignationProps {
  employee: string;
  startDate: string;
}

/**
 * Employee data structure at the creation
 * @author Adonis SIMO <simo.adonis@workerly.io>
 */
export interface EmployeeCreationRequestData {
  pk?: string;
  amount: number;
  birth_date: string;
  city: string;
  country: string;
  department: string;

  email: string;
  emergency_phone: string;
  employee_type: number;
  file_active: boolean;
  first_name: string;

  hire_date: string;
  holidays: number[];
  is_active: boolean;
  last_name: string;
  leaves_approvers: string[];
  location: string;
  payroll: string;
  phone1: string;
  phone2: string;
  position: string;

  role: string;
  start: string;
  start_date: string;

  unique_id: string;
  week_work: string;
  pto_policies: string[];
  overtime?: string[];

  // department_active: boolean;
  // department_description?: string;
  // department_end: string;
  // department_mission: string;
  // department_start: string;
  // position_active: boolean;
  // position_description: string;
  // position_end: string;
  // position_mission: string;
  // position_start: string;
  // team?: string;
  // team_active?: boolean;
  // team_description?: string;
  // team_end?: string;
  // team_mission: string;
  // team_start: string;
}

/**
 * What we receive after the employee creation query being performed
 * @author Adonis SIMO <simo.adonis@workerly.io>
 */
export interface EmployeeCreationResponseData extends EmployeeCreationRequestData {
  pk: string;
}

/**
 * Datastruc when listing all employees
 * @author Adonis SIMO <simo.adonis@workerly.io>
 */
export interface EmployeeListData {
  pk: string;
  first_name: string;
  last_name: string;
  email: string;
  employee_type: number;
  unique_id: string;
  pp: string;
  position: {
    name: string;
    pk: string;
  };
  location: {
    name: string;
    pk: string;
  };
  department: {
    name: string;
    pk: string;
  };
  phone1: string;
}

/**
 * Just a simple interface to represent a fraction of employee information.
 * ideal to be displayed in pop-up, suggestion list etc.
 * -- NO need of DataModel --
 * @author Adonis SIMO <simo.adonis@workerly.io>
 */
export interface SmallEmployeeData {
  pk: string;
  full_name: string;
  unique_id: string;
}

/**
 * One employees file, represent the core component of an employee, everything defining
 * and employee's employment is related to this object. It will usually contain a huge
 * amount of data.
 * @author Adonis SIMO <simo.adonis@workerly.io>
 */
export interface EmployeeFileData {
  overtimes: OvertimeResponseData[];
  pk: string;
  active: boolean;
  name: string;
  employee_type: number;
  attendances: AttendanceResponseData[];
  year_work_weeks: string[];
  start: string;
  ended: string;
  week_work: WorkWeekResponseData;
  payrolls: string[];
  base_location: LocationResponseData;
  salaries: Salary[];
  file_positions: FilePositionData[];
  file_departments: FileDepartment[];
  work_weeks: String[];
  payroll: PayrollResponseData;
  employees_pto_leaves: ParentPTOPolicyResponseData[];
  employees_holidays: HolidayResponseData[];
  shift_assignements: ShiftAssignmentPerFileData[];
  leaves_aprovers: SmallEmployeeData[];
  employeelocation_set: LocationEmployeeData[];
  leaves_aprovees: SmallEmployeeData[];
}

/**
 * One employee details, with everything regarding one employee
 * @author Adonis SIMO <simo.adonis@workerly.io>
 */
export interface EmployeeDetail {
  pk: string;
  created: string;
  files: EmployeeFileData[];
  first_name: string;
  last_name: string;
  email: string;
  birth_date: string;
  hire_date: string;
  address: string;
  emergency_phone: string;
  phone1: string;
  phone2: string;
  city: string;
  gender: string;
  employee_type: number;
  is_active: boolean;
  is_owner: boolean;
  unique_id: string;
  pp: string;
  bio: string;
  work_conformity: any;
  leaves: any[];
  overtimes: any[];
  leave_policies: any[];
  shifts: any[];
}

/**
 * Resource to handle only employee creation process
 * @author Adonis SIMO <simo.adonis@workerly.io>
 */
export class EmployeeCreationResource extends Resource<EmployeeCreationRequestData, EmployeeCreationResponseData> {
  constructor() {
    super('/employees');
  }
}

/**
 * Endpoint to list only employees
 * @author Adonis SIMO <simo.adonis@workerly.io>
 */
export class EmployeeListResource extends ReadOnlyResource<EmployeeListData> {
  constructor() {
    super('/employees');
  }
}

/**
 * Endpoint to retrieve only employee's details
 * @author Adonis SIMO <simo.adonis@workerly.io>
 */
export class EmployeeDetailsResource extends ReadOnlyResource<EmployeeDetail> {
  constructor() {
    super('/employees');
  }
}
