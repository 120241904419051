import { DateTime } from 'luxon';

import { DataModel } from '@/data/DataModel';
import { FullTimezone } from '@/api/types';
import { LocationResponseData } from '@/api/location';
import { EmployeeLocationAssignationProps } from '@/api/employees';
import { ConstrainedDataModel } from '@/data/ConstrainedDataModel';

/**
 * @author Adonis SIMO <simo.adonis@worerly.io>
 */
export class Location extends ConstrainedDataModel<LocationResponseData> {
  public getRelated(key: keyof LocationResponseData): Promise<DataModel | DataModel[] | null> {
    throw new Error('Method not implemented.');
  }

  public get yearWorkWeek(): Array<{ start: DateTime; end: DateTime }> {
    // 2020-10-01 00:00:00+00:00
    // DateTime.fromFormat(start, "yyyy-MM-dd HH:mm:ssZZ");
    return this._rawData.year_work_week?.map((period) => {
      const [start, end] = period.split('--', 2);
      return {
        start: DateTime.fromFormat(start, 'yyyy-MM-dd HH:mm:ssZZ', {
          setZone: true,
        }),
        end: DateTime.fromFormat(end, 'yyyy-MM-dd HH:mm:ssZZ', {
          setZone: true,
        }),
      };
    });
  }

  public get pk(): string {
    return this._rawData.pk;
  }

  public set pk(value: string) {
    this._rawData.pk = value;
  }

  public set isHeadquater(value: boolean) {
    this._rawData.is_headquater = value;
  }

  public get isHeadquater(): boolean {
    return this._rawData.is_headquater;
  }

  public set name(value: string) {
    this._rawData.name = value;
  }

  public get name(): string {
    return this._rawData.name;
  }

  public set state(value: string) {
    this._rawData.state = value;
  }

  public get state(): string {
    return this._rawData.state;
  }

  public set city(value: string) {
    this._rawData.city = value;
  }

  public get city(): string {
    return this._rawData.city;
  }

  public set defaultYearlyRate(value: number) {
    this._rawData.default_yearly_rate = value;
  }

  public get defaultYearlyRate(): number {
    return this._rawData.default_yearly_rate;
  }

  public set defaultHourlyRate(value: number) {
    this._rawData.default_hourly_rate = value;
  }

  public get defaultHourlyRate(): number {
    return this._rawData.default_hourly_rate;
  }

  public set fullTimezone(value: FullTimezone) {
    this._rawData.full_timezone = value;
  }

  public get fullTimezone(): FullTimezone {
    return this._rawData.full_timezone;
  }

  public set workWeekStart(value: number) {
    this._rawData.work_week_start = value;
  }

  public get workWeekStart(): number {
    return this._rawData.work_week_start;
  }

  public get regularStartTime() {
    return DateTime.fromISO(this._rawData.regular_start_time, { setZone: true });
  }

  public set regularStartTime(value: DateTime) {
    this._rawData.regular_start_time = value.toISO();
  }

  public get regularEndTime() {
    return DateTime.fromISO(this._rawData.regular_end_time, { setZone: true });
  }

  public set regularEndTime(value: DateTime) {
    this._rawData.regular_end_time = value.toISO();
  }

  public set fullAddress(value: string) {
    this._rawData.full_address = value;
  }

  public get fullAddress(): string {
    return this._rawData.full_address;
  }

  public set phone(value: string) {
    this._rawData.phone = value;
  }

  public get phone(): string {
    return this._rawData.phone;
  }

  public set email(value: string) {
    this._rawData.email = value;
  }

  public get email(): string {
    return this._rawData.email;
  }

  public set breakStart(value: string) {
    this._rawData.break_start = value;
  }

  public get breakStart(): string {
    return this._rawData.break_start;
  }

  public set breakEnd(value: string) {
    this._rawData.break_end = value;
  }

  public get breakEnd(): string {
    return this._rawData.break_end;
  }
  public set workWeeks(value: string) {
    this._rawData.work_weeks = value;
  }

  public get workWeeks(): string {
    return this._rawData.work_weeks;
  }

  public set breakGap(value: number) {
    this._rawData.break_gap = value;
  }

  public get breakGap(): number {
    return this._rawData.break_gap;
  }

  public set timeGap(value: number) {
    this._rawData.time_gap = value;
  }

  public get timeGap(): number {
    return this._rawData.time_gap;
  }

  public set meta(value: any) {
    this._rawData.city = value;
  }

  public get meta(): any {
    return this._rawData.meta;
  }

  /**
   * Assign one or many employees to the current location.
   * @param employees employees to assign to the current location
   * @author Adonis SIMO <simo.adoni@workerly.io>
   */
  public assignEmployees(employeesAssignation: EmployeeLocationAssignationProps[]) {}
}
