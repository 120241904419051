import { DataModel } from '@/data/DataModel';
import { EmployeeCreationResponseData, EmployeeFileData, EmployeeListData } from '@/api/employees';
import { DateTime } from 'luxon';
import { Position } from './Position';
import { Overtime } from './Overtime';
import { Department } from './Department';
import { Salary } from '@/api/salaries';
import { AttendanceCorrectionRequestProps, AttendanceCreationRequestProps } from '@/api/attendance';
import { Attendance } from '@/data/Attendance';
import { Pay } from '@/data/Pay';
import { Timesheet } from './Timesheet';

/**
 * To be used in cas of a simple list of employee
 * @author Adonis SIMO <simo.adonis@workerly.io>
 */
export class EmployeeListDM extends DataModel<EmployeeListData> {
  public get pk(): string {
    return this._rawData.pk;
  }

  public set pk(value: string) {
    this._rawData.pk = value;
  }
  public getRelated(key: keyof EmployeeListData): Promise<DataModel | DataModel[] | null> {
    throw new Error('Method not implemented.');
  }

  public set email(value: string) {
    this._rawData.email = value;
  }

  public get email(): string {
    return this._rawData.email;
  }

  public get uniqueId(): string {
    return this._rawData.unique_id;
  }

  public set employeeType(value: number) {
    this._rawData.employee_type = value;
  }

  public get employeeType(): number {
    return this._rawData.employee_type;
  }

  public set firstName(value: string) {
    this._rawData.first_name = value;
  }

  public get firstName(): string {
    return this._rawData.first_name;
  }

  public set lastName(value: string) {
    this._rawData.last_name = value;
  }

  public get lastName(): string {
    return this._rawData.last_name;
  }

  public get name(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  public get pp(): string {
    return this._rawData.pp;
  }

  public get position(): any {
    return this._rawData.position;
  }

  public get location(): any {
    return this._rawData.location;
  }

  public get department(): any {
    //
    return this._rawData.department;
  }
}
/**
 * @author Adonis SIMO <simo.adonis@worerly.io>
 */
export class Employee extends DataModel<EmployeeCreationResponseData> {
  public getRelated(key: keyof EmployeeCreationResponseData): Promise<DataModel | DataModel[] | null> {
    throw new Error('Method not implemented.');
  }

  public get pk(): string {
    return this._rawData.pk;
  }

  public set pk(value: string) {
    this._rawData.pk = value;
  }

  public get amount(): number {
    return this._rawData.amount;
  }

  public set amount(value: number) {
    this._rawData.amount = value;
  }

  public get birthDate(): DateTime {
    return DateTime.fromISO(this._rawData.birth_date);
  }

  public set birthDate(value: DateTime) {
    this._rawData.birth_date = value.toISO();
  }

  public get city(): string {
    return this._rawData.city;
  }

  public set city(value: string) {
    this._rawData.city = value;
  }

  public get country(): string {
    return this._rawData.country;
  }

  public set country(value: string) {
    this._rawData.country = value;
  }

  public set department(value: string) {
    this._rawData.department = value;
  }

  public get department(): string {
    return this._rawData.department;
  }

  public set email(value: string) {
    this._rawData.email = value;
  }

  public get email(): string {
    return this._rawData.email;
  }

  public set emergencyPhone(value: string) {
    this._rawData.emergency_phone = value;
  }

  public get emergencyPhone(): string {
    return this._rawData.emergency_phone;
  }

  public set fileActive(value: boolean) {
    this._rawData.file_active = value;
  }

  public get fileActive(): boolean {
    return this._rawData.file_active;
  }

  public set employeeType(value: number) {
    this._rawData.employee_type = value;
  }

  public get employeeType(): number {
    return this._rawData.employee_type;
  }

  public set firstName(value: string) {
    this._rawData.first_name = value;
  }

  public get firstName(): string {
    return this._rawData.first_name;
  }

  public set lastName(value: string) {
    this._rawData.last_name = value;
  }

  public get lastName(): string {
    return this._rawData.last_name;
  }

  public set hireDate(value: string) {
    this._rawData.hire_date = value;
  }

  public get hireDate(): string {
    return this._rawData.hire_date;
  }

  public set holidays(values: number[]) {
    this._rawData.holidays = values;
  }

  public get holidays(): number[] {
    return this._rawData.holidays;
  }

  public set isActive(value: boolean) {
    this._rawData.is_active = value;
  }

  public get isActive(): boolean {
    return this._rawData.is_active;
  }

  public set leaveApprovers(value: string[]) {
    this._rawData.leaves_approvers = value;
  }

  /**
   * Should be use to display full name mostly
   */
  public get name(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  public set name(value: string) {}
  public set location(value: string) {
    this._rawData.location = value;
  }
  public get location(): string {
    return this._rawData.location;
  }

  /**
   * Assign a new salary, this will disable the existing one or schedul it in future
   * @param salary the new salary object to assign
   * @param startData When should the salarhy take effect
   * @author Adonis SIMO <simo.adonis@workerly.io>
   */
  public assignSalary(salary: Salary, startData: Date): void {}

  /**
   * Assign a new department, this will disable the existing one or schedul it in future
   * @param department the new department object to assign
   * @param startData When the  assignation should  take effect
   * @author Adonis SIMO <simo.adonis@workerly.io>
   */
  public assignDepartment(department: Department, startData: Date): void {}

  /**
   * Assign a new overtime, this will disable the existing one or schedule it in future
   * @param overtime the new overtime object to assign
   * @param startData When the  assignation should  take effect
   * @author Adonis SIMO <simo.adonis@workerly.io>
   */
  public assignOvertime(overtime: Overtime, startData: Date): void {}

  /**
   * Assign a new position, this will disable the existing one or schedule it in future
   * @param position the new position object to assign
   * @param startData When the  assignation should  take effect
   * @author Adonis SIMO <simo.adonis@workerly.io>
   */
  public assignPosition(position: Position, startData: Date): void {}

  /**
   * Put a temporary hold to the employment of an employee
   * @param date When to put it on hold
   * @author Adonis SIMO <simo.adonis@workerly.io>
   */
  public pauseEmployment(date?: DateTime) {}

  /**
   * Completely stop the employment of an employee, eg: in case of hi
   * getting fired
   * @param date When the  assignation should  take effect
   * @author Adonis SIMO <simo.adonis@workerly.io>
   */
  public stopEmployment(date?: DateTime) {}

  /**
   * Make a file transition from and existing to the new one
   * @param oldFile the curently actived file
   * @param newFile the new file after the transition
   * @author Adonis SIMO <simo.adonis@workerly.io>
   */
  public fileTransition(oldFile: EmployeeFileData, newFile: EmployeeFileData) {}

  /**
   * Retrieve emoployee's attendance within a specific timeframe
   * @param start min interval to retrieve the attendance withn
   * @param end max interval to retrieve attendance within
   * @author Adonis SIMO <simo.adonis@workerly.io>
   */
  public getAttendances(start: DateTime, end: DateTime): Attendance[] {
    return [new Attendance()];
  }

  /**
   * Retrieve emoployee's payrolls within a specific timeframe
   * @param start min interval to retrieve the payrolls withn
   * @param end max interval to retrieve payrolls within
   * @author Adonis SIMO <simo.adonis@workerly.io>
   */
  public getPayrolls(start: DateTime, end: DateTime): Pay[] {
    return [new Pay()];
  }

  /**
   * Retrieve emoployee's timesheet within a specific timeframe
   * @param start min interval to retrieve the timesheet withn
   * @param end max interval to retrieve timesheet within
   * @author Adonis SIMO <simo.adonis@workerly.io>
   */
  public getTimesheets(start: DateTime, end: DateTime): Timesheet[] {
    return [new Timesheet()];
  }

  /**
   * If an employee request a change to a specific attendance from his manager.
   * @param start min interval to retrieve the timesheet withn
   * @param end max interval to retrieve timesheet within
   * @author Adonis SIMO <simo.adonis@workerly.io>
   */
  public requestAttendanceCorrection(attendanceCorrectionRequest: AttendanceCorrectionRequestProps) {}

  /**
   * If an employee request for creation of a specific attendance from his manager at a specific date.
   * @param start min interval to retrieve the timesheet withn
   * @param end max interval to retrieve timesheet within
   * @author Adonis SIMO <simo.adonis@workerly.io>
   */
  public requestAttendanceCreation(attendanceCreationRequest: AttendanceCreationRequestProps) {}
}
